import { Component, OnInit,ChangeDetectorRef} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { Router } from '@angular/router';
import { BonusService } from '../../bonuses-cards/bonus.service';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-clientdetails',
  templateUrl: './clientdetails.component.html',
  styleUrls: ['./clientdetails.component.scss'],
})
export class ClientdetailsComponent implements OnInit {
  imageLoaded: boolean = false;
  htmlContent: SafeHtml;
  balnceamout:any;
  bonusamout:any;
  sportamout:any;
  casinoamout:any;
  casinoamount:any;
  sportamount:any;
  Exposure:any;
  infoWalletType: any;
  typeWallet: any;
  isActive:String='balance';
  isActiveTab:boolean=false;
  isBonus:boolean=false;
  isCasino:boolean=false;
  ischeck:boolean=false;
  isSport:boolean=false;
  balanceshow:any;
  balanceshide:any;
  isLoader:any = false;
  promoItemInfo: any;
  allPromoRes: any;
  userName: any;
  removePopup: boolean = false;
  infoPage: boolean = false;
  bonusData: any;
  shortInfo: any;
  firstTwoWordofShortInfo: any;
  remainingWordsofShortInfo: any;
  animateCloseWindow: boolean = false;
  showBalance: boolean = true;
  howToDownloadAPK: boolean = false;
  showIosInstruction: boolean = false;
  loyaltyPopup: boolean = false;
  showLoyaltyProgram: boolean = false;
  depositOptionShow: boolean = true;

  menuListProfile = [
    { img: "../../../../assets/images/profile-wallet.svg", name: "Deposit Withdraw Report", link: "depositwithdrawreport" },
    { img: "../../../../assets/images/profile-live-bets.svg", name: "Open Bets", link: "/bethistory" },
    { img: "../../../../assets/images/profile-currency.svg", name: "Bonus", link: "showBonuses" },
    { img: "../../../../assets/images/profile-betting-profit-loss.webp", name: "Betting Profit Loss", link: "/builderhistory" },
    // { img: "../../../../assets/images/loylty-logo-yellow.svg", name: "Loyalty program", link: "loyalty" },
    { img: "../../../../assets/images/profile-account-statement.png", name: "Account Statement", link: "/account-statement" },
    { img: "../../../../assets/images/profile-settings.svg", name: "Settings", link: "myprofile/PersonalDetails" },
    // { img: "../../../../assets/images/profile-dwn-apk.svg", name: "Download APK", link: "goToAPK", depositOptionShow: true },
  ]
  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  constructor(private myprofileServices: MyprofileService, public authService:AuthService, private cdr: ChangeDetectorRef, private router: Router, public _bonus: BonusService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const intercom = document.getElementsByClassName('intercom-lightweight-app')[0];
    // if element is present then remove it
    if (intercom) intercom.remove();

    const timer = setTimeout(() => {
      let balanceShow = localStorage.getItem('showBalance')
      if (balanceShow === 'true') {
        this.showBalance = false;
      } else if (balanceShow === 'false') {
        this.showBalance = true;
      } else {
        this.showBalance = true;
   }
    }, 100);

    this.getToken();
    if (this.getToken() == null) {
      this.router.navigate(['/']);
    }
    // console.log(localStorage.getItem('showBalance'));
    this.getUserName();
this.balanceshide = localStorage.getItem("balanceshow");

    this.myprofileServices.balanceamount().subscribe();

    this.myprofileServices.getPromotion().subscribe((res) => {
      this.bonusData = res.result;
      this.shortInfo = this.bonusData?.[1]?.["short-info"]?.split(" ");
      this.firstTwoWordofShortInfo = this.shortInfo?.slice(0, 2).join(" ");
      this.remainingWordsofShortInfo = this.shortInfo?.slice(2, this.shortInfo?.length)?.join(" ");
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.bonusData?.[1]?.["infoRules"]);
    })

    this.myprofileServices.getBalanceData().subscribe((data) => {
      this.isLoader = false;
      this.balnceamout=data?.result;
      this.sportamout=data?.result?.sWallet;
      this.bonusamout=data?.result?.bWallet;
      this.casinoamount=data?.result?.cWallet;
      // if (data?.result?.useBWallet == true) {
      //   this.isActiveTab = true;
      //   this.typeWallet = 'B';
      // }
      // else if (data?.result?.useSWallet == true) {
      //   this.isActiveTab = true;
      //   this.typeWallet = 'S';
      // }
      // else if (data?.result?.useCWallet == true) {
      //   this.isActiveTab = true;
      //   this.typeWallet = 'C';
      // }
      // else {
      //   this.typeWallet = 'M';
      // }
      // console.log("data", this.balnceamout);
    });

        }

  gotToComponent(link: any) {
    if (link == 'showBonuses') {
      this.router.navigate(['/']);
      this._bonus.showAllBonuses = true;
      return;
    } else if (link == 'goToAPK') {
      this.goAndriodToAPK();
      this._bonus.showAllBonuses = false;
    } else {
      this.router.navigate([link]);
      this.router.navigate([link], { queryParams: { source: 'profile' } });
      this._bonus.showAllBonuses = false;
  }

  }

  goToDeposit() {
    if (this.getToken()) {

      this.router.navigate(['/balancemanagement/Deposit']);
      this.router.navigate(['/balancemanagement/Deposit'], { queryParams: { source: 'profile' } });

    } else {
      document.getElementById('demo').click();
    }
  }

  goToDepositWithDrawReport() {
    if (this.getToken()) {
      this.router.navigate(['/balancemanagement/depositWithdrawReport']);
    } else {
      document.getElementById('demo').click();
    }
  }

  goToWithDraw() {
    this.authService.versionPopUpOpen = true;
    if (this.getToken()) {
      this.router.navigate(['/balancemanagement/Withdraw']);
      this.router.navigate(['/balancemanagement/Withdraw'], { queryParams: { source: 'profile' } });
    } else {
      document.getElementById('demo').click();
    }
  }

  goAndriodToAPK() {
    this.howToDownloadAPK = true;
    // const link = document.createElement('a');
    // link.href = 'https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/11EXCH1.0.1.apk';
    // link.target = '_self';
    // link.download = '11EXCH1.0.1.apk';
    // link.click();
  }
  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }
  getMember() {
    return localStorage.getItem('Member_code');
  }
  getUserName() {
    const user = localStorage.getItem("User_name")
    const mailLoginName = localStorage.getItem('loginName');

    if (user) {
      //check that user patter like /^91_\d{10}$/;
      const pattern =  /(91_\d{10})/;
      if(pattern.test(user)) {
        this.userName = this.formatPhoneNumberWithCountryCode(user);
      }else{
      this.userName = user;
        this.depositOptionShow = false;
      }
    } else if (mailLoginName) {
      this.userName = mailLoginName;
    }
  }

  formatPhoneNumberWithCountryCode(inputNumber:any) {
    if(inputNumber.startsWith('b06.')) {
      inputNumber = inputNumber.replace('b06.', '');
    }

    const countryCode = inputNumber.substring(0, 2);

    const phoneNumber = inputNumber.substring(2).replace(/[^0-9]/g, '');

    if (!phoneNumber.startsWith('+' + countryCode)) {
      return '+' + countryCode + phoneNumber;
    }
    return '+' + countryCode + phoneNumber;
  }

  openInfo() {
    this.infoPage = !this.infoPage;
  }
  closeInfoPage() {
    this.infoPage = false;
  }



  showInfo(type: any) {
       if(type=='B'){
 this.Exposure=this.bonusamout?.deductedExposure;
   this.ischeck=this.isBonus;
       }
       else{
        this.Exposure=this.sportamout?.deductedExposure;
        this.ischeck=this.isSport;
        //console.log('Inside showInfo this.Exposure' + JSON.stringify(this.sportamout));
       }

      this.myprofileServices.getWalletDetails(type).subscribe((res)=> {
        this.promoItemInfo = res
        this.infoWalletType = type
      })
  }
  closeInfo() {
    this.promoItemInfo = null
  }



  // profileshow(val: any) {
  //   if (val == 'p') {

  //     this.myprofileServices.personaldetailsdta.next(false);
  //     this.myprofileServices.verifyaccount.next(true);
  //     this.myprofileServices.ChangePassword.next(true);
  //     this.myprofileServices.authentication.next(true);
  //   } else if (val == 'cp') {
  //     this.myprofileServices.ChangePassword.next(false);
  //     this.myprofileServices.personaldetailsdta.next(true);
  //     this.myprofileServices.verifyaccount.next(true);
  //     this.myprofileServices.authentication.next(true);
  //   } else if (val == 'ac') {
  //     this.myprofileServices.verifyaccount.next(false);
  //     this.myprofileServices.ChangePassword.next(true);
  //     this.myprofileServices.authentication.next(true);
  //     this.myprofileServices.personaldetailsdta.next(true);
  //   } else {
  //     this.myprofileServices.authentication.next(false);
  //     this.myprofileServices.verifyaccount.next(true);
  //     this.myprofileServices.ChangePassword.next(true);
  //     this.myprofileServices.personaldetailsdta.next(true);
  //   }
  // }


  logOut() {
    localStorage.removeItem('ROYALTOKEN_KEY');
    localStorage.removeItem('Member_code');
    localStorage.removeItem('token');
    localStorage.removeItem('WaLink');
    this.router.navigate(['/']);
    localStorage.removeItem('loginName');
    localStorage.removeItem('User_name');
    localStorage.removeItem('betstack');
    localStorage.removeItem('showBalance');
    localStorage.removeItem('userData');
    this._bonus.getAllBonuses('alive');
    this._bonus.activeBonus=0;
    this.myprofileServices.totalBetCount=0;
    this.authService.setCurrentUserValue(null);
    this.myprofileServices.updateActiveMenuItem('/main');
    //location.reload();
   }

  logOutPopupWindow() {
    this.removePopup = !this.removePopup;
  }

  closePopupWindow() {
    this.animateCloseWindow=true;
    setTimeout(() => {
    this.removePopup = false;
      this.animateCloseWindow=false;
    }, 500);
  }

  formatAmountWithXs(amount: string): string {
    return amount.replace(/\d/g, 'X')
  }

  closeVideoPopup() {
    this.howToDownloadAPK = false;
    // window.location.href = "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/11EXCH1.0.0.apk";
  }

  goIosAPKInstruction() {
    this.showIosInstruction = true;
  }

  closePopup() {
    this.loyaltyPopup = false;
  }

  openPopup() {
    this.loyaltyPopup = true;
  }

  whatsappAccountRegistration() {
    const link = localStorage.getItem('WaLink') || 'https://walink.live/betkings';
    window.open(link, '_blank');
  }

        }

