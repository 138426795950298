<!-- spinning loader -->
<div class="max-width-550">

<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <!-- <img loading="lazy" class="loader1" src="../../../../../assets/images/loader-gif.gif" alt="Your Image"> -->
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

  </div>
</div>
<!-- spinning loader -->

<!--new live bet design section -->
<app-profile-header></app-profile-header>
<div class="live-bet">
  <div class="back-nav-head max-width-550">
    <svg (click)="goBack()" xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
      <path
        d="M7.12134 14.0761L0.25 7.20472L7.12134 0.333374L8.08333 1.31254L2.19115 7.20472L8.08333 13.0969L7.12134 14.0761Z"
        fill="#003c79" />
    </svg>
    <span class="back-nav-head-title">{{'Open Bets'|translate}}</span>
  </div>

  <div class="live-bet-section max-width-550">
    <div class="bet-btn-section">
      <div [ngClass]="{ 'unmatch-btn': matchedSection }" (click)="goToMatchedSection()" class="matched-btn">
        <span>{{'Matched bets'|translate}}</span>
        <div class="bet-count" *ngIf="(matchedBetsList$ | async)?.length>0">
          <span>{{(matchedBetsList$|async)?.length }}</span>
        </div>
      </div>
      <div [ngClass]="{ 'unmatch-btn': unmatchedSection }" (click)="goToUnmatchedSection()" class="matched-btn">
        <span>{{'Unmatched bets'|translate}}</span>
        <div class="bet-count" *ngIf="(unMatchedBetsList$ | async)?.length>0">
          <span>{{(unMatchedBetsList$ | async)?.length}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="live-bet-page">
    <!--Unmatched Betting detail-->

    <div class="unmatched-bet-section" *ngIf="unmatchedSection">
      <ng-container *ngIf="unMatchedBetsList?.length >= 0 && !isLoader">
        <ng-container *ngFor="let item1 of visibleUnMatcheBets; trackBy: trackByFn">
          <app-bet-history-card [item]="item1" *ngIf="unMatchedBetsList?.length > 0"></app-bet-history-card>
        </ng-container>
        <div class="bet-now-box" *ngIf="unMatchedBetsList?.length == 0 && !isLoader && visibleUnMatcheBets?.length==0">
          <div class="watch-box">
            <img src="../../../../../assets/images/watch-img.svg" alt="" />
            <span>{{"You don't have any unmatched bets"|translate}}</span>
          </div>
          <button (click)="goToBetHistoryPage()"><span>{{'Bet now'|translate}}</span></button>
        </div>
      </ng-container>
    </div>

    <!--Matched Betting detail-->

    <div class="matched-bet-section" *ngIf="matchedSection">
      <ng-container *ngIf="matchedBetsList.length >= 0 && !isLoader">
        <ng-container *ngFor="let item1 of visibleMatcheBets; trackBy: trackByFn">
          <app-bet-history-card [data]="item1" *ngIf="matchedBetsList.length > 0"></app-bet-history-card>
        </ng-container>
      </ng-container>
      <div class="bet-now-box" *ngIf="matchedBetsList.length === 0 && !isLoader && visibleMatcheBets?.length==0">
        <div class="watch-box">
          <img src="../../../../../assets/images/watch-img.svg" alt="" />
          <span>{{"You don't have any matched bets"|translate}}</span>
        </div>
        <button (click)="goToBetHistoryPage()"><span>{{'Bet now'|translate}}</span></button>
      </div>
    </div>




  </div>


</div>
</div>
