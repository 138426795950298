<app-profile-header></app-profile-header>
<div class="settings-page">
    <div class="myprofile-head">
        <span (click)="navigateBackToAccount()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M13.1213 17.0761L6.25 10.2047L13.1213 3.33337L14.0833 4.31254L8.19115 10.2047L14.0833 16.0969L13.1213 17.0761Z"
                    fill="#003c79" />
            </svg>
        </span>
        <span class="head-settings">
            {{'Settings'|translate}}
        </span>
    </div>
    <div class="main-content-settings">
        <div class="edit-stake">
            <span class="rect1"></span>
            <span class="rect2"></span>
            <span class="rect3"></span>
            <div class="edit-stake-head">
                {{'Edit Stakes'|translate}}
            </div>
            <!-- <div class="edit-stake-content">
                After changing stakes please logout and login again
            </div> -->
            <form [formGroup]="editStakesForm" style="width: 100%;">
                <div class="edit-stakes-input-section">
                    <input *ngFor="let stake of stakesList; let i = index" type="text" class="edit-stakes-input"
                        [formControlName]="'stake' + (i + 1)"
                        [ngClass]="{'edit-stakes-input-active': i === selectedInputIndex}" inputmode="numeric"
                        (input)="onInputChangeForEditStakes(i,$event)" (focus)="onInputFocusForEditStakes(i)"
                        (blur)="onInputBlurForEditStakes(i)" (keydown.enter)="$event.preventDefault()" min="100"
                        max="10000000" (keydown)="preventDecimalInput($event)" [pattern]="'\\d*'" />
                </div>
                <div *ngFor="let stake of stakesList; let index = index" class="stakeErrors">
                    <div class="password-match-error"
                        *ngIf="editStakesForm.get('stake' + (index + 1)).hasError('min')&& editStakesForm.get('stake' + (index + 1)).value?.length>0">
                        {{"The stake amount for 'Stake"|translate}} {{ index + 1 }}' {{'should not be less than 100.'|translate}}
                    </div>
                    <div class="password-match-error"
                        *ngIf="editStakesForm.get('stake' + (index + 1)).hasError('required')">
                        {{"The stake amount for 'Stake"|translate}} {{ index + 1 }}' {{'should not be empty.'|translate}}
                    </div>
                    <div class="password-match-error"
                        *ngIf="editStakesForm.get('stake' + (index + 1)).hasError('max')&& editStakesForm.get('stake' + (index + 1)).value?.length>7">
                        {{"The stake amount for 'Stake"|translate}} {{ index + 1 }}' {{'should not be greater than 1,00,00,000.'|translate}}
                    </div>
                </div>

                <button (click)="onSubmitEditStakes()" type="submit" class="active-button"
                    [ngClass]="{'disable-btn':editStakesForm.invalid|| editStakesForm.pristine}"
                    [disabled]="editStakesForm.invalid ||editStakesForm.pristine">
                    <span>{{'Update'|translate}}</span>
                </button>
            </form>

        </div>
        <div class="change-pass-section">
            <div class="change-password" (click)="expandChangePassword()">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="change-passsword-left">
                    <img loading="lazy" src="../../../../../assets/images/setting-change-password.svg" alt="">
                    <span>{{'Change Password'|translate}}</span>
                </div>
                <span class="change-pass-drpdown-1" [ngClass]="{'change-pass-drpdown':expandChangePasswordFlag===true}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                        <path
                            d="M6.00007 7.33333C5.8904 7.33397 5.78168 7.31295 5.68015 7.27148C5.57862 7.23001 5.48627 7.1689 5.40841 7.09167L0.408406 2.09167C0.251487 1.93475 0.16333 1.72192 0.16333 1.5C0.16333 1.27808 0.251487 1.06525 0.408406 0.908334C0.565326 0.751414 0.778155 0.663258 1.00007 0.663258C1.22199 0.663258 1.43482 0.751414 1.59174 0.908334L6.00007 5.325L10.4084 0.916668C10.5678 0.780146 10.7729 0.708807 10.9826 0.716908C11.1923 0.725009 11.3913 0.811953 11.5397 0.960365C11.6881 1.10878 11.7751 1.30773 11.7832 1.51746C11.7913 1.72719 11.7199 1.93225 11.5834 2.09167L6.58341 7.09167C6.42819 7.24562 6.21869 7.33241 6.00007 7.33333Z"
                            fill="#617293" />
                    </svg>
                </span>
            </div>
            <form action="" [formGroup]="changePasswordForm" (submit)="submitChangePasswordForm()"
                style="width:100%; z-index: 1000;">
                <div class="set-pass-section" *ngIf="expandChangePasswordFlag===true">
                    <div class="set-password">
                        <input [type]="showCurrentPassword ? 'text':'password'" placeholder="Enter Current Password"
                            minlength="2" maxlength="30" formControlName="currentPassword"
                            (keydown.enter)="$event.preventDefault()">
                        <img loading="lazy" (click)="showPasswordBtn('CurrentPassword')"
                            [src]="showCurrentPassword ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'"
                            alt="showpassword" />
                    </div>
                    <span class="password-match-error"
                        *ngIf="changePasswordForm.get('currentPassword').value?.length===0 && changePasswordForm.get('currentPassword').dirty && changePasswordForm.get('currentPassword').touched">{{'This field is required!'|translate}}</span>
                    <div class="forgot-current-pass">
                        <span (click)="forgotCurrentPasswordBtn()">{{'Forgot Current Password?'|translate}}</span>
                    </div>
                    <div class="set-password" style="margin-top: 0.56rem;">
                        <input [type]="showNewPassword? 'text':'password'" placeholder="{{'Enter New Password'|translate}}"
                            minlength="8" maxlength="16" formControlName="newPassword" (input)="checkForPasswordRules()"
                            (keydown.enter)="$event.preventDefault()">
                        <img loading="lazy" (click)="showPasswordBtn('NewPassword')"
                            [src]="showNewPassword ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'"
                            alt="showpassword" />
                    </div>
                    <span class="password-match-error"
                        *ngIf="changePasswordForm.get('newPassword').value?.length===0 && changePasswordForm.get('newPassword').dirty && changePasswordForm.get('newPassword').touched">{{'This field is required!'|translate}}</span>
                    <div class="passrulessection" *ngIf="changePasswordForm.get('newPassword').dirty">
                        <div class="password-rules" *ngFor="let rule of passwordrules; let i = index">
                            <div class="rule">
                                <span class="wrong-icon" *ngIf="ruleCompliance[i]===false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6"
                                        fill="none">
                                        <path
                                            d="M1.14934 5.35378C1.24299 5.44741 1.36999 5.5 1.50241 5.5C1.63483 5.5 1.76183 5.44741 1.85548 5.35378L3.5 3.70927L5.14451 5.35378C5.2387 5.44475 5.36485 5.49509 5.49579 5.49395C5.62673 5.49281 5.75199 5.44029 5.84458 5.3477C5.93717 5.25511 5.98969 5.12985 5.99083 4.99891C5.99197 4.86797 5.94163 4.74182 5.85066 4.64764L4.20615 3.00312L5.85066 1.35861C5.89836 1.31254 5.9364 1.25743 5.96258 1.19651C5.98875 1.13558 6.00253 1.07005 6.0031 1.00374C6.00368 0.937427 5.99104 0.871666 5.96593 0.810292C5.94082 0.748918 5.90374 0.69316 5.85685 0.64627C5.80996 0.59938 5.7542 0.562298 5.69283 0.537188C5.63145 0.512078 5.56569 0.499443 5.49938 0.500019C5.43308 0.500595 5.36754 0.514372 5.30662 0.540544C5.24569 0.566717 5.19058 0.604763 5.14451 0.65246L3.5 2.29697L1.85548 0.65246C1.80942 0.604763 1.75431 0.566717 1.69338 0.540544C1.63245 0.514372 1.56692 0.500595 1.50061 0.500019C1.4343 0.499443 1.36854 0.512078 1.30717 0.537188C1.2458 0.562298 1.19004 0.59938 1.14315 0.64627C1.09626 0.69316 1.05918 0.748918 1.03407 0.810292C1.00896 0.871666 0.99632 0.937427 0.996897 1.00374C0.997473 1.07005 1.01125 1.13558 1.03742 1.19651C1.0636 1.25743 1.10164 1.31254 1.14934 1.35861L2.79385 3.00312L1.14934 4.64764C1.05572 4.74129 1.00312 4.86829 1.00312 5.00071C1.00312 5.13313 1.05572 5.26013 1.14934 5.35378Z"
                                            fill="white" />
                                    </svg>
                                </span>
                                <span class="correct-icon" *ngIf="ruleCompliance[i]"> <svg
                                        xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <g clip-path="url(#clip0_289_3378)">
                                            <path
                                                d="M9.89844 0H4.10156C1.83633 0 0 1.83633 0 4.10156V9.89844C0 12.1637 1.83633 14 4.10156 14H9.89844C12.1637 14 14 12.1637 14 9.89844V4.10156C14 1.83633 12.1637 0 9.89844 0Z"
                                                fill="#22C55E" />
                                            <path
                                                d="M9.44234 4.51717L5.92703 8.03249L4.55738 6.66284C4.29051 6.39596 3.85766 6.39596 3.59051 6.66284C3.32363 6.92971 3.32363 7.36256 3.59051 7.62971L5.44359 9.4828C5.71047 9.74967 6.14332 9.74967 6.41047 9.4828L10.4092 5.48405C10.6761 5.21717 10.6761 4.78432 10.4092 4.51717C10.1423 4.2503 9.70949 4.2503 9.44234 4.51717Z"
                                                fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_289_3378">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span class="text-rule" [ngClass]="{'text-rule-success':ruleCompliance[i]}">
                                    {{rule}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="set-password" style="margin-top: 0.75rem;">
                        <input [type]="showConfirmPassword? 'text':'password'" placeholder="Confirm Password"
                            minlength="8" maxlength="16" formControlName="confirmPassword">
                        <img loading="lazy" (click)="showPasswordBtn('ConfirmPassword')"
                            [src]="showConfirmPassword ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'"
                            alt="showpassword" />
                    </div>
                    <span class="password-match-error"
                        *ngIf="(changePasswordForm.get('newPassword').value !== changePasswordForm.get('confirmPassword').value) && changePasswordForm.get('confirmPassword').dirty && changePasswordForm.get('confirmPassword').value?.length>0">{{'Password does not match!'|translate}}</span>
                    <span class="password-match-error"
                        *ngIf="changePasswordForm.get('confirmPassword').value?.length===0 && changePasswordForm.get('confirmPassword').dirty && changePasswordForm.get('confirmPassword').touched">{{'This field is required!'|translate}}</span>
                    <button type="submit" class="set-pass-btn-enabled"
                        [disabled]="changePasswordForm?.invalid||(changePasswordForm.get('newPassword').value !== changePasswordForm.get('confirmPassword').value )|| changePasswordForm.get('currentPassword').value?.length <= 0 "
                        [ngClass]="{'set-pass-btn':changePasswordForm.invalid ||(changePasswordForm.get('newPassword').value !== changePasswordForm.get('confirmPassword').value )|| changePasswordForm.get('currentPassword').value?.length <= 0 }">
                        <span>{{'Save'|translate}}</span>
                    </button>
                </div>
            </form>
        </div>

        <div class="other-settings-section">
            <div class="other-settings-section-head">{{'Other Settings'|translate}}</div>
            <div class="other-settings-section-main">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="other-settings-section-main-left"> <span class="balance-text">{{'Balance'|translate}}</span> <span
                        class="balance-subtext">{{'Disable balance display in the header'|translate}}</span> </div>
                <div class="other-settings-switch"> <app-switch size="large" name="skip_notification"
                        (change)="changed()" [(ngModel)]="value"></app-switch> </div>
            </div>
            <div class="lang-box">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="lang-card" (click)="openLangPopup()">
                    <div class="lang-text">
                        <span>{{activeLang}}</span>
                        <small>{{'Active language'|translate}}</small>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                        <path
                            d="M12.4991 17.1667C12.362 17.1675 12.2261 17.1412 12.0992 17.0894C11.9722 17.0375 11.8568 16.9612 11.7595 16.8646L5.50947 10.6146C5.31332 10.4185 5.20313 10.1524 5.20312 9.87503C5.20312 9.59763 5.31332 9.3316 5.50947 9.13545C5.70562 8.9393 5.97166 8.8291 6.24905 8.8291C6.52645 8.8291 6.79249 8.9393 6.98864 9.13545L12.4991 14.6563L18.0095 9.14586C18.2087 8.97521 18.4651 8.88604 18.7272 8.89616C18.9894 8.90629 19.2381 9.01497 19.4236 9.20049C19.6091 9.386 19.7178 9.63469 19.7279 9.89685C19.738 10.159 19.6489 10.4153 19.4782 10.6146L13.2282 16.8646C13.0342 17.0571 12.7723 17.1655 12.4991 17.1667Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Language popup -->
<div class="lang-popup Modal-Background max-width-550" *ngIf="languagePopup">
    <div class="popup-box">
        <div class="close-image">
            <span>{{'Change Language'|translate}}</span>
            <svg (click)="closeLangPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                viewBox="0 0 20 20" fill="none">
                <path
                    d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433288 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM13.8564 12.5718C13.9432 12.6557 14.0124 12.756 14.0601 12.8669C14.1077 12.9778 14.1328 13.0971 14.1339 13.2178C14.1349 13.3385 14.1119 13.4582 14.0662 13.57C14.0205 13.6817 13.953 13.7832 13.8676 13.8685C13.7823 13.9539 13.6808 14.0214 13.5691 14.0671C13.4573 14.1128 13.3376 14.1358 13.2169 14.1348C13.0962 14.1337 12.9769 14.1086 12.866 14.061C12.7551 14.0134 12.6548 13.9441 12.5709 13.8573L10 11.2855L7.42909 13.8573C7.25764 14.0229 7.028 14.1145 6.78964 14.1124C6.55128 14.1104 6.32327 14.0147 6.15471 13.8462C5.98616 13.6776 5.89055 13.4496 5.88848 13.2113C5.88641 12.9729 5.97804 12.7433 6.14364 12.5718L8.71455 10L6.14364 7.42818C6.05681 7.34432 5.98756 7.24401 5.93991 7.13309C5.89227 7.02218 5.86719 6.90289 5.86614 6.78218C5.86509 6.66147 5.88809 6.54176 5.9338 6.43004C5.97951 6.31832 6.04701 6.21681 6.13237 6.13146C6.21773 6.0461 6.31923 5.9786 6.43095 5.93289C6.54268 5.88718 6.66239 5.86418 6.78309 5.86523C6.9038 5.86627 7.02309 5.89135 7.13401 5.939C7.24492 5.98664 7.34523 6.0559 7.42909 6.14273L10 8.71454L12.5709 6.14273C12.6548 6.0559 12.7551 5.98664 12.866 5.939C12.9769 5.89135 13.0962 5.86627 13.2169 5.86523C13.3376 5.86418 13.4573 5.88718 13.5691 5.93289C13.6808 5.9786 13.7823 6.0461 13.8676 6.13146C13.953 6.21681 14.0205 6.31832 14.0662 6.43004C14.1119 6.54176 14.1349 6.66147 14.1339 6.78218C14.1328 6.90289 14.1077 7.02218 14.0601 7.13309C14.0124 7.24401 13.9432 7.34432 13.8564 7.42818L11.2855 10L13.8564 12.5718Z"
                    fill="#929EB5" />
            </svg>
        </div>
        <div class="vr-line"></div>
        <div class="lang-card-body">
            <div class="lang-body">
                <ng-container *ngFor="let lang of langs; let index=index">
                    <div class="first" (click)="changeLanguage(lang.value)"
                        [ngClass]="{'first_active': activeLanguage[index]}">
                        <span>{{lang?.name}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- forgot current password popup -->
<div class="Modal-Background forgot-current-password-pop-up max-width-550" [ngClass]="{'closeAnimationForgotWindow':closeWindowPopup}"
    *ngIf="forgotCurrentPassword">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="top-div">
        <span class="main-title">{{'Forgot Password'|translate}}</span>
        <span class="close-svg" (click)="cancel()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.91709 10.2587C2.6893 10.4866 2.68934 10.8559 2.91716 11.0837C3.14499 11.3115 3.51433 11.3115 3.74212 11.0836L7.00018 7.82499L10.2585 11.0833C10.4863 11.3111 10.8557 11.3111 11.0835 11.0833C11.3112 10.8555 11.3112 10.4862 11.0835 10.2584L7.82507 6.99998L11.0832 3.74126C11.311 3.51343 11.311 3.14409 11.0831 2.91631C10.8553 2.68851 10.4859 2.68855 10.2582 2.91638L7.00006 6.17503L3.74172 2.91666C3.51392 2.68885 3.14457 2.68885 2.91677 2.91666C2.68896 3.14446 2.68896 3.51381 2.91677 3.74162L6.17523 7.00004L2.91709 10.2587Z"
                    fill="#111827" />
            </svg>
        </span>
    </div>
    <div class="hr-line-forgot"></div>
    <div class="main-content">
        {{'You will be logged out to reset your password'|translate}}
    </div>
    <div class="btns">
        <button class="yes-btn" (click)="logOut()">
            <span>{{'Yes, Logout'|translate}}</span></button>
        <button class="no-btn" (click)="cancel()">
            <span>{{'No'|translate}}</span>
        </button>
    </div>
</div>
<div class="Modal-Background parent-backdrop-profile max-width-550" [ngClass]="{'closeAnimationBackdrop':closeWindowPopup}"
    *ngIf="forgotCurrentPassword"></div>
