<!-- spinning loader -->
<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

    </div>
</div>
<!-- spinning loader -->

<re-captcha *ngIf="rSiteKey" #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"
    [siteKey]="rSiteKey"></re-captcha>

<div class="register-box">
    <div class="register-card">
        <div class="register-card-header">
            <span>{{'New Member?'|translate}}</span>
            <div class="register-text">
                <p>{{'Register Now,'|translate}}</p>
                <img style="height:24px;width:24px" src="../../../assets/images/hand-logo.webp" alt="">
            </div>
        </div>
        <!-- <div *ngIf="hideOtherOptionsafterGetOtp===false" (click)="whatsappAccountRegistration()" class="whatsup-card">
            <img loading="lazy" src="../../../assets/images/whatsup.svg" alt="">
            <span>{{'Get Instant ID on Whatsapp'|translate}}</span>
        </div> -->
        <form [formGroup]="registerForm">
            <div class="mobile-input">
                <span>{{'Mobile Number*'|translate}}</span>
                <div class="input-box">
                    <span class="drp-btn">{{'+91'|translate}}</span>
                    <img loading="lazy" class="india-flag" src="../../../assets/images/ind-flag-icon.svg" alt="">
                    <div class="str-line">
                        <img loading="lazy" src="../../../assets/images/straight-line1.svg" alt="">
                    </div>
                    <input minlength="10" maxlength="10" (input)="onInput($event)" formControlName="mobileNo" type="tel"
                        placeholder="{{'Enter your Phone Number'|translate}}" class="mobile-input" [disabled]="showOtpInput">
                    <div *ngIf="!isRegisterMobileNumberValid && registerForm.get('mobileNo').value.length > 0"
                        class="error-msg-logo">
                        <img src="../../../assets/images/error-logo.svg" alt="">
                    </div>
                    <img *ngIf="isRegisterMobileNumberValid"
                        style="position: absolute; right: -1rem; margin-right: 1.5rem;"
                        src="../../../assets/images/right-click-check.svg" alt="">
                </div>
            </div>
            <div *ngIf="passwordInput" style="width: 100%; margin-top: 1rem; margin-bottom: 1rem;">
                <div class="pwd-box">
                    <span class="pwd-text">{{'Password*'|translate}}</span>
                    <input
                        [ngClass]="{'registerPassword':registerForm.get('password').dirty, 'registerPasswordError':greenCheckColorForPassword==false && registerForm.get('password').dirty}"
                        formControlName="password" (keyup)="passwordValidation($event)"
                        [type]="showPassword ? 'text': 'password'" placeholder="{{'Enter your password'|translate}}" maxlength="16"
                        minlength="8">
                    <span class="showPass" (click)="showPassword = !showPassword">
                        <img
                            [src]="showPassword ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'" />
                    </span>

                </div>
                <ul *ngIf="registerForm.get('password').touched || registerForm.get('password').dirty">
                    <li>
                        <img loading="lazy" *ngIf="!passwordMinLength" src="../../../assets/images/error-msg-box.svg"
                            alt="">
                        <img loading="lazy" *ngIf="passwordMinLength" src="../../../assets/images/success-msg-txt.svg"
                            alt="">
                        <span [ngClass]="{'green-text':passwordMinLength}" class="red-text">
                            {{"Must be atleast 8 Characters!"|translate}}
                        </span>
                    </li>
                    <li>
                        <img loading="lazy" *ngIf="!passwordHaveNumber" src="../../../assets/images/error-msg-box.svg"
                            alt="">
                        <img loading="lazy" *ngIf="passwordHaveNumber" src="../../../assets/images/success-msg-txt.svg"
                            alt="">
                        <span [ngClass]="{'green-text':passwordHaveNumber}" class="red-text">
                            {{'Must contain atleast 1 number!'|translate}}
                        </span>
                    </li>
                    <li>
                        <img loading="lazy" *ngIf="!passwordHaveUpperCase"
                            src="../../../assets/images/error-msg-box.svg" alt="">
                        <img loading="lazy" *ngIf="passwordHaveUpperCase"
                            src="../../../assets/images/success-msg-txt.svg" alt="">
                        <span [ngClass]="{'green-text':passwordHaveUpperCase}" class="red-text">
                            {{'Must contain atleast 1 in Capital Case!'|translate}}
                        </span>
                    </li>
                    <li>
                        <img loading="lazy" *ngIf="!passwordHaveLowerCase"
                            src="../../../assets/images/error-msg-box.svg" alt="">
                        <img loading="lazy" *ngIf="passwordHaveLowerCase"
                            src="../../../assets/images/success-msg-txt.svg" alt="">
                        <span [ngClass]="{'green-text':passwordHaveLowerCase}" class="red-text">
                            {{'Must contain atleast 1 Letter in Small Case!'|translate}}
                        </span>
                    </li>
                    <li>
                        <img loading="lazy" *ngIf="!passwordHaveSpecialChar"
                            src="../../../assets/images/error-msg-box.svg" alt="">
                        <img loading="lazy" *ngIf="passwordHaveSpecialChar"
                            src="../../../assets/images/success-msg-txt.svg" alt="">
                        <span [ngClass]="{'green-text':passwordHaveSpecialChar}" class="red-text">
                            {{'Must contain atleast 1 Special Character!'|translate}}
                        </span>
                    </li>
                </ul>
                <div class="pwd-box">
                    <span class="pwd-text">{{'Confirm Password*'|translate}}</span>
                    <input
                        [ngClass]="{'registerPassword':registerForm.get('confirmPassword').dirty, 'registerPasswordError':registerForm.hasError('passwordMismatch') && registerForm.get('password').touched}"
                        formControlName="confirmPassword" [type]="showConfirmPassword ? 'text': 'password'"
                        placeholder="{{'Enter Confirm password'|translate}}" maxlength="16" minlength="8">
                    <span class="showPass" (click)="showConfirmPassword = !showConfirmPassword">
                        <img
                            [src]="showConfirmPassword ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'" />
                    </span>


                    <div *ngIf="registerForm.hasError('passwordMismatch') && registerForm.get('password').touched"
                        class="error-msg">
                        <span>{{"Passwords do not match"|translate}}</span>
                    </div>
                </div>
            </div>
            <div (click)="acceptUserConsent()" class="terms">
                <div class="radio-check">
                    <img *ngIf="registerForm.get('userConsent').value" style="height: 1rem;"
                        src="../../../assets/images/checked.png" alt="">
                </div>
                <span>{{'I confirm that I am 18 years old or above and agree to the terms and conditions.'|translate}}</span>
            </div>

            <button *ngIf="passwordInput" (click)="registerUser()" class="register-btn"
                [disabled]="(registerForm.get('password').value !==registerForm.get('confirmPassword').value) || (registerForm.get('password').value.length < 1 && registerForm.get('confirmPassword').value.length < 1)">
                <span>{{'Register Now'|translate}}</span>
            </button>

            <button
                [disabled]="(registerForm.get('mobileNo').value.length < 10) || (registerForm.get('userConsent').value === false)"
                *ngIf="!showOtpInput && !passwordInput" (click)="triggerOtp()" class="otp-btn">
                <span>{{'Get OTP'|translate}}</span>
            </button>
            <div *ngIf="showOtpInput">
                <div class="otp-input-box">
                    <span>{{'Enter OTP*'|translate}}</span>
                    <div class="input-boxes" [ngClass]="{'invalidOtp':registerForm.get('otp4').hasError('incorrect')}">
                        <input formControlName="otp1" placeholder="_" id="first"
                            (keyup)="otpInputHandler($event)" (input)="onInputForOtp($event)" type="text"
                            [maxLength]="1" inputmode="numeric" (keydown)="otpEnterIncomplete($event)">
                        <input formControlName="otp2" placeholder="_" id="second"
                            (keyup)="otpInputHandler($event)" (input)="onInputForOtp($event)" type="text"
                            [maxLength]="1" inputmode="numeric" (keydown)="otpEnterIncomplete($event)">
                        <input formControlName="otp3" placeholder="_" id="third"
                            (keyup)="otpInputHandler($event)" (input)="onInputForOtp($event)" type="text"
                            [maxLength]="1" inputmode="numeric" (keydown)="otpEnterIncomplete($event)">
                        <input formControlName="otp4" placeholder="_" id="fourth"
                            (keyup)="otpInputHandler($event)" (input)="onInputForOtp($event)" type="text"
                            [maxLength]="1" inputmode="numeric" (keydown)="otpEnterIncomplete1($event)">
                    </div>
                    <span *ngIf="otpTimerSeconds > 0" class="resend-otp">{{'Resend in 00:'|translate}}{{otpTimerSeconds | number:
                        '2.0'}}</span>
                    <span (click)="resendOtp()" *ngIf="otpTimerSeconds <= 0" class="resend-otp">{{'Resend OTP'|translate}}</span>
                </div>
                <button (click)="otpSubmit()" class="submit-btn"
                    [disabled]="!registerForm.get('otp1').value || !registerForm.get('otp2').value || !registerForm.get('otp3').value || !registerForm.get('otp4').value">
                    <span>{{'Submit'|translate}}</span>
                </button>
            </div>
        </form>

        <div class="register-with" *ngIf="hideOtherOptionsafterGetOtp===false">
            <div class="line"></div>
            <span>{{'or register with'|translate}}</span>
            <div class="line"></div>
        </div>
        <div class="login-box" *ngIf="hideOtherOptionsafterGetOtp===false">
            <div class="google-login" (click)="glogin()" id="buttonDiv">
                <img loading="lazy" src="../../../assets/images/google-icon.svg" alt="">
                <span>{{'Google'|translate}}</span>
            </div>
<!--            <div class="whatsup-login" (click)="walogin()">-->
<!--                <img loading="lazy" src="../../../assets/images/whatsup-icon1.svg" alt="">-->
<!--                <span>{{'Whatsapp'|translate}}</span>-->
<!--            </div>-->
        </div>

      <div *ngIf="hideOtherOptionsafterGetOtp===false" (click)="whatsappAccountRegistration()" class="whatsup-card">
        <img loading="lazy" src="../../../assets/images/whatsup.svg" alt="">
        <span>{{'Get Instant ID on Whatsapp'|translate}}</span>
      </div>

    </div>
</div>


<!-- Register Congratulation Popup -->
<app-register-popup class="Modal-Background max-width-550" *ngIf="registerCongoPopup" (closeClick)="signInAfterRegister()">
    <div class="congo-box">
        <div class="congo">
            <!-- <img loading="eager" class="phatake" src="../../../assets/images/firework.gif" alt=""> -->
        </div>
        <div class="congo-msg">
            <h1>{{'Congratulations!'|translate}}</h1>
            <span style="color: gray;">{{'Registration completed successfully!'|translate}}</span>
        </div>
        <div class="congo-popup-card">
            <img loading="lazy" class="star1" src="../../../assets/images/star.svg" alt="">
            <img loading="lazy" class="star2" src="../../../assets/images/star.svg" alt="">
            <img loading="lazy" class="star3" src="../../../assets/images/star.svg" alt="">
            <img class="bonus-img" loading="lazy" class="back-svg" src="../../../assets/images/register-popup-gift.png"
                alt="">
            <img loading="lazy" class="back-svg1" src="../../../assets/images/back-svg.svg" alt="">
            <img loading="lazy" class="back-svg2" src="../../../assets/images/back-svg.svg" alt="">
            <img loading="lazy" class="back-svg3" src="../../../assets/images/back-svg.svg" alt="">
            <img loading="lazy" class="back-svg4" src="../../../assets/images/back-svg.svg" alt="">
            <div class="congo-text">
                <span>{{'500% Deposit Bonus'|translate}} <br> {{"+ 5% free money"|translate}}</span>
                <button (click)="goToDeposit()">
                    <span>{{'Deposit Now'|translate}}</span>
                </button>
            </div>

            <div class="slide"></div>
            <div class="slide1"></div>
        </div>
    </div>
</app-register-popup>


<!--Save Account Popup-->
<app-register-popup class="Modal-Background max-width-550" *ngIf="registerSaveCongoPopup"
    (closeClick)="registerSaveCongoPopup = false">
    <div class="congo-box1">
        <div class="congo1">
            <!-- <img loading="eager" class="phatake" src="../../../assets/images/firework.gif" alt=""> -->
        </div>
        <div class="congo-msg" style="margin-top: 1.5rem;">
            <h1>{{'Congratulations!'|translate}}</h1>
            <span style="color: gray;">{{'Registration completed successfully!'|translate}}</span>
        </div>

        <div class="account-box">
            <span>{{"Do not forget to save your login & password, so you don't lose access to the site"|translate}}</span>
            <div class="user-input">
                <div class="user-name">
                    <div class="logo-box">
                        <img loading="lazy" src="../../../assets/images/user-logo-box.svg" alt="">
                    </div>
                    <div class="name">
                        <span>{{'User Name'|translate}}</span>
                        <div class="copy-logo">
                            <span>Cameron Williamson</span>
                            <img loading="lazy" src="../../../assets/images/copy-svg-logo.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="user-name">
                    <div class="logo-box">
                        <img loading="lazy" src="../../../assets/images/lock-svg.svg" alt="">
                    </div>
                    <div class="name">
                        <span>{{'Password'|translate}}</span>
                        <div class="copy-logo">
                            <span>**********</span>
                            <img loading="lazy" src="../../../assets/images/copy-svg-logo.svg" alt="">
                        </div>
                    </div>
                </div>
                <button><span>{{'Go Back'|translate}}</span></button>
            </div>
        </div>

        <div class="congo-popup-card">
            <img loading="lazy" class="star1" src="../../../assets/images/star.svg" alt="">
            <img loading="lazy" class="star2" src="../../../assets/images/star.svg" alt="">
            <img loading="lazy" class="star3" src="../../../assets/images/star.svg" alt="">
            <img loading="lazy" class="back-svg" src="../../../assets/images/register-popup-gift.png" alt="">
            <img loading="lazy" class="back-svg1" src="../../../assets/images/back-svg.svg" alt="">
            <img loading="lazy" class="back-svg2" src="../../../assets/images/back-svg.svg" alt="">
            <img loading="lazy" class="back-svg3" src="../../../assets/images/back-svg.svg" alt="">
            <img loading="lazy" class="back-svg4" src="../../../assets/images/back-svg.svg" alt="">
            <div class="congo-text">
                <span>{{'500% Deposit Bonus'|translate}} <br> {{'+ 5% free money'|translate}}</span>
                <button>
                    <span>{{'Deposit Now'|translate}}</span>
                </button>
            </div>

            <div class="slide"></div>
        </div>
    </div>
</app-register-popup>
