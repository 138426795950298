<!-- spinning loader -->
<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>
  </div>
</div>
<!-- spinning loader -->

<re-captcha *ngIf="rSiteKey" #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"
  [siteKey]="rSiteKey"></re-captcha>

<div *ngIf="!showForgot" class="login-page">
  <div class="login-box">
    <div class="login-card">
      <div class="login-card-header">
        <span>{{'Log in'|translate}}</span>
        <div class="login-text">
          <p>{{'Welcome to Betkings'|translate}}</p>
          <img style="height:24px;width:24px" loading="lazy" src="../../../assets/images/hand-logo.webp" alt="waving-hand" />
        </div>
      </div>
      <div class="login-input-btn-animation">
        <div class="login-input-btn">
          <button [ngClass]="{'btn1':showSigninSectionWithPhoneNumber}" (click)="handleSigninSectionWithPhoneNumber()">
            <span>{{'Phone Number'|translate}}</span>
          </button>
          <button [ngClass]="{'btn1':showSigninSectionWithUserId}" lass="taeb"
            (click)="handleSigninSectionWithuserId()">
            <span>{{'User ID'|translate}}</span>
          </button>
        </div>
        <div [ngClass]="{'animation-div ':showSigninSectionWithUserId}">
        </div>
        <div [ngClass]="{'animation-div-1 ':showSigninSectionWithPhoneNumber}">
        </div>
      </div>

      <form action="" [ngClass]="{'animateSignInFormMobileNo':showSigninSectionWithPhoneNumber}"
        [formGroup]="signInForm" *ngIf="showSigninSectionWithPhoneNumber" (ngSubmit)="signIn()" style="width: 100%;">
        <div class="mobile-input">
          <span>{{'Mobile Number'|translate}}</span>
          <div class="input-box" [ngClass]="{'input-box-1':focusedDiv}" id="sign-in-Input-Box" (click)="onFocus()"
            (clickOutside)="onFocurRemove()">
            <span class="drp-btn">{{'+91'|translate}}</span>
            <img loading="lazy" class="india-flag" src="../../../assets/images/ind-flag-icon.svg" alt="" />
            <div class="str-line">
              <img loading="lazy" src="../../../assets/images/straight-line1.svg" alt="" />
            </div>
            <input id="login-mobile-number" type="tel" minlength="10" (input)="onInput($event)" formControlName="mobileNo" maxlength="10"
              placeholder="{{'Enter your Phone Number'|translate}}" required />
          </div>
          <img loading="lazy" class="right-click" *ngIf="rightClick"
            style="position: absolute; right: -1rem; margin-right: 1.5rem"
            src="../../../assets/images/right-click-check.svg" alt="" />

          <div class="password-input" [ngClass]="{'password-input-1':focusesPassword}" ngClass=""
            (click)="onFocusPassword()" (clickOutside)="onFocurRemovePassword()">
            <span>{{'Password*'|translate}}</span>
            <input id="password" [type]="showPassword ? 'text' : 'password'" minlength="6" formControlName="password"
              placeholder="{{'Enter your password'|translate}}" required />
            <span class="showPass" (click)="handleShowPassword()">
              <div [ngClass]="{'spinner':imgLoaded===false}"></div>
              <!-- Use the (load) event to update the imagesLoaded variable -->
              <img loading="lazy"
                [src]="showPassword ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'"
                (load)="imgLoaded = true" />
            </span>
            <div class="forgot">
              <span (click)="handleForgot()">{{'Forgot Password?'|translate}}</span>
            </div>
          </div>
        </div>
        <button class="login-btn" type="submit"><span>{{'Log in'|translate}}</span></button>
      </form>
      <form action="" [ngClass]="{'animateSignInFormUserId':showSigninSectionWithUserId }"
        *ngIf="showSigninSectionWithUserId" [formGroup]="signInFormByUserId" (ngSubmit)="signInByUserId()"
        style="width: 100%;">
        <div class="whatsup-login-box">
          <div class="mobile-input">
            <span>{{'User ID'|translate}}</span>
            <div class="input-box" [ngClass]="{'input-box-1': focusedDivForUserId}" (click)="focusForUserId()"
              (clickOutside)="onFocurRemoveForUserId()">
              <div [ngClass]="{'spinner':userImageLoaded===false}"></div>
              <img loading="lazy"
                [src]="signInFormByUserId?.get('userId')?.value?.length > 0 ? '../../../assets/images/login-person1.svg' : '../../../assets/images/login-person.svg'"
                alt="user" (load)="userImageLoaded=true">
              <div class="str-line">
                <img src="../../../assets/images/straight-line1.svg" alt="user">
              </div>
              <input style="color: #FFF;" type="text" maxLength="25" minlength="1" placeholder="{{'Enter User ID'|translate}}"
                formControlName="userId" (keydown)="checkForSpace($event)" (input)="checkForSpace1($event)" required>
            </div>

            <div class="password-input" [ngClass]="{'password-input-1': focusedPasswordforUserId}"
              (click)="onFocusPasswordForUserId()" (clickOutside)="onFocurRemovePasswordForUserId()">
              <span>{{'Password*'|translate}}</span>
              <input [type]="shpowPasswordforUserId ? 'text' : 'password'" placeholder="{{'Enter your password'|translate}}"
                minlength="6" formControlName="password" required>
              <span class="showPass" (click)="handleShowPasswordForUserId()">
                <div [ngClass]="{'spinner':passImageLoaded===false}"></div>
                <!-- Use the (load) event to update the imagesLoaded variable -->
                <img loading="lazy"
                  [src]="shpowPasswordforUserId ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'"
                  (load)="passImageLoaded = true" />
              </span>
              </div>
            </div>
          </div>
          <button class="login-btn" type="submit"><span>{{'Log in'|translate}}</span></button>
<!--        </div>-->
      </form>
    </div>
    <div class="login-with">
      <div class="line"></div>
      <span>{{'or log in with'|translate}}</span>
      <div class="line"></div>
    </div>


    <div class="login-box1">
      <div id="buttonDiv" class="google-login" (click)="glogin()">
        <img loading="lazy" src="../../../assets/images/google-icon.svg" alt="" />
        <span>{{'Google'|translate}}</span>
      </div>
<!--      <div class="whatsup-login" (click)="walogin()" style="cursor: pointer;">-->
<!--        <img loading="lazy" src="../../../assets/images/whatsup-icon1.svg" alt="" />-->
<!--        <span>{{'Whatsapp'|translate}}</span>-->
<!--      </div>-->
    </div>
  </div>
</div>


<!-- Forgot page section -->
<!-- Forgot section for SMS -->

<div *ngIf="showForgot" class="forgot-password-box">
  <div class="forgot-password-card">
    <form action="" [formGroup]="forgotPasswordFormBySms" style="width: 100%;">
      <div class="forgot-input">
        <div class="forgot-password-card-header">
          <span>{{PasswordSectionforSms ? 'Reset':'Forgot'}} {{'Password'|translate}}</span>
        </div>

        <div class="forgot-password-boxs">
          <div class="sms-box" (click)="handleSmsPopup()">
            <img loading="lazy" class="star1" src="../../../assets/images/star.svg" alt="">
            <div class="logo-box">
              <img loading="lazy" src="../../../assets/images/sms-logo.svg" alt="">
              <span>{{'SMS'|translate}}</span>
              <div class="check-btn">
                <span class="dot-img" *ngIf="showSmsPopup"></span>
              </div>
            </div>
          </div>
          <!-- <div class="Whatsapp-box" (click)="handleWhatsAppPopUp()">
            <img class="star1" src="../../../assets/images/star.svg" alt="">
            <div class="logo-box">
              <img src="../../../assets/images/whatsup-icon1.svg" alt="">
              <span>Whatsapp</span>
              <div class="check-btn">
                <span class="dot-img" *ngIf="showWhtasAppPopUp"></span>
              </div>
            </div>
          </div> -->
        </div>

        <div *ngIf="showSmsPopup && !OtpInputSection && !PasswordSectionforSms" class="mobile-input">
          <span>{{"Mobile Number"|translate}}</span>
          <div class="input-box">
            <span class="drp-btn">
              {{'+91'|translate}}
            </span>
            <img loading="lazy" src="../../../assets/images/ind-flag-icon.svg" alt="">
            <div class="str-line"></div>
            <input type="tel" minlength="10" (input)="onInputForForgotPassword($event)" maxlength="10"
              (keydown)="mobileEnter($event)" placeholder="{{'Enter your Phone Number'|translate}}" *ngIf="showSmsPopup" required
              formControlName="mobileNo" />
            <img loading="lazy" class="right-click" *ngIf="rightClickforForgotPasswordBySms"
              style="position: absolute; right: 0rem; margin-right: 1.5rem"
              src="../../../assets/images/right-click-check.svg" alt="" />
          </div>
        </div>

        <div class="forgot-otp-box" *ngIf="OtpInputSection">
          <span>{{'Enter OTP'|translate}}</span>
          <div class="input-boxs">
            <input placeholder="_" [ngClass]="{'invalidOtp':forgotPasswordFormBySms.get('otp4').hasError('incorrect')}"
              id="first" formControlName="otp1" (focus)="selectAll($event)" (input)="onInputForOtp($event)"
              (keyup)="otpInputHandler($event)" type="text" [maxLength]="1" inputmode="numeric"
              (keydown)="otpEnterIncomplete($event)">
            <input placeholder="_" [ngClass]="{'invalidOtp':forgotPasswordFormBySms.get('otp4').hasError('incorrect')}"
              id="second" formControlName="otp2" (focus)="selectAll($event)" (input)="onInputForOtp($event)"
              (keyup)="otpInputHandler($event)" type="text" [maxLength]="1" inputmode="numeric"
              (keydown)="otpEnterIncomplete($event)">
            <input placeholder="_" [ngClass]="{'invalidOtp':forgotPasswordFormBySms.get('otp4').hasError('incorrect')}"
              id="third" formControlName="otp3" (focus)="selectAll($event)" (input)="onInputForOtp($event)"
              (keyup)="otpInputHandler($event)" type="text" [maxLength]="1" inputmode="numeric"
              (keydown)="otpEnterIncomplete($event)">
            <input placeholder="_" [ngClass]="{'invalidOtp':forgotPasswordFormBySms.get('otp4').hasError('incorrect')}"
              id="fourth" formControlName="otp4" (focus)="selectAll($event)" (input)="onInputForOtp($event)"
              (keyup)="otpInputHandler($event)" type="text" [maxLength]="1" inputmode="numeric"
              (keydown)="otpEnterIncomplete1($event)">
          </div>
          <span *ngIf="otpTimerSeconds > 0" class="resend-otp">{{'Resend in 00:'|translate}}{{otpTimerSeconds | number:
            '2.0'}}</span>
          <span (click)="resendOtp()" *ngIf="otpTimerSeconds <= 0" class="resend-otp">{{'Resend OTP'|translate}}</span>
        </div>

        <div style="width: 100%;">
          <div class="password-input-box" *ngIf="PasswordSectionforSms">
            <span>{{"Password*"|translate}}</span>
            <input [minLength]="8" required formControlName="password" (keyup)="passwordValidation($event)"
              [maxLength]="15" [type]="showPasswordForForgotBySms ? 'text' : 'password'" id="customPassword"
              placeholder="{{'Enter New password'|translate}}">
            <span class="showPass" (click)="handleShowPasswordForSms()">
              <img loading="lazy"
                [src]="showPasswordForForgotBySms ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'"
                alt="showpassword" />
            </span>
          </div>


          <ul
            *ngIf="conditionsforForPassword && (forgotPasswordFormBySms.get('password').touched || forgotPasswordFormBySms.get('password').dirty)">
            <li>
              <img loading="lazy" *ngIf="!passwordMinLength" src="../../../assets/images/error-msg-box.svg" alt="">
              <img loading="lazy" *ngIf="passwordMinLength" src="../../../assets/images/success-msg-txt.svg" alt="">
              <span [ngClass]="{'green-text':passwordMinLength}" class="red-text">
                {{'Must be atleast 8 Characters!'|translate}}
              </span>
            </li>
            <li>
              <img loading="lazy" *ngIf="!passwordHaveNumber" src="../../../assets/images/error-msg-box.svg" alt="">
              <img loading="lazy" *ngIf="passwordHaveNumber" src="../../../assets/images/success-msg-txt.svg" alt="">
              <span [ngClass]="{'green-text':passwordHaveNumber}" class="red-text">
                {{'Must contain atleast 1 number!'|translate}}
              </span>
            </li>
            <li>
              <img loading="lazy" *ngIf="!passwordHaveUpperCase" src="../../../assets/images/error-msg-box.svg" alt="">
              <img loading="lazy" *ngIf="passwordHaveUpperCase" src="../../../assets/images/success-msg-txt.svg" alt="">
              <span [ngClass]="{'green-text':passwordHaveUpperCase}" class="red-text">
                {{'Must contain atleast 1 in Capital Case!'|translate}}
              </span>
            </li>
            <li>
              <img loading="lazy" *ngIf="!passwordHaveLowerCase" src="../../../assets/images/error-msg-box.svg" alt="">
              <img loading="lazy" *ngIf="passwordHaveLowerCase" src="../../../assets/images/success-msg-txt.svg" alt="">
              <span [ngClass]="{'green-text':passwordHaveLowerCase}" class="red-text">
                {{'Must contain atleast 1 Letter in Small Case!'|translate}}
              </span>
            </li>
            <li>
              <img loading="lazy" *ngIf="!passwordHaveSpecialChar" src="../../../assets/images/error-msg-box.svg"
                alt="">
              <img loading="lazy" *ngIf="passwordHaveSpecialChar" src="../../../assets/images/success-msg-txt.svg"
                alt="">
              <span [ngClass]="{'green-text':passwordHaveSpecialChar}" class="red-text">
                {{'Must contain atleast 1 Special Character!'|translate}}
              </span>
            </li>
          </ul>
        </div>
        <div class="password-input-box" *ngIf="PasswordSectionforSms">
          <span>{{'Confirm Password*'|translate}}</span>
          <input formControlName="confirmPassword" required [minLength]="8"
            [type]="showConfirmPasswordForForgotBySms ? 'text' : 'password'" [maxLength]="15" type="text"
            id="confirmPassword" placeholder="{{'Confirm new password'|translate}}">
          <span class="showPass" (click)="handleShowConfirmPasswordForSms()">
            <img loading="lazy"
              [src]="showConfirmPasswordForForgotBySms ? '../../../assets/images/eye-open.svg' : '../../../assets/images/view_password_eye.svg'"
              alt="showpassword" />
          </span>
          <div
            *ngIf="forgotPasswordFormBySms.get('password').value!==forgotPasswordFormBySms.get('confirmPassword').value && forgotPasswordFormBySms.get('confirmPassword').dirty"
            class="error-msg">
            <span>{{'Password does not match'|translate}}</span>
          </div>
        </div>
      </div>
      <div class="forgot-btn-box">
        <button class="back-btn" (click)="backButtonForForgotPassword();"
          *ngIf="!PasswordSectionforSms"><span>{{'Back'|translate}}</span></button>
        <button class="get-otp-btn" *ngIf="!OtpInputSection && !PasswordSectionforSms"
          [disabled]="forgotPasswordFormBySms.get('mobileNo').value?.length < 10" (click)="getOtpBtn()"><span>{{'Get OTP'|translate}}</span></button>


        <button class="submit-btn"
          [disabled]="forgotPasswordFormBySms.get('otp4').invalid"
          (click)="SubmitOtpBtn()" *ngIf="OtpInputSection"><span>{{'Submit'|translate}}</span></button>
        <button class="pwd-submit-btn" (click)="forgoPasswordFormBySms()" [disabled]="forgotPasswordFormBySms.invalid"
          *ngIf="PasswordSectionforSms"><span>{{'Submit'|translate}}</span></button>
      </div>
    </form>
  </div>


  <!--Forgot password whatsup section -->
  <!-- <div class="forgot-password-card">
    <div class="forgot-input">
      <div class="forgot-password-card-header">
        <span>Forgot Password</span>
        <span>Reset Password</span>
      </div>
      <div class="forgot-password-boxs">
        <div class="sms-box">
          <img class="star1" src="../../../assets/images/star.svg" alt="">
          <div class="logo-box" (click)="handleSmsPopup()">
            <img src="../../../assets/images/sms-logo.svg" alt="">
            <span>SMS</span>
            <input [checked]="showSmsPopup" name="sms" class="check-btn" type="radio">
          </div>
        </div>
        <div class="Whatsapp-box" (click)="handleWhatsAppPopUp()">
          <img class="star1" src="../../../assets/images/star.svg" alt="">
          <div class="logo-box">
            <img src="../../../assets/images/whatsup-icon1.svg" alt="">
            <span>Whatsapp</span>
            <input [checked]="showWhtasAppPopUp" name="sms" class="check-btn" type="radio">
          </div>
        </div>
      </div>

      <div class="progress-bar">
        <div class="progress">
          <span class="title timer" data-from="0" data-to="85" data-speed="1800">100%</span>
          <div class="overlay"></div>
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>

      <div class="whatsup-input-btn">
        <button class="whatsup-back-btn">
          <img src="../../../assets/images/left-arrow.svg" alt="">
          <span>Back</span>
        </button>
      </div>

      <div class="mobile-input">
        <span>Mobile Number</span>
        <div class="input-box">
          <span class="drp-btn">+91</span>
          <img src="../../../assets/images/ind-flag-icon.svg" alt="">
          <div class="str-line">
            <img src="../../../assets/images/straight-line1.svg" alt="">
          </div>
          <input [maxLength]="10" type="text" placeholder="Enter your Phone Number">
          <img *ngIf="isMobileNoValid" style="position: absolute; right: 0rem; margin-right: 1.5rem;"
            src="../../../assets/images/right-click-check.svg" alt="">
        </div>
      </div>

      <div class="password-input-box">
        <span>Password*</span>
        <input [minLength]="8" [maxLength]="15" type="text" id="customPassword" placeholder="Enter New password">
        <button><img src="../../../assets/images/view_password_eye.svg" /></button>
        <button><img src="../../../assets/images/eye-open.svg" /></button>
        <div class="pwd-line-box">
          <div class="pwd-strength">
            <div [ngClass]="{'pwd-line':true}" class="pwd-line"></div>
            <div [ngClass]="{'week-line':true}" class="pwd-line"></div>
            <div [ngClass]="{'medium-line':true}" class="pwd-line"></div>
          </div>
        </div>
        </div>
      </div>
  </div> -->


  </div>
  <!--Forgot password congo popup -->
<app-forgot-popup *ngIf="showSuccessPopUp" (closeClick)="showSuccessPopUp=false" class="Modal-Background max-width-550">
    <div class="congo-box">
      <div class="congo">
      <!-- <img class="phatake" src="../../../assets/images/firework.gif" alt=""> -->
      </div>
      <div class="congo-msg">
      <h1>{{'Congratulations!'|translate}}</h1>
        <span style="color: gray;">{{'Password changed successfully!'|translate}}</span>
      </div>
      <button (click)="navigateToSignInPage()"><span>{{"Go to Login"|translate}}</span></button>
    </div>
  <!--Forgot password congo popup -->
  </app-forgot-popup>
