import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { MyprofileService, } from 'src/app/services/myprofile.service';
import { AuthService } from '../../../../services/auth.service';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss'],
})
export class MyprofileComponent implements OnInit {
  tabactive: any;
  tabactiveunderline: any;
  stakesList = [0, 0, 0, 0];
  selectedInputIndex: number | null = null;
  disabledOtherInputs: boolean = false;
  editStakesForm: FormGroup = new FormGroup({});
  expandChangePasswordFlag: boolean = false;
  passwordrules = ["Must be atleast 8 Characters!", "Must contain atleast 1 number!", "Must contain atleast 1 in Capital Case!", "Must contain atleast 1 Letter in Small Case!", "Must contain atleast 1 Spacial Character!"];
  value: boolean = true;
  value1: any;
  ruleCompliance: boolean[] = [false, false, false, false, false];
  newPassword: string = '';
  showCurrentPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  forgotCurrentPassword: boolean = false;
  closeWindowPopup: boolean = false
  languagePopup: boolean = false;
  activeLang: string = 'English';

  langs = [
    { 'name': 'English', 'value': 'en' },
    // { 'name': 'हिन्दी', 'value': 'hi' },
    // { 'name': 'ગુજરાતી', 'value': 'gu' },
    // { 'name': 'मराठी', 'value': 'mr' },
    { 'name': 'Tamil', 'value': 'ta' },
    { 'name': 'Telgu', 'value': 'te' },
    { 'name': 'Kannada', 'value': 'kn' },
    { 'name': 'Malayalam', 'value': 'ml' },
    // { 'name': 'বাংলা', 'value': 'bn' },
    // { 'name': 'ਪੰਜਾਬੀ', 'value': 'pa' }
  ];

  //make an array of activeLanguage equal to the length of lang and set false to all
  activeLanguage = new Array(this.langs.length).fill(false);

  constructor(private profilesServices: MyprofileService, private router: Router, private authService: AuthService, private route: ActivatedRoute, private fb: FormBuilder, private toastr: NotifyServiceService, private translater: TranslateService, private cdr: ChangeDetectorRef) {
    const activeLang = localStorage.getItem('lang');
    this.changeLanguage(activeLang ? activeLang : 'en');
  }

  changePasswordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(''),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(this.newPassword)]),
  });



  ngOnInit(): void {

    this.route.params.subscribe(tab => {
      this.tabactive = tab['id'];
      this.tabactiveunderline = this.tabactive;

    })
    setTimeout(() => {
      if (localStorage.getItem('showBalance') === 'true') {
        this.value = true;
      } else if (localStorage.getItem('showBalance') === 'false') {
        this.value = false;
      } else {
        this.value = false;
      }
    }, 100);
    let betstack = localStorage.getItem('betstack');

    if (betstack) {
      this.stakesList = betstack
        .split(',')
        .map((item) => parseInt(item))
        .slice(0, 4);
      for (let i = 0; i < this.stakesList.length; i++) {
        const controlName = `stake${i + 1}`;
        this.editStakesForm.addControl(
          controlName,
          new FormControl(this.stakesList[i], {
            validators: [
              Validators.required,
              Validators.maxLength(8),
              Validators.minLength(3),
              Validators.pattern(/^[0-9]*$/),
              Validators.min(100),
              Validators.max(10000000)
            ],
            asyncValidators: [], // You can add async validators if needed
          })
        );
        // Set custom error messages
        const control = this.editStakesForm.get(controlName);
        control.setErrors({ 'required': 'This field is required.' });
        control.setErrors({ 'maxlength': 'Maximum length is 8 characters.' });
        control.setErrors({ 'minlength': 'Minimum length is 3 characters.' });
        control.setErrors({ 'pattern': 'Only numeric characters are allowed.' });
        control.setErrors({ 'min': 'Minimum value is 100.' });
        control.setErrors({ 'max': 'Maximum value is 10,000,000.' });
      }
    } else {
      this.stakesList = [0, 0, 0, 0];
    }

  }

  changeLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this.translater.use(lang);
    //select language from langs array
    for (let i = 0; i < this.langs.length; i++) {
      if (this.langs[i].value === lang) {
        this.activeLang = this.langs[i].name;
        this.activeLanguage[i] = true;
        //set other to false
        for (let j = 0; j < this.langs.length; j++) {
          if (j !== i) {
            this.activeLanguage[j] = false;
          }
        }
      }
    }
    return this.activeLang;
  }

  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  navigateBackToAccount() {
    this.router.navigate(['account']);
  }

  onInputFocusForEditStakes(index: number) {
    this.selectedInputIndex = index;
  }

  onInputBlurForEditStakes(index: number) {
    this.selectedInputIndex = null;
  }

  onInputChangeForEditStakes(i: number, event: any) {
    //allow only nos between 0-9 to accept in input
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
      //also set the value in formcontrol
      const controlName = `stake${i + 1}`;
      this.editStakesForm.get(controlName)?.setValue(event.target.value);
      //update the error as wells as the value i have already set
      this.editStakesForm.get(controlName)?.updateValueAndValidity();
    }
    if (this.editStakesForm.invalid) {
      //disable editsatkeform values other than the current input
      for (let j = 0; j < this.stakesList.length; j++) {
        if (j !== i) {
          const controlName = `stake${j + 1}`;
          this.editStakesForm.get(controlName)?.disable();
          this.selectedInputIndex = i;
          this.onInputFocusForEditStakes(i);
        }
      }
    } else {
      for (let j = 0; j < this.stakesList.length; j++) {
        const controlName = `stake${j + 1}`;
        this.editStakesForm.get(controlName)?.enable();
      }
    }
  }
  expandChangePassword() {
    setTimeout(() => {
      this.expandChangePasswordFlag = !this.expandChangePasswordFlag;
      this.changePasswordForm.reset();
      this.ruleCompliance = [false, false, false, false, false];
      this.showConfirmPassword = false;
      this.showCurrentPassword = false;
      this.showNewPassword = false;
    }, 400);
  }


  onSubmitEditStakes() {
    if (this.editStakesForm.valid) {
      let data = { "settings": [this.editStakesForm.value.stake1, this.editStakesForm.value.stake2, this.editStakesForm.value.stake3, this.editStakesForm.value.stake4] };
      if (this.editStakesForm.value.stake1 >= 100 && this.editStakesForm.value.stake2 >= 100 && this.editStakesForm.value.stake3 >= 100 && this.editStakesForm.value.stake4 >= 100) {
        this.authService.editStake(data).subscribe(
          res => {
            if (res.success === true) {
              localStorage.setItem('betstack', this.editStakesForm.value.stake1 + ',' + this.editStakesForm.value.stake2 + ',' + this.editStakesForm.value.stake3 + ',' + this.editStakesForm.value.stake4);
              this.stakesList = [this.editStakesForm.value.stake1, this.editStakesForm.value.stake2, this.editStakesForm.value.stake3, this.editStakesForm.value.stake4];
              this.toastr.success(res.status.statusDesc);
              this.editStakesForm.reset();
              for (let i = 0; i < this.stakesList.length; i++) {
                const controlName = `stake${i + 1}`;
                this.editStakesForm.get(controlName)?.setValue(this.stakesList[i]);
              }
            }
          },
          error => {
            console.log(error);
            this.toastr.error(error.error.error);
          }
        );

      }
    }
    else {
      this.editStakesForm.markAllAsTouched();
    }
  }



  checkForPasswordRules() {
    // Check for password rules
    const password = this.changePasswordForm.value.newPassword;
    this.newPassword = password;

    for (let i = 0; i < this.passwordrules.length; i++) {
      // Check if the password contains the rule's specification
      if (i === 0) {
        // Rule 1: Must contain at least 8 characters
        this.ruleCompliance[i] = password.length >= 8;
      } else if (i === 1) {
        // Rule 2: Must contain at least 1 number
        this.ruleCompliance[i] = /[0-9]/.test(password);
      } else if (i === 2) {
        // Rule 3: Must contain at least 1 uppercase letter
        this.ruleCompliance[i] = /[A-Z]/.test(password);
      } else if (i === 3) {
        // Rule 4: Must contain at least 1 lowercase letter
        this.ruleCompliance[i] = /[a-z]/.test(password);
      } else if (i === 4) {
        // Rule 5: Must contain at least 1 special character
        this.ruleCompliance[i] = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
      }
    }
  }


  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value: string = control.value;

      // Define your password strength criteria here
      //check for uppercase
      const hasUppercase = /[A-Z]/.test(value);
      //check for lowercase
      const hasLowercase = /[a-z]/.test(value);
      //check for number
      const hasNumber = /[0-9]/.test(value);
      //check for special character
      const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);

      const isValid = hasUppercase && hasLowercase && hasNumber && hasSpecialCharacter;

      if (!isValid) {
        return { 'passwordStrength': true };
      }

      return null;
    };
  }


  submitChangePasswordForm() {
    if (this.changePasswordForm.valid) {
      let data = {
        "oldPassword": this.changePasswordForm.value.currentPassword,
        "password": this.changePasswordForm.value.newPassword,
        "passVerify": this.changePasswordForm.value.confirmPassword
      }
      this.profilesServices.changepasswords(data).subscribe((res) => {
        this.toastr.success(res.status.statusDesc);
        if (res.success) {
          this.expandChangePasswordFlag = false;
          this.changePasswordForm.reset();
          this.ruleCompliance = [false, false, false, false, false];
          this.showConfirmPassword = false;
          this.showCurrentPassword = false;
          this.showNewPassword = false;
        }
      }, (err) => {
        console.log(err);
        this.toastr.error(err.error.error.oldPassword[0].description);
      })
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  showPasswordBtn(type: string) {
    if (type === 'CurrentPassword') {
      this.showCurrentPassword = !this.showCurrentPassword;
    } else if (type === 'NewPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (type === 'ConfirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  forgotCurrentPasswordBtn() {
    this.forgotCurrentPassword = true;
  }

  changed() {
    if (this.value === true) {
      localStorage.setItem('showBalance', 'false')
    } else {
      localStorage.setItem('showBalance', 'true')
    }
  }

  logOut() {
    localStorage.removeItem('ROYALTOKEN_KEY');
    localStorage.removeItem('Member_code');
    localStorage.removeItem('token');
    this.router.navigate(['/']);
    localStorage.removeItem('loginName');
    localStorage.removeItem('User_name');
    localStorage.removeItem('betstack');
    localStorage.removeItem('showBalance');
    localStorage.removeItem('WaLink');
  }
  cancel() {
    this.closeWindowPopup = true;
    const timer = setTimeout(() => {
      this.forgotCurrentPassword = false;
      this.closeWindowPopup = false;
    }, 340);
  }

  onInputChangedForEditStake(inputElement: any, maxLength: number) {
    const inputValue = inputElement.value;
    if (inputValue.length > maxLength) {
      inputElement.value = inputValue.slice(0, maxLength);
    }
  }

  preventDecimalInput(event: any) {
    if (event.key === '.') {
      event.preventDefault();
    }
  }

  openLangPopup() {
    this.languagePopup = true;
  }

  closeLangPopup() {
    this.languagePopup = false;
  }

}
