export const environment = {
  production: true,

  // API_URL: 'https://7exch.com/api/public/',
  API_URL: "https://api.betkings.live/api/public/",
  // API_URL: process.env.REACT_APP_BASE_DEV_URL,
  // API_URL: '/api/public/',
  // SENTRY_AUTH_TOKEN=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
  SENTRY_AUTH_TOKEN: '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',
  API_ODDS_URL: 'https://odds8443.ak9.live/app/',
    API_GAME_URL: 'https://api.ak9.live/api/',
   // API_Event_URL: 'https://7exch.com/api/',
  API_Event_URL: "https://api.betkings.live/api/",
 // API_Event_URL: '/api/',
  gamespath:'https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1',
  gLoginKey: '368547399208-82o9eepc89fallv8cuf9m5e0fdb0ch87.apps.googleusercontent.com'
};
