import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BonusService } from '../bonus.service';

@Component({
  selector: 'app-deposit-now-card2',
  templateUrl: './deposit-now-card2.component.html',
  styleUrls: ['./deposit-now-card2.component.scss']
})
export class DepositNowCard2Component implements OnInit {
  @Input() promotion: any;
  loggedUsername: string;
  mailLoginName: string;
  constructor(
    public router: Router,
    private _bonusService: BonusService
  ) { }

  ngOnInit(): void {
    this.loggedUsername = localStorage.getItem('User_name');
    this.mailLoginName = localStorage.getItem('loginName');
  }

  depositNow() {
    if (this.getToken()) {
      //  console.log('Inside if condition openSign of header component');
      this._bonusService.showAllBonuses = false;
      this.router.navigate(['/balancemanagement/Deposit']);
    } else {
      this._bonusService.showAllBonuses = false;
    // document.body.classList.add('openpopup');
      document.getElementById('demo').click();
    }
  }

  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  whatsappAccountRegistration() {
    const link = localStorage.getItem('WaLink') || 'https://walink.live/betkings';
    window.open(link, '_blank');
  }

}
