<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img loading="lazy" class="loader1" src="../../../assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>


    </div>
</div>
<!-- <app-whatsapp></app-whatsapp> -->

<div *ngIf="showFooterForHome" class="layout-footer-holder-bc">
    <div class="footer-card-1">
        <div class="layout-card">
            <img loading="lazy" class="gcgaming" src="../../../assets/betkings.svg" alt="footer">
            <div class="footer-header">
                <div (click)="showDropdown('about')">
                    <span>{{'ABOUT' | translate}}</span>
        </div>
                <div (click)="showDropdown('regulations')">
                    <span>{{'REGULATIONS' | translate}}</span>
        </div>
                <div (click)="showDropdown('help')">
                    <span>{{'HELP' | translate}}</span>
            </div>
                <div class="header-logo">
                    <img loading="lazy" src="../../../assets/images/footer-logo.svg" alt="footer">
        </div>
        </div>
            </div>
        <!-- <div class="hr-line"></div> -->
        <ng-container>
            <div *ngIf="showAbout">
                <div class="ftr-m-nav-l-item-bc active">
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="aboutshow1()">
                        <span class="ellipsis">{{'About us' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showresponsible()">
                        <span class="ellipsis">{{'Responsible Gaming' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="blog()">
                        <span class="ellipsis">{{'Blog' | translate}}</span>
                    </a>
        </div>
        </div>
            <div *ngIf="showRegulation">
                <div class="ftr-m-nav-l-item-bc">
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showfaq()">
                        <span class="ellipsis">{{'FAQ' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showcontact()">
                        <span class="ellipsis">{{'Contact us' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showsportbetting()"><span
                            class="ellipsis">{{'Sport Betting Rules' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showcasino()">
                        <span class="ellipsis">{{'Casino' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showfooterpage()">
                        <span class="ellipsis">{{'Poker' | translate}}</span>
                    </a>
    </div>
</div>
            <div *ngIf="showHelp">
                <div class="ftr-m-nav-l-item-bc">
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showgeneral()">
                        <span class="ellipsis">{{'General Terms and Conditions' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showsportbook()">
                        <span class="ellipsis">{{'Sportsbook Terms and Conditions' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="affiliate()">
                        <span class="ellipsis">{{'Exclusion Policy' | translate}}</span>
                    </a>
                    <a target="_self" class="ftr-m-nav-link-bc ellipsis" (click)="showprivacy()">
                        <span class="ellipsis">{{'Privacy Policy' | translate}}</span>
                    </a>
            </div>
                                </div>
        </ng-container>
        <div class="hr-line"></div>
        <!-- <div class="media"><span>{{'Social Networks' | translate}}</span></div> -->
        <!-- <div class="social-media">
            <a class="telegram" href="https://t.me/+P2O-n01NM6s2YWVi" target="_blank">
                <img src="../../../assets/images/telegram-logo.svg" alt="telegram">
            </a>
            <a class="instagram" href="https://www.instagram.com/11exch_india/" target="_blank">
                <img src="../../../assets/images/instram-logo.svg" alt="telegram">
            </a>
            <a class="facebook" href="https://www.facebook.com/11Exch.news" target="_blank">
                <img src="../../../assets/images/facebook-logo.svg" alt="telegram">
            </a>
            <a class="xtra" href="https://twitter.com/11Exch_news" target="_blank">
                <img src="../../../assets/images/xtra-logo.svg" alt="telegram">
            </a>
                                </div> -->
        <div class="hr-line"></div>
        <div clas="footer-card">
            <div class="footer-img">
                <img style="width:5.93rem;" src="../../../assets/images/footer-bottom-img.png" alt="footer">
                                </div>
            <div class="text">
                <span>
                    {{'Betkings is operated by Radon B.V.(Commercial register of Curacao no.126922 Emancipatie Boulevard 29, Curacao) under a sublicense CIL pursuant to Master gaming License №5536/JAZ.' |translate }}
                </span>
                                </div>
                                </div>
        <div class="hr-line"></div>
        <div class="hdr-navigation-scrollable-bc" data-scroll-lock-scrollable="">
            <div class="ftr-payments-row">
                <div class="footer-logo">
                    <img loading="lazy" id="12008_S2PBankTransfer" src="../../../assets/images/bank-footer.svg"
                        alt="S2PBankTransfer">
                  <span>{{'BANK' | translate}} <br>{{'TRANSFER' | translate}}</span>
                                    </div>

                <img loading="lazy" id="58_AstroPayDirect" src="../../../assets/images/imps-footer.svg" alt="IMPS">

                <img loading="lazy" id="9755_FXMBNetbanking" src="../../../assets/images/upi-footer.svg" alt="UPI">

                <img loading="lazy" id="4343_RupeePay" src="../../../assets/images/netbanking-footer.svg"
                    alt="Net Banking">

                <img loading="lazy" id="9228_PaykassmaPaytm" src="../../../assets/images/paytm-footer.svg" alt="PayTM">

                <img loading="lazy" style="width:4rem; height:1.5rem;" id="9234_PaykassmaPhonePe"
                    src="assets/images/footer/20.webp" alt="PhonePe">

                <img loading="lazy" id="7822_Paydaddys" class="banking-img" src="assets/images/footer/21.webp"
                    alt="Paydaddys">

                <img loading="lazy" id="10577_PaykassmaIMPS" class="banking-img" src="assets/images/footer/22.webp"
                    alt="PaykassmaIMPS">

                <img loading="lazy" id="6975_AstroPayOneTouchUPI" class="banking-img" src="assets/images/footer/23.webp"
                    alt="AstroPay UPI">

                <img loading="lazy" id="6977_AstroPayOneTouchNetBanking" class="banking-img"
                    src="assets/images/footer/24.webp" alt="Astropay Net Banking">

                <img loading="lazy" id="6974_AstroPayOneTouchPhonePe" class="banking-img"
                    src="assets/images/footer/25.webp" alt="AstroPay PhonePe">

                <img loading="lazy" id="6976_AstroPayOneTouchGoogle" class="banking-img"
                    src="assets/images/footer/26.webp" alt="AstroPay GPay">

                <img loading="lazy" id="6139_AstroPayOneTouch" class="banking-img" src="assets/images/footer/27.webp"
                    alt="AstroPay
                     ">
                <img loading="lazy" id="1_skrill" class="banking-img" src="assets/images/footer/31.webp" alt="Skrill">

                <img loading="lazy" id="4701_NetellerNew" class="banking-img" src="assets/images/footer/32.png"
                    alt="Neteller">

                <img loading="lazy" id="1783_SticPay" class="banking-img" src="assets/images/footer/33.png"
                    alt="STICPAY">

                <img loading="lazy" id="957_MuchBetter" class="banking-img" src="assets/images/footer/34.png"
                    alt="MuchBetter">

                <img loading="lazy" id="7148_CryptoPayV2" class="banking-img" src="assets/images/footer/35.png"
                    alt="CryptoPayV2">

                <img loading="lazy" id="1223_JetonV3" class="banking-img" src="assets/images/footer/36.png"
                    alt=" JetonV3">

                <img loading="lazy" id="11693_FulgurPayTRX" class="banking-img" src="assets/images/footer/37.png"
                    alt="TRX">

                <img loading="lazy" id="12260_Pay4X" class="banking-img" src="assets/images/footer/38.png" alt="Pay4X">

                <img loading="lazy" id="10457_CoindirectBTC" class="banking-img" src="assets/images/footer/39.png"
                    alt="BitCoin">

                <img loading="lazy" id="10876_FulgurPayBTC" class="banking-img" src="assets/images/footer/40.png"
                    alt="BTC">

                <img loading="lazy" id="10966_FulgurPayUSDT_T" class="banking-img" src="assets/images/footer/41.png"
                    alt="USDT TRC20">

                <img loading="lazy" id="10466_CoindirectBCH" class="banking-img" src="assets/images/footer/42.png"
                    alt="BitcoinCash">

                <img loading="lazy" id="10463_CoindirectUSDT(TRC20)" class="banking-img"
                    src="assets/images/footer/43.png" alt="USDT (TRC20 / ERC20)">

                                </div>
                                </div>
        <div class="hr-line"></div>
        <div class="ftr-copy-rights-bc">{{'© 2003 - 2023 Betkings India' | translate}}</div>
                                </div>
                                </div>
<div class="whatsapp">
    <app-whatsapp *ngIf="!menushow"></app-whatsapp>
</div>

<div class="Modal-background max-width-550" *ngIf="menushow">
    <app-menu (closeClick)="menushow=false; activeMenu=false"></app-menu>
                                    </div>



<!-- new Gooter for Mobile -->
 <div class="pc-center">
    <div class="mobile-view-footer" *ngIf="myProfile.showFooter">
    <div class="left">
        <div class="casino" (click)="gotoCasinoSection()">
                    <div class="line" *ngIf="activeCasino"></div>
            <img class="casino-img" [ngClass]="{'casino-img-1':activeCasino}"
                src="../../../assets/images/footer-menu-casino.svg" alt="CASINO">
            <span class="opt-title-inactive" [ngClass]="{'opt-title':activeCasino}">{{'CASINO' | translate}}</span>
                                </div>
        <div class="casino" (click)="gotoSprtsSection()">
                    <div class="line" *ngIf="activeSport"></div>
            <img class="casino-img" [ngClass]="{'casino-img-1':activeSport}"
                src="../../../assets/images/footer-menu-sports.svg" alt="SPORTS">
            <span class="opt-title-inactive" [ngClass]="{'opt-title':activeSport}">{{'SPORTS' | translate}}</span>
                                </div>
                    </div>
    <div class="center">
        <div class="home-icon" (click)="gotoHomeSection()"
            [ngClass]="{'home-icon-active':activeHome}">
            <img src="../../../assets/images/home-icon-footer-menu.svg" alt="HOME">
        </div>
    </div>
    <div class="right">
        <div class="casino" (click)="gotoLiveBets()">
            <div class="count-bet" *ngIf="myProfile.totalBetCount>0">
                <span class="count">{{myProfile.totalBetCount}}</span>
            </div>
                    <div class="line" *ngIf="activeLiveBets"></div>
            <img class="casino-img" [ngClass]="{'casino-img-1':activeLiveBets}"
                src="../../../assets/images/footer-live-tv-menu.svg" alt="LIVE BET">
            <span class="opt-title-inactive" [ngClass]="{'opt-title':activeLiveBets}">{{'OPEN BETS' | translate}}</span>
                </div>
        <div class="casino" (click)="footermenubarshow()">
                    <div class="line" *ngIf="activeMenu"></div>
            <img class="casino-img" [ngClass]="{'casino-img-1':activeMenu}" src="../../../assets/images/footer-menu.svg"
                        alt="MENU">
            <span class="opt-title-inactive" [ngClass]="{'opt-title':activeMenu}">{{'MENU' | translate}}</span>
            </div>
        </div>
    </div>
</div>


<!-- about us -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true}" class="Modal-Background max-width-550"
    *ngIf="aboutshowhide1">
    <div class="popup-middleware-bc">
        <div class="popup-inner-bc"><i (click)="this.aboutshowhide1 = false" class="e-p-close-icon-bc bc-i-close-remove"></i>
            <div #aboutUs></div>
        </div>
    </div>
</div>
<!-- about us -->

<!-- Exclusion policy -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true}" class="Modal-Background max-width-550"
    *ngIf="!showhideaboutshow">
    <div class="popup-middleware-bc">
        <div class="popup-inner-bc"><i (click)="this.showhideaboutshow = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
            <div #selfExclusion></div>
        </div>
    </div>
</div>
<!-- Exclusion policy -->

<!-- blogs -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhideblogs">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhideblogs = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #blogs></div>
    </div>
  </div>
</div>
<!-- blogs -->


<!-- Responsible Gaming -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhideresponsible">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhideresponsible = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #responsibleGaming></div>
    </div>
  </div>
</div>
<!-- Responsible Gaming -->

<!-- FAQ -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhidefaq">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhidefaq = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #faq></div>
    </div>
  </div>
</div>
<!-- FAQ -->

<!-- contact us -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhidecontact">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhidecontact = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #contactUs></div>
    </div>
  </div>
</div>
<!-- contact us -->

<!-- Sport Betting Rules -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhidesportbetting">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhidesportbetting = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #sportsBettingRules></div>
    </div>
  </div>
</div>
<!-- Sport Betting Rules -->

<!-- Casino -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhidecasino">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhidecasino = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #casino></div>
    </div>
  </div>
</div>
<!-- Casino -->

<!-- poker -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhidefooterpage">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhidefooterpage = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #poker></div>
    </div>
  </div>
</div>
<!-- poker -->

<!-- general terms and condition -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
     *ngIf="!showhidegeneral">
  <div class="popup-middleware-bc">
    <div class="popup-inner-bc"><i (click)="showhidegeneral = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
      <div #generalTnC></div>
        </div>
    </div>
</div>
<!-- general terms and condition -->

<!-- Sportsbook Terms and Conditions -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
    *ngIf="!showhidesportbook">
    <div class="popup-middleware-bc">
        <div class="popup-inner-bc"><i (click)="showhidesportbook = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
          <div #sportsbookTnC></div>
        </div>
    </div>
</div>
<!-- Sportsbook Terms and Conditions -->

<!-- Privacy Policy -->
<div [ngClass]="{'popup-holder-bc':true, 'windowed':true, 'content-manager':true} " class="Modal-Background max-width-550"
    *ngIf="!showhideprivacy">
    <div class="popup-middleware-bc">
        <div class="popup-inner-bc"><i (click)="showhideprivacy = true" class="e-p-close-icon-bc bc-i-close-remove"></i>
          <div #privacy></div>
        </div>
    </div>
</div>
<!-- Privacy Policy -->
