<div class="pc-center">
<re-captcha *ngIf="rSiteKey" #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"
  [siteKey]="rSiteKey"></re-captcha>
<div class="max-width-550">
<app-notify></app-notify>
<router-outlet></router-outlet>
</div>
<!--<app-download-apk></app-download-apk>-->
<ng-container *ngIf="landingBanner?.length > 0">
  <app-update-popup [landingBanner]="landingBanner"></app-update-popup>
</ng-container>
<!--<app-whatsapp></app-whatsapp>-->

</div>