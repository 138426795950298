<div class="pc-center">
<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img loading="lazy" class="loader1" src="../../../assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>


    </div>
</div>
<app-profile-header></app-profile-header>
<div class="deposit-report-head max-width-550">
    <svg (click)="backToPrev(openDepositDetails || openWithdrawDetails);" xmlns="http://www.w3.org/2000/svg" width="20"
        height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M13.1213 17.0761L6.25 10.2047L13.1213 3.33337L14.0833 4.31254L8.19115 10.2047L14.0833 16.0969L13.1213 17.0761Z"
            fill="#003c79" />
    </svg>
    <span class="deposit-withdraw-head-title" *ngIf="!openDepositDetails && !openWithdrawDetails">{{'Deposit & Withdraw Report'|translate}}</span>
    <span class="deposit-withdraw-head-title" *ngIf="openDepositDetails">{{'Deposit Details'|translate}}</span>
    <span class="deposit-withdraw-head-title" *ngIf="openWithdrawDetails">{{'Withdraw Details'|translate}}</span>

</div>
<div class="deposit-withdraw-btns max-width-550" *ngIf="!openDepositDetails && !openWithdrawDetails">
    <div class="btns-animation">
        <div class="btnBox">
            <button class="btn-inactive" (click)="showDepositReport()"><span>{{'Deposit'|translate}}</span></button>
            <button class="btn-inactive" (click)="showWithdrawReport()"><span>{{'Withdraw'|translate}}</span></button>
        </div>
        <div *ngIf="showWithdrawList" [ngClass]="{'animation-div ':showWithdrawList}">
        </div>
        <div *ngIf="showDepositList" [ngClass]="{'animation-div-1 ':showDepositList}">
        </div>
    </div>
</div>

<ng-container *ngIf="depositList?.length>0 && showDepositList">
    <div class="main-content max-width-550" *ngIf="!openDepositDetails">
        <ng-container *ngFor="let group of groupDepositItems">
            <span class="date-separator"> {{dateFormate1(group.date)}}</span>
            <ng-container *ngFor="let item of group.items;trackBy: trackByFn">
                <div class="card-deposit" (click)="openDepositDetailsPage(item)">
                    <div class="logo">
                        <img *ngIf="item?.gateway?.logo" src={{item?.gateway?.logo}} alt="logo">
                        <img *ngIf="!item?.gateway?.logo" src="../../../../../assets/images/withdraw-bank-logo.svg"
                           class="bank-logo"  alt="logo">
                    </div>
                    <div class="card-content">
                        <div class="top-content">
                            <div class="left-top-text">
                                <span>{{'Deposit Via'|translate}} <span *ngIf="item?.gateway?.name">{{item?.gateway?.name}}</span>
                                <span *ngIf="!item?.gateway?.name">{{item?.gateway}}</span> <span *ngIf="!item?.gateway">{{'Bank'|translate}}</span></span>
                                <span class="status"
                                    [ngClass]="{'status-pending':item.status=='Pending','status-cancelled':item.status==='Cancelled','status-aproved':item.status=='Approved'||item.status==='Complete' }">
                                    <img *ngIf="item.status==='Cancelled'"
                                        src="../../../../../assets/images/cancel-deposit-icon.svg" alt="">
                                    <svg *ngIf="item.status==='Completed' || item.status=='Approved' || item.status=='Complete'"
                                        xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"
                                        fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10ZM0.78125 5C0.78125 7.32995 2.67005 9.21875 5 9.21875C7.32995 9.21875 9.21875 7.32995 9.21875 5C9.21875 2.67005 7.32995 0.78125 5 0.78125C2.67005 0.78125 0.78125 2.67005 0.78125 5ZM4.58968 6.58651C4.43713 6.73906 4.1898 6.73906 4.03725 6.58651L2.78973 5.33901C2.63718 5.18647 2.63718 4.93914 2.78973 4.78659C2.94228 4.63404 3.1896 4.63403 3.34215 4.78658L4.31346 5.75787L6.65784 3.4135C6.81038 3.26095 7.05771 3.26095 7.21026 3.4135C7.36281 3.56605 7.36281 3.81338 7.21026 3.96592L4.58968 6.58651Z"
                                            fill="#17C964" />
                                    </svg>
                                    <svg *ngIf="item.status==='Pending'" width="10" height="10" viewBox="0 0 10 10"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_585_6594)">
                                            <path
                                                d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                                fill="#F5A524" />
                                            <path
                                                d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                                fill="#F5A524" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_585_6594">
                                                <rect width="10" height="10" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <svg *ngIf="item.status==='Initiated' ||item.status==='Exported'" width="10"
                                        height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_585_6594)">
                                            <path
                                                d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                                fill="#00B2FF" />
                                            <path
                                                d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                                fill="#00B2FF" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_585_6594">
                                                <rect width="10" height="10" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <span class="status-text"
                                        [ngClass]="{'status-text-pending':item.status==='Pending', 'status-text-cancelled':item.status=='Cancelled','status-text-approved':item.status==='Approved' || item.status==='Completed' || item.status==='Complete'}"
                                        *ngIf="item.status!=='Complete'">{{item?.status}}</span>
                                    <span class="status-text"
                                        [ngClass]="{'status-text-pending':item.status==='Pending', 'status-text-cancelled':item.status=='Cancelled','status-text-approved':item.status==='Approved' || item.status==='Completed' || item.status==='Complete'}"
                                        *ngIf="item.status==='Complete'">{{'Completed'|translate}}</span>
                                </span>
                            </div>
                            <span class="right-top-amount"
                                [ngClass]="{'right-top-amount-pending ':item.status==='Pending', 'right-top-amount-cancelled':item.status=='Cancelled','right-top-amount-approved ':item.status==='Approved' || item.status==='Completed' || item.status=='Complete'}">
                                ₹ {{item?.amount}}
                            </span>
                        </div>
                        <div class="bottom-content">
                            <span class="left-bottom-id">
                                #{{item.requestId}}
                            </span>
                            <span class="right-bottom-date">
                                {{dateFormate(item.lastUpdateTime || item.createdAt)}}
                            </span>
                        </div>

                    </div>
                </div>
            </ng-container>
        </ng-container>

    </div>
</ng-container>

<ng-container *ngIf="withdrawList?.length>0 && showWithdrawList">
    <div class="main-content" *ngIf="!openWithdrawDetails">
        <ng-container *ngFor="let group of groupWithdrawItems">
            <span class="date-separator"> {{dateFormate1(group.date)}}</span>
            <ng-container *ngFor="let item of group.items;trackBy: trackByFn">
                <div class="card-deposit" (click)="openWithDrawDetailsPage(item)">
                    <div class="logo">
                        <img src="../../../../../assets/images/withdraw-bank-logo.svg"
                            style="width: 50%; aspect-ratio: 1;" alt="logo">
                    </div>
                    <div class="card-content">
                        <div class="top-content">
                            <div class="left-top-text">
                                <span>{{'Withdraw Via Bank'|translate}}</span>
                                <span class="status"
                                    [ngClass]="{'status-pending':item.status=='Pending','status-cancelled':item.status==='Cancelled','status-aproved':item.status=='Approved'|| item.status==='Completed' || item.status=='Complete' }">
                                    <img *ngIf="item.status==='Cancelled'"
                                        src="../../../../../assets/images/cancel-deposit-icon.svg" alt="">
                                    <svg *ngIf="item.status==='Completed' || item.status=='Approved' || item.status=='Complete'"
                                        xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"
                                        fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10ZM0.78125 5C0.78125 7.32995 2.67005 9.21875 5 9.21875C7.32995 9.21875 9.21875 7.32995 9.21875 5C9.21875 2.67005 7.32995 0.78125 5 0.78125C2.67005 0.78125 0.78125 2.67005 0.78125 5ZM4.58968 6.58651C4.43713 6.73906 4.1898 6.73906 4.03725 6.58651L2.78973 5.33901C2.63718 5.18647 2.63718 4.93914 2.78973 4.78659C2.94228 4.63404 3.1896 4.63403 3.34215 4.78658L4.31346 5.75787L6.65784 3.4135C6.81038 3.26095 7.05771 3.26095 7.21026 3.4135C7.36281 3.56605 7.36281 3.81338 7.21026 3.96592L4.58968 6.58651Z"
                                            fill="#17C964" />
                                    </svg>
                                    <svg *ngIf="item.status==='Pending'" width="10" height="10" viewBox="0 0 10 10"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_585_6594)">
                                            <path
                                                d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                                fill="#F5A524" />
                                            <path
                                                d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                                fill="#F5A524" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_585_6594">
                                                <rect width="10" height="10" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <svg *ngIf="item.status==='Initiated'|| item.status==='Exported'" width="10"
                                        height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_585_6594)">
                                            <path
                                                d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                                fill="#00B2FF" />
                                            <path
                                                d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                                fill="#00B2FF" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_585_6594">
                                                <rect width="10" height="10" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <span class="status-text"
                                        [ngClass]="{'status-text-pending':item.status==='Pending', 'status-text-cancelled':item.status=='Cancelled','status-text-approved':item.status==='Approved' || item.status==='Completed'}"
                                        *ngIf="item.status !=='Complete'">{{item?.status}}</span>
                                    <span class="status-text"
                                        [ngClass]="{'status-text-pending':item.status==='Pending', 'status-text-cancelled':item.status=='Cancelled','status-text-approved':item.status==='Approved' || item.status==='Completed' ||item.status==='Complete'}"
                                        *ngIf="item.status ==='Complete'">{{'Completed'|translate}}</span>
                                </span>
                            </div>
                            <span class="right-top-amount"
                                [ngClass]="{'right-top-amount-pending ':item.status==='Pending', 'right-top-amount-cancelled':item.status=='Cancelled','right-top-amount-approved ':item.status==='Approved' || item.status==='Completed' || item.status ==='Complete'}">
                                ₹ {{item?.amount}}
                            </span>
                        </div>
                        <div class="bottom-content">
                            <span class="left-bottom-id">
                                #{{item.requestId}}
                            </span>
                            <span class="right-bottom-date">
                                {{dateFormate(item.lastUpdateTime || item.createdAt)}}
                            </span>
                        </div>

                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<div class="deposit-not-available" *ngIf="withdrawList?.length===0 && showWithdrawList && !showDepositList">
    <div class="card-no-deposits">
        <img class="no-deposits" src="../../../../../assets/images/no-deposits.svg" alt="no-deposits" loading="lazy">
        <span class="no-deposit-text">
            {{"You don't have any Withdraw Report."|translate}}
        </span>
        <div class="bet-now-btn" (click)="gotoWithdraw()">
            <span>{{'Withdraw Now'|translate}}</span>
        </div>
    </div>
</div>
<div class="deposit-not-available" *ngIf="depositList?.length===0 && !showWithdrawList">
    <div class="card-no-deposits">
        <img class="no-deposits" src="../../../../../assets/images/no-deposits.svg" alt="no-deposits" loading="lazy">
        <span class="no-deposit-text">
            {{"You don't have any Deposit Report."|translate}}
        </span>
        <div class="bet-now-btn" (click)="gotoDeposit()">
            <span>{{'Deposit Now'|translate}}</span>
        </div>
    </div>
</div>
<app-deposit-details *ngIf="openDepositDetails" (closeClick)="openDepositDetails=false"
    [detailsWithdrawDeposit]="depositDetails"></app-deposit-details>
<app-deposit-details *ngIf="openWithdrawDetails" (closeClick)="openWithdrawDetails=false"
    [detailsWithdrawDeposit]="withDrawDetails"></app-deposit-details>
</div>