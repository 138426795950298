import { AfterViewInit, Component, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  animAppOpen = false
  animAppClose = false
  intercomBtn = false;
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.animAppOpen = true
      setTimeout(() => {
        this.animAppClose = true
        this.animAppOpen = false

      }, 20000);
    }, 6500)
    this.setupIntercomInterval();
  }

  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  openWhatsApp() {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const waLink = localStorage.getItem('ROYALTOKEN_KEY') ? userData?.wa : 'https://walink.live/betkings'
    window.open(waLink);
  }

  clickOpenIntercom() {
    const intercomContainer = document.getElementById("intercom-container");
    // @ts-ignore
    window.Intercom('show');
    if (intercomContainer) {
      this.renderer.setStyle(intercomContainer, 'display', 'block', RendererStyleFlags2.Important);
    }
    this.intercomBtn = true;
  }

  clickCloseIntercom() {
    const intercomContainer = document.getElementById("intercom-container");
    // @ts-ignore
    window.Intercom('hide');
    if (intercomContainer) {
      this.renderer.setStyle(intercomContainer, 'display', 'none', RendererStyleFlags2.Important);
    }
    this.intercomBtn = false;
  }

  private setupIntercomInterval() {
    const interval = setInterval(() => {
      const intercomContainer = document.getElementById("intercom-container");
      const intercomContainerClass = document.getElementsByClassName("intercom-lightweight-app")[0];
      if (window.location.href.includes('intercom')) {
        // @ts-ignore

        window.Intercom('show')
        // @ts-ignore

        console.log(' window.Intercom', window.Intercom)
        clearInterval(interval);
      } else if (intercomContainer || intercomContainerClass) {
        clearInterval(interval);
        this.intercomRender();
      }
    }, 1000);
  }
  intercomRender() {
    this.clickOpenIntercom()
    this.clickCloseIntercom()
  }
}
