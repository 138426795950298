<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image" /> -->
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

  </div>
</div>

<div class="p-1 body-profile-page">
  <div class="top-user-name-logoutaction">
    <div class="user-name-icon-profile">
      <span class="user-icon-account-profile">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M8.00035 0C3.58234 0 0 3.58164 0 8C0 12.4184 3.58199 16 8.00035 16C12.4191 16 16.0007 12.4184 16.0007 8C16.0007 3.58164 12.4191 0 8.00035 0ZM8.00035 2.39209C9.46224 2.39209 10.6469 3.57707 10.6469 5.03826C10.6469 6.4998 9.46224 7.68443 8.00035 7.68443C6.53916 7.68443 5.35454 6.4998 5.35454 5.03826C5.35454 3.57707 6.53916 2.39209 8.00035 2.39209ZM7.99859 13.9084C6.54057 13.9084 5.20518 13.3774 4.17518 12.4985C3.92427 12.2845 3.77949 11.9707 3.77949 11.6414C3.77949 10.1595 4.97887 8.97342 6.46115 8.97342H9.54026C11.0229 8.97342 12.2177 10.1595 12.2177 11.6414C12.2177 11.971 12.0736 12.2841 11.8224 12.4981C10.7927 13.3774 9.45697 13.9084 7.99859 13.9084Z"
            fill="url(#paint0_linear_47_4625)" />
          <defs>
            <linearGradient id="paint0_linear_47_4625" x1="-1.92458e-07" y1="1.30909" x2="17.6702" y2="3.70926"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#36E2FA" />
              <stop offset="1" stop-color="#EBFFBF" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span class="user-name-text skeleton-text">{{ userName?.replace('b06.', '') }}</span>
    </div>
    <!-- <div  class="logoutactiono-profile-page active">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9.00016 0.666626C9.46041 0.666626 9.8335 1.03973 9.8335 1.49996V8.99996C9.8335 9.46021 9.46041 9.83329 9.00016 9.83329C8.53991 9.83329 8.16683 9.46021 8.16683 8.99996V1.49996C8.16683 1.03973 8.53991 0.666626 9.00016 0.666626ZM4.2819 3.11062C4.6074 3.436 4.60748 3.96364 4.2821 4.28913C3.34996 5.22157 2.71525 6.40946 2.45819 7.70263C2.20113 8.99579 2.33327 10.3361 2.83792 11.5541C3.34257 12.7722 4.19705 13.8132 5.29334 14.5457C6.38961 15.2781 7.6785 15.669 8.99691 15.669C10.3153 15.669 11.6042 15.2781 12.7005 14.5457C13.7967 13.8132 14.6512 12.7722 15.1559 11.5541C15.6606 10.3361 15.7927 8.99579 15.5357 7.70263C15.2786 6.40946 14.6438 5.22157 13.7117 4.28913C13.3863 3.96364 13.3864 3.436 13.7119 3.11062C14.0374 2.78523 14.5651 2.78533 14.8904 3.11081C16.0556 4.27636 16.849 5.76122 17.1703 7.37763C17.4917 8.99413 17.3265 10.6695 16.6957 12.192C16.0648 13.7146 14.9967 15.016 13.6264 15.9315C12.256 16.847 10.645 17.3357 8.99691 17.3357C7.34883 17.3357 5.7378 16.847 4.36745 15.9315C2.99709 15.016 1.92899 13.7146 1.29818 12.192C0.667362 10.6695 0.502179 8.99413 0.823504 7.37763C1.14482 5.76122 1.93823 4.27636 3.1034 3.11081C3.42878 2.78533 3.95641 2.78523 4.2819 3.11062Z"
          fill="#617293" />
      </svg>

    </div> -->
  </div>

  <!-- <div class="loyalty-card-section">
    <div class="loyalty-card">
      <div class="loyalty-body">
        <div class="loyalty-first">
          <img src="../../../../assets/images/loylty-logo-white.svg" alt="loyalty">
        </div>
        <div class="loyalty-text-box">
          <span>Beginner <small> Next Level: Roller</small></span><div class="loyalty-card-section">
            <div class="loyalty-card">
              <div class="loyalty-body">
                <div class="loyalty-first">
                  <img src="../../../../assets/images/loylty-logo-white.svg" alt="loyalty">
                </div>
                <div class="loyalty-text-box">
                  <span>Beginner <small> Next Level: Roller</small></span>
                  <div class="turnover-box">
                    <span>₹130/1000 Turnover</span>
                  </div>
                </div>
              </div>
              <svg (click)="openPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M9.49375 13.75H10.6187V9.25H9.49375V13.75ZM9.99966 7.8625C10.1749 7.8625 10.3219 7.805 10.4406 7.69C10.5594 7.575 10.6187 7.4325 10.6187 7.2625C10.6187 7.08187 10.5595 6.93047 10.441 6.80828C10.3224 6.68609 10.1755 6.625 10.0003 6.625C9.82511 6.625 9.67813 6.68609 9.55938 6.80828C9.44063 6.93047 9.38125 7.08187 9.38125 7.2625C9.38125 7.4325 9.44051 7.575 9.55904 7.69C9.67758 7.805 9.82445 7.8625 9.99966 7.8625ZM10.005 17.5C8.97081 17.5 7.99894 17.3031 7.08936 16.9094C6.17979 16.5156 5.38438 15.9781 4.70312 15.2969C4.02188 14.6156 3.48438 13.8197 3.09063 12.9092C2.69687 11.9987 2.5 11.0259 2.5 9.99063C2.5 8.95539 2.69687 7.98252 3.09063 7.07202C3.48438 6.16151 4.02188 5.36875 4.70312 4.69375C5.38438 4.01875 6.18026 3.48438 7.09077 3.09063C8.00127 2.69687 8.97414 2.5 10.0094 2.5C11.0446 2.5 12.0175 2.69687 12.928 3.09063C13.8385 3.48438 14.6313 4.01875 15.3063 4.69375C15.9813 5.36875 16.5156 6.1625 16.9094 7.075C17.3031 7.9875 17.5 8.96084 17.5 9.99501C17.5 11.0292 17.3031 12.0011 16.9094 12.9106C16.5156 13.8202 15.9813 14.6145 15.3063 15.2934C14.6313 15.9724 13.8375 16.5099 12.925 16.9059C12.0125 17.302 11.0392 17.5 10.005 17.5ZM10.0094 16.375C11.7781 16.375 13.2812 15.7531 14.5187 14.5094C15.7562 13.2656 16.375 11.7594 16.375 9.99063C16.375 8.22188 15.7574 6.71875 14.5223 5.48125C13.2871 4.24375 11.7797 3.625 10 3.625C8.2375 3.625 6.73438 4.24258 5.49062 5.47773C4.24687 6.71289 3.625 8.22031 3.625 10C3.625 11.7625 4.24687 13.2656 5.49062 14.5094C6.73438 15.7531 8.24062 16.375 10.0094 16.375Z"
                  fill="white" />
              </svg>
            </div>

            <div class="loyalty-card">
              <div class="loyalty-body">
                <div class="loyalty-first">
                  <img src="../../../../assets/images/loylty-logo-white.svg" alt="loyalty">
                </div>
                <div class="loyalty-text-box">
                  <span>Beginner <small> Next Level: Roller</small></span>
                  <div class="turnover-box">
                    <span>₹130/1000 Turnover</span>
                  </div>
                </div>
              </div>
              <svg (click)="openPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M9.49375 13.75H10.6187V9.25H9.49375V13.75ZM9.99966 7.8625C10.1749 7.8625 10.3219 7.805 10.4406 7.69C10.5594 7.575 10.6187 7.4325 10.6187 7.2625C10.6187 7.08187 10.5595 6.93047 10.441 6.80828C10.3224 6.68609 10.1755 6.625 10.0003 6.625C9.82511 6.625 9.67813 6.68609 9.55938 6.80828C9.44063 6.93047 9.38125 7.08187 9.38125 7.2625C9.38125 7.4325 9.44051 7.575 9.55904 7.69C9.67758 7.805 9.82445 7.8625 9.99966 7.8625ZM10.005 17.5C8.97081 17.5 7.99894 17.3031 7.08936 16.9094C6.17979 16.5156 5.38438 15.9781 4.70312 15.2969C4.02188 14.6156 3.48438 13.8197 3.09063 12.9092C2.69687 11.9987 2.5 11.0259 2.5 9.99063C2.5 8.95539 2.69687 7.98252 3.09063 7.07202C3.48438 6.16151 4.02188 5.36875 4.70312 4.69375C5.38438 4.01875 6.18026 3.48438 7.09077 3.09063C8.00127 2.69687 8.97414 2.5 10.0094 2.5C11.0446 2.5 12.0175 2.69687 12.928 3.09063C13.8385 3.48438 14.6313 4.01875 15.3063 4.69375C15.9813 5.36875 16.5156 6.1625 16.9094 7.075C17.3031 7.9875 17.5 8.96084 17.5 9.99501C17.5 11.0292 17.3031 12.0011 16.9094 12.9106C16.5156 13.8202 15.9813 14.6145 15.3063 15.2934C14.6313 15.9724 13.8375 16.5099 12.925 16.9059C12.0125 17.302 11.0392 17.5 10.005 17.5ZM10.0094 16.375C11.7781 16.375 13.2812 15.7531 14.5187 14.5094C15.7562 13.2656 16.375 11.7594 16.375 9.99063C16.375 8.22188 15.7574 6.71875 14.5223 5.48125C13.2871 4.24375 11.7797 3.625 10 3.625C8.2375 3.625 6.73438 4.24258 5.49062 5.47773C4.24687 6.71289 3.625 8.22031 3.625 10C3.625 11.7625 4.24687 13.2656 5.49062 14.5094C6.73438 15.7531 8.24062 16.375 10.0094 16.375Z"
                  fill="white" />
              </svg>
            </div>
          </div>

          <app-loyalty-popup *ngIf="loyaltyPopup" (closeClick)="loyaltyPopup = false"></app-loyalty-popup>

          <div class="turnover-box">
            <span>₹130/1000 Turnover</span>
          </div>
        </div>
      </div>
      <svg (click)="openPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
        fill="none">
        <path
          d="M9.49375 13.75H10.6187V9.25H9.49375V13.75ZM9.99966 7.8625C10.1749 7.8625 10.3219 7.805 10.4406 7.69C10.5594 7.575 10.6187 7.4325 10.6187 7.2625C10.6187 7.08187 10.5595 6.93047 10.441 6.80828C10.3224 6.68609 10.1755 6.625 10.0003 6.625C9.82511 6.625 9.67813 6.68609 9.55938 6.80828C9.44063 6.93047 9.38125 7.08187 9.38125 7.2625C9.38125 7.4325 9.44051 7.575 9.55904 7.69C9.67758 7.805 9.82445 7.8625 9.99966 7.8625ZM10.005 17.5C8.97081 17.5 7.99894 17.3031 7.08936 16.9094C6.17979 16.5156 5.38438 15.9781 4.70312 15.2969C4.02188 14.6156 3.48438 13.8197 3.09063 12.9092C2.69687 11.9987 2.5 11.0259 2.5 9.99063C2.5 8.95539 2.69687 7.98252 3.09063 7.07202C3.48438 6.16151 4.02188 5.36875 4.70312 4.69375C5.38438 4.01875 6.18026 3.48438 7.09077 3.09063C8.00127 2.69687 8.97414 2.5 10.0094 2.5C11.0446 2.5 12.0175 2.69687 12.928 3.09063C13.8385 3.48438 14.6313 4.01875 15.3063 4.69375C15.9813 5.36875 16.5156 6.1625 16.9094 7.075C17.3031 7.9875 17.5 8.96084 17.5 9.99501C17.5 11.0292 17.3031 12.0011 16.9094 12.9106C16.5156 13.8202 15.9813 14.6145 15.3063 15.2934C14.6313 15.9724 13.8375 16.5099 12.925 16.9059C12.0125 17.302 11.0392 17.5 10.005 17.5ZM10.0094 16.375C11.7781 16.375 13.2812 15.7531 14.5187 14.5094C15.7562 13.2656 16.375 11.7594 16.375 9.99063C16.375 8.22188 15.7574 6.71875 14.5223 5.48125C13.2871 4.24375 11.7797 3.625 10 3.625C8.2375 3.625 6.73438 4.24258 5.49062 5.47773C4.24687 6.71289 3.625 8.22031 3.625 10C3.625 11.7625 4.24687 13.2656 5.49062 14.5094C6.73438 15.7531 8.24062 16.375 10.0094 16.375Z"
          fill="white" />
      </svg>
    </div>

    <div class="loyalty-card">
      <div class="loyalty-body">
        <div class="loyalty-first">
          <img src="../../../../assets/images/loylty-logo-white.svg" alt="loyalty">
        </div>
        <div class="loyalty-text-box">
          <span>Beginner <small> Next Level: Roller</small></span>
          <div class="turnover-box">
            <span>₹130/1000 Turnover</span>
          </div>
        </div>
      </div>
      <svg (click)="openPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M9.49375 13.75H10.6187V9.25H9.49375V13.75ZM9.99966 7.8625C10.1749 7.8625 10.3219 7.805 10.4406 7.69C10.5594 7.575 10.6187 7.4325 10.6187 7.2625C10.6187 7.08187 10.5595 6.93047 10.441 6.80828C10.3224 6.68609 10.1755 6.625 10.0003 6.625C9.82511 6.625 9.67813 6.68609 9.55938 6.80828C9.44063 6.93047 9.38125 7.08187 9.38125 7.2625C9.38125 7.4325 9.44051 7.575 9.55904 7.69C9.67758 7.805 9.82445 7.8625 9.99966 7.8625ZM10.005 17.5C8.97081 17.5 7.99894 17.3031 7.08936 16.9094C6.17979 16.5156 5.38438 15.9781 4.70312 15.2969C4.02188 14.6156 3.48438 13.8197 3.09063 12.9092C2.69687 11.9987 2.5 11.0259 2.5 9.99063C2.5 8.95539 2.69687 7.98252 3.09063 7.07202C3.48438 6.16151 4.02188 5.36875 4.70312 4.69375C5.38438 4.01875 6.18026 3.48438 7.09077 3.09063C8.00127 2.69687 8.97414 2.5 10.0094 2.5C11.0446 2.5 12.0175 2.69687 12.928 3.09063C13.8385 3.48438 14.6313 4.01875 15.3063 4.69375C15.9813 5.36875 16.5156 6.1625 16.9094 7.075C17.3031 7.9875 17.5 8.96084 17.5 9.99501C17.5 11.0292 17.3031 12.0011 16.9094 12.9106C16.5156 13.8202 15.9813 14.6145 15.3063 15.2934C14.6313 15.9724 13.8375 16.5099 12.925 16.9059C12.0125 17.302 11.0392 17.5 10.005 17.5ZM10.0094 16.375C11.7781 16.375 13.2812 15.7531 14.5187 14.5094C15.7562 13.2656 16.375 11.7594 16.375 9.99063C16.375 8.22188 15.7574 6.71875 14.5223 5.48125C13.2871 4.24375 11.7797 3.625 10 3.625C8.2375 3.625 6.73438 4.24258 5.49062 5.47773C4.24687 6.71289 3.625 8.22031 3.625 10C3.625 11.7625 4.24687 13.2656 5.49062 14.5094C6.73438 15.7531 8.24062 16.375 10.0094 16.375Z"
          fill="white" />
      </svg>
    </div>
  </div>

  <app-loyalty-popup *ngIf="loyaltyPopup" (closeClick)="loyaltyPopup = false"></app-loyalty-popup> -->

  <div class="Modal-Background Modal-Background-1 max-width-550" *ngIf="removePopup"
    [ngClass]="{'closePopupWindow':animateCloseWindow}">
    <div class="rectLogout1"></div>
    <div class="rectLogout2"></div>
    <div class="title-logout">
      <span class="log-out-text">{{'Logout'|translate}}</span>
      <span (click)="closePopupWindow()" class="closeBtn-logout">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.91703 10.2587C2.68924 10.4866 2.68928 10.8559 2.9171 11.0837C3.14493 11.3115 3.51427 11.3115 3.74206 11.0836L7.00012 7.82499L10.2584 11.0833C10.4862 11.3111 10.8556 11.3111 11.0834 11.0833C11.3112 10.8555 11.3112 10.4862 11.0834 10.2584L7.82501 6.99998L11.0832 3.74126C11.3109 3.51343 11.3109 3.14409 11.083 2.91631C10.8552 2.68851 10.4859 2.68855 10.2581 2.91638L7 6.17503L3.74166 2.91666C3.51386 2.68885 3.14451 2.68885 2.91671 2.91666C2.6889 3.14446 2.6889 3.51381 2.91671 3.74162L6.17517 7.00004L2.91703 10.2587Z"
            fill="#111827" />
        </svg>
      </span>
    </div>
    <div class="hr-line"></div>
    <span class="logout-content">
      {{'Are you sure you want to logout from this account?'|translate}}
    </span>
    <div class="logout-action-btns active">
      <button class="log-out-action-cancel" (click)="closePopupWindow()">
        <span>{{'Cancel'|translate}}</span>
      </button>
      <button class="log-out-action-confirm" (click)="logOut()">
        <span>{{'Yes, Logout'|translate}}</span>
      </button>
    </div>
  </div>
  <!-- <div class="flexbox-profile-page-1"> -->

  <div class="profile-menu-box">
    <div class="card-profile-page">
      <div class="card-profile-page-upper-div">
        <div class="card-profile-page-upper-div-left">
          <span class="card-profile-page-upper-div-left-balance">
            <ng-container *ngIf="showBalance===true">
              ₹{{ balnceamout?.availBalance | indianCurrency }}
            </ng-container>
            <ng-container *ngIf="balnceamout?.availBalance >= 0 && showBalance===false">
              ₹{{formatAmountWithXs( balnceamout?.availBalance | indianCurrency )}}
            </ng-container>
          </span>
          <div class="d-flex">
            <span class="card-profile-page-upper-div-left-main-wallet">
              {{'Main Wallet'|translate}}
            </span>
            <button (click)="gotToComponent('showBonuses')" class="redeem-text">
              Redeem
            </button>
          </div>
        </div>
        <div class="card-profile-page-upper-div-right">
          <img loading="lazy" src="../../../../assets/images/profile-card-wallet.png"
            style="width: 3.5rem; height: 3.01rem" alt="" />
        </div>
      </div>
      <div class="card-profile-image-lower-div">
        <div class="card-profile-image-lower-div-left">
          <span class="card-profile-image-lower-div-left-text">
            <span class="text-left-profile">{{'Free Money'|translate}}</span>
            <span class="info-profile-left" (click)="openInfo()">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                <g clip-path="url(#clip0_47_4592)">
                  <path
                    d="M7.4375 7.41467C7.4375 7.17306 7.24162 6.97717 7 6.97717C6.75838 6.97717 6.5625 7.17306 6.5625 7.41467V10.9147C6.5625 11.1563 6.75838 11.3522 7 11.3522C7.24162 11.3522 7.4375 11.1563 7.4375 10.9147V7.41467Z"
                    fill="#D1D5DB" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.99984 1.72717C3.53655 1.72717 0.729004 4.53472 0.729004 7.99801C0.729004 11.4613 3.53655 14.2688 6.99984 14.2688C10.4631 14.2688 13.2707 11.4613 13.2707 7.99801C13.2707 4.53472 10.4631 1.72717 6.99984 1.72717ZM1.604 7.99801C1.604 5.01797 4.0198 2.60217 6.99984 2.60217C9.97985 2.60217 12.3957 5.01797 12.3957 7.99801C12.3957 10.978 9.97985 13.3938 6.99984 13.3938C4.0198 13.3938 1.604 10.978 1.604 7.99801Z"
                    fill="#D1D5DB" />
                  <path
                    d="M7.58317 5.66463C7.58317 5.9868 7.32201 6.24797 6.99984 6.24797C6.67766 6.24797 6.4165 5.9868 6.4165 5.66463C6.4165 5.34247 6.67766 5.0813 6.99984 5.0813C7.32201 5.0813 7.58317 5.34247 7.58317 5.66463Z"
                    fill="#D1D5DB" />
                </g>
                <defs>
                  <clipPath id="clip0_47_4592">
                    <rect width="14" height="14" fill="white" transform="translate(0 0.998047)" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </span>
          <span class="card-profile-image-lower-div-left-amount">₹ {{ balnceamout?.bonusAmount |indianCurrency }}</span>
        </div>
        <div class="card-profile-image-lower-div-right">
          <span class="card-profile-image-lower-div-left-text">{{'Exposure'|translate}}</span>
          <span class="card-profile-image-lower-div-left-amount">₹ {{ balnceamout?.deductedExposure | indianCurrency
            }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="profile-menu-box">
    <div class="button-container-profile-page">
      <div class="button-container-profile-page-1 active" (click)="goToWithDraw()">
        <span class="button-container-profile-page-1-icon">
          <img src="../../../../assets/images/withdrawIcon.svg" alt="Withdraw">
        </span>
        <span class="button-container-profile-page-1-text">{{'Withdraw'|translate}}</span>
      </div>
      <div class="button-container-profile-page-2 active" (click)="goToDeposit()">
        <span class="button-container-profile-page-2-icon">
          <img src="../../../../assets/images/depositProfileIcon.svg" alt="Deposit">
        </span>
        <span class="button-container-profile-page-2-text">{{'Deposit'|translate}}</span>
      </div>
    </div>
  </div>
  <div class="profile-menu-box">
    <ul class="profile-menu-container active">
      <ng-container *ngFor="let item of menuListProfile;let i=index">
        <li class="profile-menu-items">
          <div class="routingProfile" (click)="gotToComponent(item.link)">
            <div class="profile-menu-items-left">
              <span class="profile-menu-icon">
                <img style="height:24px" loading="lazy" [src]="item.img" alt="" (load)="imageLoaded=true" />
                <div class="skeleton-img" *ngIf="imageLoaded">
                  <div class="moving-strip"></div>
                </div>
              </span>
              <span class="profile-menu-left-text">{{ item.name|translate }}</span>
            </div>
            <div class="profile-menu-items-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M6.87866 2.92393L13.75 9.79528L6.87865 16.6666L5.91667 15.6875L11.8088 9.79528L5.91667 3.9031L6.87866 2.92393Z"
                  fill="#617293" />
              </svg>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="profile-menu-box">
    <div class="download-btn-card">
      <!-- <div class="download-card">
        <div class="download-body" (click)="goIosAPKInstruction()">
          <div class="first-card">
            <img src="../../../../assets/images/iphone-logo.svg" alt="iphone">
            <div class="text">
              <span>{{'Application'|translate}}</span>
              <small>{{'for iOS'|translate}}</small>
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10" fill="none">
            <path
              d="M4.83312 4.99997C4.83356 5.07674 4.81885 5.15285 4.78982 5.22392C4.76079 5.29499 4.71801 5.35963 4.66395 5.41414L1.16395 8.91414C1.05411 9.02398 0.905125 9.08569 0.749783 9.08569C0.59444 9.08569 0.44546 9.02398 0.335616 8.91414C0.225772 8.8043 0.164062 8.65532 0.164062 8.49997C0.164062 8.34463 0.225772 8.19565 0.335616 8.08581L3.42728 4.99997L0.341449 1.91414C0.245884 1.80255 0.195947 1.659 0.201617 1.51219C0.207288 1.36538 0.268149 1.22612 0.372037 1.12223C0.475926 1.01834 0.61519 0.95748 0.762001 0.95181C0.908812 0.946139 1.05236 0.996076 1.16395 1.09164L4.66395 4.59164C4.77172 4.70029 4.83247 4.84694 4.83312 4.99997Z"
              fill="white" />
          </svg>
        </div>
        <div class="download-body" (click)="goAndriodToAPK()">
          <div class="first-card">
            <img src="../../../../assets/images/android-logo.svg" alt="andriod">
            <div class="text">
              <span>{{'Application'|translate}}</span>
              <small>{{'for Android'|translate}}</small>
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10" fill="none">
            <path
              d="M4.83312 4.99997C4.83356 5.07674 4.81885 5.15285 4.78982 5.22392C4.76079 5.29499 4.71801 5.35963 4.66395 5.41414L1.16395 8.91414C1.05411 9.02398 0.905125 9.08569 0.749783 9.08569C0.59444 9.08569 0.44546 9.02398 0.335616 8.91414C0.225772 8.8043 0.164062 8.65532 0.164062 8.49997C0.164062 8.34463 0.225772 8.19565 0.335616 8.08581L3.42728 4.99997L0.341449 1.91414C0.245884 1.80255 0.195947 1.659 0.201617 1.51219C0.207288 1.36538 0.268149 1.22612 0.372037 1.12223C0.475926 1.01834 0.61519 0.95748 0.762001 0.95181C0.908812 0.946139 1.05236 0.996076 1.16395 1.09164L4.66395 4.59164C4.77172 4.70029 4.83247 4.84694 4.83312 4.99997Z"
              fill="white" />
          </svg>
        </div>
      </div> -->
      <button class="logout-btn" (click)="logOutPopupWindow()">
        <span>{{'Log out'|translate}}</span>
      </button>
    </div>
  </div>
</div>

<app-ios-instruction *ngIf="showIosInstruction" (closeClick)="showIosInstruction = false"></app-ios-instruction>

<!-- <div class="Modal-Background-3 max-width-550" *ngIf="howToDownloadAPK">
  <div class="video-player">
    <div class="close-img" (click)="closeVideoPopup()">
      <div class="skip-btn">
        <span>{{'Skip Video'|translate}}</span>
      </div>
    </div>
    <iframe class="video" src="https://player.vimeo.com/video/876696274?h=0c3a5f3af0&autoplay=1" width="640"
      height="1138" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
    </iframe>
    <p>
      <a href="https://vimeo.com/876696274">How to download 11exch APP</a>
      from <a href="https://vimeo.com/user208700594">Exch</a>
      on <a href="https://vimeo.com">Vimeo</a>.
    </p>
  </div>
</div> -->

<div class="Modal-Background parent-backdrop-profile max-width-550" *ngIf="removePopup || infoPage || loyaltyPopup">
</div>

<div class="Modal-Background info-page-profile max-width-550" *ngIf="infoPage">
  <div class="background-design-info-profile">
    <span class="rectangle"></span>
    <span class="rectangle1"></span>
    <span class="rectangle2"></span>
    <span class="rectangle3"></span>
    <span class="rectangle4"></span>
    <span class="rectangle5"></span>
    <span class="icon1">
      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="33" viewBox="0 0 44 33" fill="none">
        <g opacity="0.3">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M43.5824 24.6022C45.8247 20.7481 38.8128 12.555 27.9213 6.31166C17.0347 0.0633075 6.38703 -1.87139 4.14473 1.98272C2.90399 4.1194 1.66344 6.26112 0.417636 8.3978C-1.82484 12.2519 5.18721 20.445 16.0788 26.6883C26.9704 32.9367 37.613 34.8714 39.8553 31.0173C41.101 28.8808 42.3415 26.7389 43.5824 24.6022Z"
            fill="#FE9923" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M27.921 6.31166C17.0344 0.0633075 6.38673 -1.87139 4.14443 1.98272C1.9072 5.84187 8.91907 14.03 19.8056 20.2733C30.6972 26.5216 41.3449 28.4563 43.5821 24.6022C45.8246 20.7481 38.8125 12.555 27.921 6.31166Z"
            fill="#FECD3D" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26.0245 9.57503C17.365 4.60456 9.37169 2.24072 8.17676 4.29647C6.98184 6.34735 13.0378 12.0452 21.7023 17.0104C30.3668 21.9809 38.3601 24.3447 39.555 22.289C40.7449 20.2383 34.689 14.5455 26.0245 9.57503Z"
            fill="#FEA832" />
          <path
            d="M4.95792 12.8118L2.97488 16.2263C2.50198 15.6504 2.07995 15.0848 1.71387 14.5341L3.27488 11.8418L4.95792 12.8118ZM6.65617 20.0047C7.13414 20.429 7.63746 20.8585 8.15616 21.2827L10.0476 18.0297L8.36455 17.065L6.65617 20.0047ZM12.2698 24.3236C12.7986 24.6772 13.3427 25.0307 13.902 25.3793L15.707 22.2728L14.0189 21.3131L12.2698 24.3236ZM18.3054 27.9099C18.8902 28.2129 19.47 28.5059 20.0497 28.7838L21.7988 25.7732L20.1107 24.8034L18.3054 27.9099ZM24.753 30.7992C25.3836 31.0366 26.0039 31.2539 26.6191 31.4509L28.3276 28.5111L26.6395 27.5514L24.753 30.7992ZM31.7496 32.7338C32.4921 32.85 33.1937 32.9307 33.8598 32.9712L35.4208 30.284L33.7378 29.3142L31.7496 32.7338Z"
            fill="#C85929" />
        </g>
      </svg>
    </span>
    <span class="icon2">
      <svg xmlns="http://www.w3.org/2000/svg" width="49" height="43" viewBox="0 0 49 43" fill="none">
        <g opacity="0.6">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.71427 34.5255C4.60643 31.8042 8.2735 22.5375 15.9022 13.8219C23.528 5.11211 32.2312 0.250004 35.3389 2.97133C37.0588 4.47826 38.7786 5.98524 40.5045 7.49508C43.6109 10.2119 39.9438 19.4786 32.3151 28.1943C24.6849 36.9055 15.9861 41.7661 12.8799 39.0492C11.1586 37.5378 9.43423 36.0324 7.71427 34.5255Z"
            fill="#FE9923" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.9022 13.8219C23.528 5.11211 32.2312 0.250004 35.3389 2.97133C38.4452 5.68818 34.7782 14.9549 27.1495 23.6705C19.5207 32.3862 10.8206 37.2423 7.71427 34.5255C4.60643 31.8042 8.2735 22.5375 15.9022 13.8219Z"
            fill="#FECD3D" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.5299 16.1228C24.6003 9.19394 30.8583 4.74886 32.5127 6.19393C34.1686 7.64358 30.5896 14.4367 24.5206 21.3699C18.4517 28.3032 12.1893 32.7499 10.5378 31.2988C8.88214 29.8492 12.4611 23.0561 18.5299 16.1228Z"
            fill="#FEA832" />
          <path
            d="M37.8652 12.7172L40.614 15.1253C40.8542 14.4795 41.0576 13.8564 41.2107 13.26L39.0427 11.3665L37.8652 12.7172ZM38.5332 19.5337C38.2433 20.0479 37.9326 20.574 37.6073 21.1004L34.9849 18.8025L36.1655 17.4608L38.5332 19.5337ZM34.928 24.9853C34.574 25.457 34.2054 25.9288 33.8263 26.3991L31.3247 24.2086L32.5053 22.8669L34.928 24.9853ZM30.7374 29.9238C30.3196 30.3677 29.9004 30.7924 29.4839 31.2112L27.0551 29.0898L28.2371 27.7377L30.7374 29.9238ZM25.9855 34.3758C25.5024 34.7727 25.0264 35.1475 24.5533 35.5014L22.1812 33.4301L23.3647 32.0824L25.9855 34.3758ZM20.4578 38.1469C19.8513 38.4721 19.2591 38.7529 18.6905 38.9859L16.5268 37.0909L17.7088 35.7389L20.4578 38.1469Z"
            fill="#C85929" />
        </g>
      </svg>
    </span>
    <span class="icon3">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path opacity="0.18"
          d="M7.37409 0V3.94356H11.3174V7.36718H7.37409V11.3174H3.95047V7.36718H0V3.94356H3.95047V0H7.37409Z"
          fill="#FEA832" />
      </svg>
    </span>
    <span class="icon4">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path opacity="0.18"
          d="M7.37409 0V3.94356H11.3174V7.36718H7.37409V11.3174H3.95047V7.36718H0V3.94356H3.95047V0H7.37409Z"
          fill="#FEA832" />
      </svg>
    </span>
    <span class="icon5">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
        <g opacity="0.3">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.8102 11.1828C20.8294 9.43095 17.6422 5.7068 12.6915 2.86893C7.74306 0.0287762 2.9032 -0.850633 1.88397 0.901237C1.32 1.87245 0.756108 2.84596 0.189834 3.81718C-0.829474 5.56905 2.35782 9.2932 7.30854 12.1311C12.2593 14.9712 17.0968 15.8506 18.1161 14.0988C18.6823 13.1276 19.2461 12.154 19.8102 11.1828Z"
            fill="#FE9923" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.6914 2.86893C7.74297 0.0287761 2.90311 -0.850633 1.88388 0.901237C0.866952 2.6554 4.05417 6.37725 9.00258 9.21512C13.9533 12.0553 18.7932 12.9347 19.8101 11.1828C20.8294 9.43095 17.6421 5.7068 12.6914 2.86893Z"
            fill="#FECD3D" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.8294 4.35176C7.89329 2.09245 4.25995 1.01798 3.7168 1.95241C3.17365 2.88463 5.92635 5.47454 9.86475 7.73148C13.8032 9.99079 17.4365 11.0653 17.9796 10.1308C18.5205 9.19869 15.7678 6.61106 11.8294 4.35176Z"
            fill="#FEA832" />
          <path
            d="M2.25338 5.8237L1.352 7.37577C1.13704 7.114 0.94521 6.85689 0.778809 6.60656L1.48836 5.38281L2.25338 5.8237ZM3.02531 9.09322C3.24257 9.28608 3.47135 9.4813 3.70712 9.67415L4.56687 8.1955L3.80185 7.75698L3.02531 9.09322ZM5.57696 11.0563C5.81734 11.2171 6.06464 11.3778 6.31885 11.5362L7.13933 10.1242L6.37201 9.68797L5.57696 11.0563ZM8.32043 12.6865C8.58625 12.8242 8.84976 12.9574 9.11327 13.0837L9.90832 11.7153L9.141 11.2745L8.32043 12.6865ZM11.2511 13.9998C11.5378 14.1077 11.8197 14.2065 12.0994 14.296L12.876 12.9598L12.1087 12.5235L11.2511 13.9998ZM14.4314 14.8792C14.7689 14.932 15.0878 14.9687 15.3906 14.9871L16.1001 13.7656L15.3351 13.3248L14.4314 14.8792Z"
            fill="#C85929" />
        </g>
      </svg>
    </span>
    <span class="icon6">
      <svg xmlns="http://www.w3.org/2000/svg" width="58" height="138" viewBox="0 0 58 138" fill="none">
        <g clip-path="url(#clip0_2660_31639)" filter="url(#filter0_i_2660_31639)">
          <mask id="mask0_2660_31639" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="-79" y="0" width="137"
            height="138">
            <path d="M8.91417 0.913367L-78.2544 49.9199L-29.2478 137.088L57.9207 88.0819L8.91417 0.913367Z"
              fill="white" />
          </mask>
          <g mask="url(#mask0_2660_31639)">
            <path
              d="M17.0654 76.6315C71.5392 91.8988 -33.064 150.707 -17.7993 96.2325C-33.064 150.707 -91.8722 46.1043 -37.401 61.3666C-91.8722 46.1043 12.731 -12.704 -2.53635 41.7656C12.731 -12.704 71.5392 91.8988 17.0654 76.6315Z"
              fill="url(#paint0_linear_2660_31639)" fill-opacity="0.1" />
          </g>
        </g>
        <defs>
          <filter id="filter0_i_2660_31639" x="-78.2544" y="0.914062" width="140.175" height="140.174"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2660_31639" />
          </filter>
          <linearGradient id="paint0_linear_2660_31639" x1="-34.6701" y1="25.4167" x2="14.3364" y2="112.585"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#DF99F7" />
            <stop offset="1" stop-color="#FFDBB0" />
          </linearGradient>
          <clipPath id="clip0_2660_31639">
            <rect width="100" height="100" fill="white" transform="translate(-78.2544 49.9199) rotate(-29.3449)" />
          </clipPath>
        </defs>
      </svg>
    </span>
    <span class="icon7">
      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="73" viewBox="0 0 43 73" fill="none">
        <path opacity="0.2"
          d="M77.0866 35.7062C93.51 60.9096 62.1308 72.5265 42.2828 72.5265C22.7626 72.5265 0.698364 65.1772 0.698364 42.4921C0.708153 -15.8618 48.8923 -7.54231 77.0866 35.7062Z"
          fill="url(#paint0_linear_2660_31630)" />
        <defs>
          <linearGradient id="paint0_linear_2660_31630" x1="41.1898" y1="0.996094" x2="41.1898" y2="72.5265"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFC461" />
            <stop offset="1" stop-color="#FF9639" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span class="icon8">
      <svg xmlns="http://www.w3.org/2000/svg" width="74" height="96" viewBox="0 0 74 96" fill="none">
        <path opacity="0.2"
          d="M83.8899 78.7487C90.1716 78.1491 96.8289 80.6009 103.056 81.9881C108.87 83.29 120.316 83.4151 125.918 80.8571C135.606 76.4397 141.339 60.6827 132.935 51.5987C123.289 41.1714 106.266 40.4912 92.7007 37.085C71.7606 31.823 73.5545 14.7334 55.2476 4.41764C48.2382 0.470745 36.7881 -1.47309 30.1903 4.62957C21.8171 12.3571 27.8164 10.2839 15.1761 26.9359C11.2559 29.3083 -3.04186 39.9246 1.62623 54.61C3.6303 60.9147 11.9671 64.7472 17.6146 68.7155C23.0473 72.5276 28.9222 74.646 33.1047 79.8297C38.6881 86.7605 44.2069 95.9723 54.1077 95.1451C66.8565 94.0688 72.228 79.8722 83.8899 78.7487Z"
          fill="#FF8AA9" />
      </svg>
    </span>
    <span class="free-money-profile-bets">
      <span class="free-money">{{'Free Money'|translate}}</span>
      <span (click)="closeInfoPage()">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="22" height="22" rx="10" fill="#9CA3AF" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.91728 14.2593C6.68949 14.4872 6.68952 14.8565 6.91735 15.0843C7.14517 15.3121 7.51452 15.3121 7.7423 15.0842L11.0004 11.8256L14.2587 15.0839C14.4865 15.3117 14.8558 15.3117 15.0836 15.0839C15.3114 14.8561 15.3114 14.4868 15.0836 14.259L11.8253 11.0006L15.0834 7.74187C15.3111 7.51404 15.3111 7.1447 15.0833 6.91692C14.8555 6.68912 14.4861 6.68916 14.2583 6.91699L11.0002 10.1756L7.74191 6.91727C7.5141 6.68946 7.14475 6.68946 6.91695 6.91727C6.68914 7.14507 6.68914 7.51442 6.91695 7.74223L10.1754 11.0007L6.91728 14.2593Z"
            fill="#111827" />
        </svg>
      </span>
    </span>
    <span class="exctra-profile">
      <p class="first-line"><span>{{firstTwoWordofShortInfo}}</span> {{remainingWordsofShortInfo}}</p>
      <p class="second-line">{{bonusData[1]["short-info-2"]}}</p>
    </span>
    <span class="min-deposits">
      <span>{{'Min Deposit'|translate}}</span>
      <span class="amount">₹ {{bonusData[1]["minDeposit"].toLocaleString()}}</span>
    </span>
    <div class="home-page">
      <span class="center-home-icon">
        <img loading="lazy" src="../../../../assets/images/center-icon-progile-info.webp"
          style="width: 9.375rem; height: 9.375rem" alt="" />
      </span>
      <span class="text-start-info">
        <span>{{bonusData[1]["bonusPercentage"]}}%</span> {{'of your deposit immediately goes to your main wallet as
        free money'|translate}}
      </span>
      <span class="text-info-2">
        {{'Claim'|translate}} <span>{{bonusData[1]["bonusPercentage"]}}% {{'free'|translate}}</span> {{"money on every
        Deposite up to ₹"|translate}}
        {{bonusData[1]["perMarketCap"].toLocaleString()}}
      </span>
      <span class="text-info-3"> {{'No Wagering required'|translate}} </span>
      <button (click)="goToDeposit()">
        <span>{{'Get the bonus'|translate}}</span>
      </button>
      <span class="how-to-claim"> {{'How to claim the bonus?'|translate}} </span>
      <span class="answer-how-to-claim">
        {{'Make a desposite and get'|translate}} <span>{{bonusData[1]["bonusPercentage"]}}% {{'free'|translate}}</span>
        {{'money immediatly in your'|translate}}
        <span>{{'main wallet'|translate}}</span>
      </span>
    </div>
  </div>
  <div style="position: relative; top:45rem; left:0; right:0; overflow-y: hidden;" [innerHTML]="htmlContent"></div>
</div>