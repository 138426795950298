import {animate, style, transition, trigger} from '@angular/animations';
import {Clipboard} from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {ClipboardService} from 'ngx-clipboard';
import { NumericFieldValidator } from 'src/app/authentication/validations/validations.validator';
import {NotifyServiceService} from 'src/app/notify/notify-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { BonusService } from 'src/app/shared/bonuses-cards/bonus.service';
import upiqr from 'upiqr';
import { bankLogoLinks } from './bankLogoLinks';
// import {PixelService} from "ngx-multi-pixel";
import {GtmService} from "ngx-gtm";
import { GatewayResult, PaymentGateways } from 'src/app/shared/models/payment-gateways.interface.js';
import { Promotion, PromotionResult } from 'src/app/shared/models/promotion.interface.js';
declare var esk: any;
declare var window: any;
declare global {
  interface window {
    fbq?: (eventType: string, eventName: string) => void,
    fbq1?: (eventType: string, eventName: string) => void,
    fbq2?: (eventType: string, eventName: string) => void,
    fbq3?: (eventType: string, eventName: string) => void,
    fbq4?: (eventType: string, eventName: string) => void,
    fbq5?: (eventType: string, eventName: string) => void,
  }
}


@Component({
  selector: 'app-balancemanagement',
  templateUrl: './balancemanagement.component.html',
  styleUrls: ['./balancemanagement.component.scss'],
  animations: [trigger(
      'inOutPaneAnimation',
      [
        transition(
            ':enter',
            [
              style({
                opacity: 0,
                transform: 'translateX(100%)'
              }),  // apply default styles before animation starts
        animate(
                  '400ms ease-in-out',
                  style({opacity: 1, transform: 'translateX(0)'}))
      ]),
        transition(
            ':leave',
            [
              style({
                opacity: 1,
                transform: 'translateX(0)'
              }),  // apply default styles before animation starts
        animate(
                  '250ms ease-in-out',
                  style({opacity: 0, transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class BalancemanagementComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  showSkeleton: boolean = true;
  loggerUsername: string = '';
  bankLogoLinks = bankLogoLinks;
  // FormGroup start
  addmonys: FormGroup;
  depositaddmonys: FormGroup;
  canceldeposit: FormGroup;
  depositedit: FormGroup;


  depositdetailsshowonpageamount: any;
  depositdetailsshowonpagecancel: any;

  // new deposite
  depositeamount: boolean = false
  // paymentmethod: boolean = true;
  utrnopage: boolean = true;
  paymentmethods: boolean = true;
  bankPaymentMethod: boolean = true;
  selectedUpiMethod = '';
  bankTransfer: boolean = true;
  showReceiptArea: string = 'IMPS';
  percentageBonus: number = 0;
  upiPaymentTimer: any;
  minutes: number = 45;
  seconds: number = 0;

  //stepper value till 4
  stepperValue: number = 1;
  showSvg = true;
  showImg = false;
  imagePath: string = '';
  showPopUp: boolean = true;
  offerApplied: boolean = false;

  // popup
  showBonusListPopup = false;
  removePopup = false;
  showCongoPopup = false;
  withdrawPopup = false;
  bankLimitPopup = false;
  withdrawCongoPopup = false;
  isDisabled: boolean = true;
  showUpiCongoPopup = false;
  userUpiDetailPopup = false;

  //infoPopup
  showHowTo: any = false;
  showHowTo2: any;

  shortInfo: any
  firstTwoWordofShortInfo: any
  remainingWordsofShortInfo: any
  htmlContent: any

  upiGateway: GatewayResult[] = [];
  nonUpiGateway: GatewayResult[] = [];
  paymentGateways:GatewayResult[]= [];
  nonUpiGateway1: GatewayResult[] = [];
  selectedPromo: PromotionResult;
  generatedQrCode: string = '';
  isWelcomeOffer: boolean = false;
  bonus: number = 0;
  welcomeBonus: number = 0;
  isBankPay: boolean = true;

  //withdrawl
  withdrawFirstPage = true;
  withdrawSecondPage = false;
  withdrawThirdPage = false;
  bankDetailsAvailable: boolean = false;
  addAccountPage: boolean = false;
  showAccountNumberinput: string = 'password';
  showConfirmAccountNumberinput: string = 'password';
  bonusMoney: number = 0;
  balnceamout: any;
  enteredBankdetails: any;
  balanceWithoutBonus: any;
  welcomeOfferCode: 'WELCOME500';
  activeBonusOfUser: number = 0; // active bonus of user ie welcome500
  invalidIfsc: boolean = true;

  selectedWithdrawBank: {
    accountId: number
    bankName: string
    branchName: string
    IFSCCode: string
    accountNo: string
    accountName: string
  };
  progressBarPercentage: number = 3; // 3% progress bar
  deleteBankId: number;
  // newdeposite
  public depositshowes: any;
  public istabactive: any;

  //active bonus
  activeBonus: any;
  activeBonusPercent = 3;
  totalWagered = 0
  wageredCap = 0;
  remainWagered = 0;
  EXPIRY_DATE = new Date();
  remainDays = 0;
  remainHours = 0;

  showhideaccountfilter: any = false;
  hidedepositmenthod: any = false;
  accountStatements: any = [];
  accountStatementss: any = [];
  accountdetailsshow: any = true;
  // minimummoney: any = false;
  isLoader: boolean = false;
  availableBalance: any;
  acountshow: any = false;
  commission: any;
  // lessamountmsg: boolean = false;
  isPrevDeposit: boolean = false;
  transactionreportdata: any;
  withdrawaccountlist: any = [];
  paymentgatelist: GatewayResult[] = [];
  min_max = { min: 100, max: 500000 };
  promocode: PromotionResult[] = [];
  promoId: any;
  filldata: any = [];
  paylist: any = true;
  accountdatashow: any = {};
  payload: any;
  currentaccountshow: any = true;
  // bankdetails: any = {};
  transactionreportdatawithdraw: any;
  transactionreportdatadeposit: any;
  // depositrepost: any = false;
  // withdrawrepost: any = true;
  // deposittabactive: any = true;
  // withdrawtabactive: any = false;
  depositdetailsshow: any = true;
  withdrawdetailsshow: any = true;
  transactionreporthide: any = false;
    depositdetailsshowonpage: any = {};
    withdrawaldetailsshowonpage: any = {};
  isScroll: boolean = false;
  depositentervalue: number;
  Backsubtotal: any;
  Laysubtotal: any;
  Marketsubtotal: any;
  NetmarketTotal: any;
  CommissionTotal: any;
  public qrcode: any = {};
  public gatewayIdqrcode: any;
  public acNoforqurcode: any;
  total = 0;
  newaccountamountentervalue: any;
  newaccounTtotal: any = 0;
  depositMinValue: any = 300;
  depositMaxValue: any = 500000;
  public bankName: any;
  // bankbranchName: any;
  // bankcityName: any;

  // IFSCError: string = '';
  selectedFile = null;
  Membercode: any;
  spinner: boolean = false; // show spinner on withdraw

  promoItemInfo: any;
  hideCancelButton: boolean = false;
  amountFirstStepError: any;
  invalidIfscErrorMessage: boolean = false;
  fifthCharacterIfsc: boolean = false;
  howToDeposit: boolean = false;
  howDepositLangPopup: boolean = false;
  howToDepositHindi: boolean = false;
  paymentGatewayApiData: any;
  paymentGateWayOpt = false;
  kycUpdatePopup = true;
  intercomBtn = false;


  constructor(
    private myprofileServices: MyprofileService,
    public authservice: AuthService,
    private clipboardService: ClipboardService,
    private toastr: NotifyServiceService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private clipboard: Clipboard,
    public _bonus: BonusService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    // private pixel: PixelService,
    private gtm: GtmService
  ) { }

  @ViewChild('ischecked') ischecked: ElementRef;
  @ViewChild('closebutton', { static: true }) closebutton: ElementRef;

  ngOnInit(): void {
    this.getToken();
    this.setupIntercomInterval();
    //get element by class name "intercom-lightweight-app"
    const intercom = document.getElementsByClassName('intercom-lightweight-app')[0];
    //if element is present then remove it
    if (intercom) intercom.remove();
      this.Membercode = localStorage.getItem('Member_code');
    this.getPromocode();
    this.paymentGatewayData();
    this.acountstament();
    this.transactionreports();
    this.myprofileServices.getBalanceData().subscribe((data) => {
      this.balnceamout = data?.result;
        this.availableBalance = this.balnceamout?.availBalance;
      this.balanceWithoutBonus = this.balnceamout?.balanceWithoutBonus;
      this.bonusMoney = this.balnceamout?.bonusAmount;
      });
    this._bonus.getAllBonuses('active');
    this._bonus.bonuses.find((bonus: any) => {
      if (bonus?.PROMO_CODE === this.welcomeOfferCode) {
        this.activeBonusOfUser = bonus?.AMOUNT ? bonus?.AMOUNT : 0;

      }
      });
    this.route.params.subscribe(params => {
      this.depositshowes = params['id'];
      this.istabactive = this.depositshowes;
    });

    this.myprofileServices.accountusecurentacountdata().subscribe((res) => {
      this.withdrawaccountlist = res.result;
    })

    this.depositaddmonys = this.fb.group({
      depositamount: [null, Validators.compose([Validators.required])],
      currentaccountifsc: [''],
      currentaccoutnaccountnumber: [''],
      currentaccountname: [''],
      utrnumber: ['', Validators.compose([Validators.required, NumericFieldValidator.ValidNumericField, Validators.minLength(6), Validators.maxLength(22),])],
    })

    this.canceldepositform();
    this.depositeditform();
    this.addmonys = this.fb.group({
      currentaccountamount: ['', Validators.compose([Validators.required])],
    })



  }
  //withdraw page add bank account formgroup with custom validator
  accountNumberMatchValidator: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    const accountNumber = control.get('accountNumber');
    const confirmAccountNumber = control.get('confirmAccountNumber');

    // Return an error if the account numbers don't match
          return accountNumber && confirmAccountNumber &&
                  accountNumber.value !== confirmAccountNumber.value ?
              {'accountNumberMismatch': true} :
              null;
  };

  addBankAccountForm: FormGroup = this.fb.group({
    accountNumber: ['', Validators.compose([
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.minLength(5),
      Validators.maxLength(18),
    ])],
    confirmAccountNumber: ['', Validators.compose([
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.minLength(5),
      Validators.maxLength(18),
    ])],
    ifscCode: ['', Validators.compose([
      Validators.required,
      Validators.minLength(11),
      Validators.maxLength(11),
    ])],
    accountName: ['', Validators.compose([Validators.required])],
  }, { validator: this.accountNumberMatchValidator } as AbstractControlOptions)



  showHowToBonus(bonus) {
    if (bonus.promoCode == 'WELCOME500') {
      this.showHowTo = bonus;
    } else {
      this.showHowTo2 = bonus;
        this.shortInfo = this.showHowTo2['short-info'].split(' ');
        this.firstTwoWordofShortInfo = this.shortInfo.slice(0, 2).join(' ');
        this.remainingWordsofShortInfo =
            this.shortInfo.slice(2, this.shortInfo.length).join(' ');
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(
            this.showHowTo2['infoRules']);
    }
  }

  getToken() {
    this.loggerUsername = localStorage.getItem('User_name');
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  withdrawMoney: FormGroup = this.fb.group({
    withdrawAmount: ['', Validators.compose([Validators.required])],
    userBankIfsc: ['', Validators.compose([
      Validators.required,
      Validators.minLength(11),
      Validators.maxLength(11),
    ])],
    userBankAccoutNo: ['', Validators.compose([
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.minLength(9),
      Validators.maxLength(18),
    ])],
    userBankingName: ['', Validators.compose([Validators.required])]
  })

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  //select offer from bonus list
  selectOffer(offer: PromotionResult) {
    this.showBonusListPopup = false;
    if (this.depositentervalue == 0 || this.depositentervalue == null || this.depositentervalue == undefined) {
      this.toastr.error('Please enter amount first');
      return;
    }
    this.promoId = offer.promoCode;
    this.selectedPromo = offer;
    if (this.promoId == 'WELCOME500') {
      this.isWelcomeOffer = true;
    } else {
      this.isWelcomeOffer = false;
    }

    this.offerApplied = true;
    this.calculateBonus(offer);
  }

  showPopUpToggle() {
    if (this.clearIndianCurrency(this.depositentervalue) >= this.min_max.min && this.clearIndianCurrency(this.depositentervalue) <= this.min_max.max) {
      this.showPopUp = !this.showPopUp;
    } else {
      this.toastr.error(`Please enter amount between ${this.formatToIndianCurrency(this.min_max.min)} to ${this.formatToIndianCurrency(this.min_max.max)}`);
    }
  }

  depositeditform() {
    this.depositedit = this.fb.group({
      amountedit: [''],
      // utredit: [''],
      utredit: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(12),
      ])],

    })
  }


  showAccountNumberInput() {
    this.showAccountNumberinput = this.showAccountNumberinput === 'password' ? 'number' : 'password';
  }

  showConfirmAccountNumberInput() {
    this.showConfirmAccountNumberinput = this.showConfirmAccountNumberinput === 'password' ? 'number' : 'password';
  }

  copyToClipboard(className: string): void {
    const accnumElement = document.querySelector(className) as HTMLParagraphElement;
    const accnumText = accnumElement.innerText;
    const tempInput = document.createElement('input');
    tempInput.value = accnumText;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.toastr.success('Copied to clipboard');
  }

  //event for preview selected image
  onFileSelected(event: Event) {
    let input = event.target as HTMLInputElement;
    // let imageTag = document.querySelector("#bank-receipt");

    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        //convert image to base64
        this.imagePath = reader.result as string;
        this.selectedFile = input.files[0];
        //convert to binary

        // this.imagePath = this.imagePath.split(',')[1];
        // console.log('reader.result', this.imagePath);
        };
      reader.readAsDataURL(input.files[0]);
      this.showSvg = false;
      this.showImg = true;
    }
  }

  closeImage() {
    this.showSvg = true;
    this.showImg = false;
    this.imagePath = '';
    this.selectedFile = null;
  }

    async toggleShowBonusListPopup() {
      //check if 'ROYALTOKEN_KEY' is present in localStorage
      //then call getPromoCode() to get promo code
      if(this.getToken()){
      // console.log('this is woking');
        await this.getPromocode();
    this.showBonusListPopup = !this.showBonusListPopup;
  }
    }

  toggleOffer() {
    if (this.offerApplied == true) {
      this.promoId = '';
      this.isWelcomeOffer = false;
    }
    if (this.showBonusListPopup == false && this.offerApplied == false) {
      this.toggleShowBonusListPopup();
    }
    this.offerApplied = !this.offerApplied;
    //clear selected welcome offer
  }

  depositEdit(id: any) {
    let data = {
      UTR: this.depositedit.controls['utredit'].value,
      amount: this.depositedit.controls['amountedit'].value,
      requestId: id
    }

    this.myprofileServices.deposittransactionreportedit(id, data).subscribe((res) => {
      if (res) {
                             this.toastr.success(
                                 'Your messages has been sent successfully');
        this.transactionreports();
        this.depositdetailsshowonpageamount = res.result.amount;
        this.depositdetailsshowonpage.utr = this.depositedit.controls['utredit'].value
      }

    }, (err) => {
      this.toastr.error(err?.error?.error || err.error.result);
    })
  }


  canceldepositform() {
    this.canceldeposit = this.fb.group({
      remarks: ['']
    })
  }

  transactionreportcancel(id) {
    let remarks = {
      requestId: id,
      remark: this.canceldeposit.controls['remarks'].value
      };
      this.myprofileServices.deposittransactionreportcancel(id, remarks)
          .subscribe(
              (res) => {
      if (res) {
                  this.toastr.success(
                      'Your messages has been sent successfully');
                  this.depositdetailsshowonpage = {
                    ...this.depositdetailsshowonpage,
                    status: 'Cancelled'
                  };
        this.depositdetailsshowonpagecancel = 'Cancelled';
        this.transactionreports();
        this.hideCancelButton = true;
                  // console.log('hideCancelButton',
                  // this.hideCancelButton);
      }

    }, (err) => {
      this.toastr.error(err.error.result);
    })
  }



  tabshow(val: string) {
    // console.log('tabshow', val);
      this.bankName = false;
    this.total = 0;
    this.depositaddmonys.reset();
    this.withdrawMoney.reset();
      this.back();
      this.newaccounTtotal = null;
      this.newaccountamountentervalue = 0;
      this.hideCancelButton = false;
    this.withdrawMoney.reset();

    this.istabactive = val;
    this.depositshowes = val;
    this.paylist = true;
    if (val == 'Withdraw') {
      // this._bonus.getAllBonuses('active');

      this._bonus.bonuses.find((bonus: any) => {
          // console.log('bonus', this.activeBonus, this.totalWagered,
          // this.wageredCap, this.remainWagered, this.activeBonusPercent,
          // this.EXPIRY_DATE, this.remainDays, this.remainHours);

        if (bonus.STS_ID === 20) {
          this.activeBonus = bonus;
          this.activeBonusOfUser = bonus?.AMOUNT ? bonus?.AMOUNT : 0;
          this.bonusMoney = this.balnceamout?.bonusAmount;
          this.totalWagered = bonus?.wagered?.totalWagered;
          this.wageredCap = bonus?.wagered?.wageredCap;
          this.remainWagered = this.wageredCap - this.totalWagered;
          this.activeBonusPercent = this.totalWagered / this.wageredCap * 100;
          this.activeBonusPercent = this.activeBonusPercent < 6 ? 6 : this.activeBonusPercent;
          this.EXPIRY_DATE = new Date(bonus?.EXPIRY_DATE);
          this.remainDays = Math.floor((this.EXPIRY_DATE.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
          this.remainHours = Math.floor((this.EXPIRY_DATE.getTime() - new Date().getTime()) / (1000 * 3600) % 24);
            // console.log('bonus', this.activeBonus, this.totalWagered,
            // this.wageredCap, this.remainWagered, this.activeBonusPercent,
            // this.EXPIRY_DATE, this.remainDays, this.remainHours);
        }
      });


      if (confirm('If you try to withdraw free Money then your bonus will be lapsed. Are you sure you want to continue?')) {
        this.goToWithdrawPage('first');
        this.router.navigate(['/balancemanagement/Withdraw']);
      } else {
        this.tabshow('Deposit');
        this.router.navigate(['/balancemanagement/Deposit']);
      }

    }
    if (val == 'Deposit') {
      this.bankTransfer = true;
      this.utrnopage = true;
      this.imagePath = '';
      this.depositentervalue = 0;
      this.showImg = false;
        this.backtodepositeamout();
      this.hidedepositmenthod = false;
      this.bankPaymentMethod = true;
      this.isWelcomeOffer = false;
      this.bonus = 0;
      this.offerApplied = false;
      this.paymentGatewayData();
      this.router.navigate(['/balancemanagement/Deposit']);
    } else if (val == 'TransactionHistory') {
      this.currentaccountshow = true;
      this.router.navigate(['/balancemanagement/TransactionHistory']);
    } else if (val === 'depositWithdrawReport') {
      this.router.navigate(['/balancemanagement/depositWithdrawReport']);
    }
  }

  accountfiler() {
    this.showhideaccountfilter = !this.showhideaccountfilter;
  }

  acountstament(fromDate = 0, toDate = 0) {
    this.myprofileServices.accountstatement(fromDate, toDate).subscribe((res) => {
      this.accountStatements = res.result.data;
      this.filldata = this.accountStatements;
      this.commission = res.result.data?.[0].commission;
    })
  }

  filteraccountstatement() {
    let dateget = new Date(this.range.controls.start.value);
    let startdate = dateget.getTime();
    let date_end = new Date(this.range.controls.end.value)
    let end_date = date_end.getTime();
    end_date = end_date + 86400000;
    this.acountstament(startdate, end_date)
    this.filldata = this.accountStatements?.filter(
      (val: any) => val.date >= startdate && val.date <= end_date);
  }

  accountdetails(val: any) {
    this.myprofileServices.accountstatements(val).subscribe((res) => {

      let backttl = 0;
      let layttl = 0;
      let mrktttl = 0;
      // let commissions = 0;
      let netttl = 0;

      this.accountStatementss = res.result;


      for (let net = 0; net < this.accountStatementss?.length; net++) {


        let backk = this.accountStatementss[net].side == 0 ? this.accountStatementss[net].memberWin : 0.00;
        backttl += backk;
        let llay = this.accountStatementss[net].side == 1 ? this.accountStatementss[net].memberWin : 0.00;
        layttl += llay;
        let mrkttl = this.accountStatementss[net].memberWin;
        mrktttl += mrkttl;
        this.CommissionTotal = this.accountStatementss[net].commission;

        let too = this.accountStatementss[net].memberWin;
        netttl += too;

      }
      this.Backsubtotal = backttl;
      this.Laysubtotal = layttl;
      this.Marketsubtotal = mrktttl;
      this.NetmarketTotal = netttl;
      }, (err) => {this.toastr.error(err.error)});
    this.acountshow = true;
    this.accountdetailsshow = false;
  }

  transactionreports() {
    this.myprofileServices.transactionreport().subscribe((res) => {

      this.transactionreportdata = res?.result;
      this.isPrevDeposit = this.transactionreportdata?.some((item: any) => item?.type === 'Deposit' && item?.status === 'Completed') || false
      if (this.transactionreportdata?.length) {

        this.transactionreportdatadeposit = this.transactionreportdata?.filter((val: any) => {
          return val.type == 'Deposit';
              });
          this.transactionreportdatadeposit?.sort(
              (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

        this.transactionreportdatawithdraw = this.transactionreportdata?.filter((val: any) => {

          return val.type == 'Withdrawal';
              });
          this.transactionreportdatawithdraw?.sort(
              (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      }

    })

  }

  getWithdrawBankList() {
    this.isLoader = true;
    this.myprofileServices.getBankAccountList().subscribe((res) => {
      if (res.result == null) {
              this.toastr.error('You have no account yet');
        this.bankDetailsAvailable = false;
        this.isLoader = false;
      } else {
        this.isLoader = false;
        this.bankDetailsAvailable = true;
        this.withdrawaccountlist = res.result;
        //check if bank list is greater than one
        if (this.withdrawaccountlist.length == 1) {
        this.selectBankAccount(this.withdrawaccountlist[0].accountId);
        } else {
          this.selectBankAccount(null);
        }
      }
    }, (err) => {
      this.toastr.error(err.error.result);
      this.isLoader = false;
    })
  }

  getBankLogoLink(bankCode: string) {
    // console.log(bankCode);
    if (bankCode == undefined) {
      return this.bankLogoLinks['DEFAULT'];
    }
    return this.bankLogoLinks[bankCode];
  }

  //this function resets form on the pop up of add bank account
  cancelAddAccount() {
    //reset form
    this.addBankAccountForm.reset();
    this.enteredBankdetails = {};
    this.withdrawPopup = false
  }
  async getBankDetailsByIfsc(event: Event) {
    this.spinner=true;
    let element = event.target as HTMLInputElement;
    //make ifsc code uppercase
    element.value = element.value.toUpperCase();
    //if ifsc code length is less than 11 then return
    if (element.value.length != 11) {
      // this.toastr.error('Invalid IFSC Code');
      // console.log(element.value.length, element.value);
      this.enteredBankdetails = false;
      return;
    }
    let ifsc = this.addBankAccountForm.controls['ifscCode'].value?.toUpperCase();

    //restrict to not enter chars length above 11 by checking length
    if (ifsc.length > 11) {
      ifsc = ifsc.substr(0, 11);
    }

    if (ifsc.length < 11) {
      this.enteredBankdetails = null;
    }
    await this.myprofileServices.bankdetailsshowafterenterifsccode(ifsc).toPromise().then(res => {
      this.enteredBankdetails = res?.result;
      this.invalidIfsc = false;
      this.enteredBankdetails.link = this.getBankLogoLink(this.enteredBankdetails?.BANKCODE);
      this.spinner=false;
    }).catch((err) => {
      this.toastr.error('Invalid IFSC Code', 2000, true );
      this.addBankAccountForm.controls['ifscCode'].setErrors({ 'incorrect': true });
      this.enteredBankdetails = false;
      this.spinner=false;
      return;
    });
  }

  getIfscValidationsErrormessage() {
    let ifscValue = this.addBankAccountForm.controls['ifscCode'].value;

    // Remove any characters that are not alphanumeric
    ifscValue = ifscValue.replace(/[^A-Za-z0-9]/g, '');

    //The length must not be more than 11 chars
    if (ifscValue.length > 11) {
      ifscValue = ifscValue.substr(0, 11);
    }

    // Update the form control value
    this.addBankAccountForm.controls['ifscCode'].setValue(ifscValue);

    const regex = /^[A-Z]{4}/;
    const firstFourChars = ifscValue.substr(0, 4);
    const fifthCharRegex = /^.{4}0/;
    const fifthChar = ifscValue.charAt(4);
    if (!regex.test(firstFourChars)) {
      this.invalidIfscErrorMessage = true;
    } else {
      this.invalidIfscErrorMessage = false;
    }
    if (!fifthCharRegex.test(ifscValue)) {
      this.fifthCharacterIfsc = true;
    } else {
      this.fifthCharacterIfsc = false;
    }
  }

  calculateBonus(item: any) {
    let virtualBonusPercentage = item?.virtualBonusPercentage || 0;
    let realBonusPercentage = item?.realBonusPercentage || 0;
    let maxCap = item?.perMarketCap;
    if (virtualBonusPercentage > 0 && realBonusPercentage > 0) {
      this.isWelcomeOffer = true;
      this.percentageBonus = item?.realBonusPercentage;
      this.welcomeBonus = this.depositentervalue * virtualBonusPercentage / 100;
      this.welcomeBonus = this.welcomeBonus >= maxCap ? maxCap : this.welcomeBonus;
      this.bonus = this.depositentervalue * realBonusPercentage / 100;
    } else if (realBonusPercentage > 0) {
      this.isWelcomeOffer = false;
      this.percentageBonus = item?.realBonusPercentage;
      this.bonus = this.depositentervalue * realBonusPercentage / 100;
    } else {
      this.isWelcomeOffer = true;
      this.welcomeBonus = this.depositentervalue * virtualBonusPercentage / 100;
      this.bonus = 0;
    }
    if (this.welcomeBonus == 0 && this.bonus == 0) {
      this.offerApplied = false;
      this.isWelcomeOffer = false;
    }
  }

  closeInfo() {
    this.promoItemInfo = null
  }

  /**function downloading qr code */
  saveQrCode(): void {
    const imageElement = document.querySelector('.qr-image') as HTMLImageElement;
    const imageSrc = imageElement.src;

    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = 'qr-code.png'; // Replace with desired filename
    link.click();
  }

  /**function sharing qr code */
  shareQrCode(): void {
    const imageElement = document.querySelector('.qr-image') as HTMLImageElement;
    const imageSrc = imageElement.src; //this image source is base64
    let files;
    // console.log(navigator)
    if (navigator.share) {
      //convert imageSrc to blob
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          files = [new File([blob], 'qr-code.png', { type: blob.type })];
          // console.log('file', files);

          if (!navigator.canShare({ files })) {
            this.toastr.error('Your system doesn\'t support sharing files.');
            // console.log('Your system doesn\'t support sharing files.');
            return;
          }
          navigator.share({
            title: 'Shared Image',
            text: 'Check out this image!',
            files,
          })
            .then(() => this.toastr.success('Image shared successfully'))
            .catch((error) => console.error('Error sharing image:', error));
        });
      //share image

    } else {
      const tempInput = document.createElement('input');
      tempInput.value = imageSrc;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      console.log('Image URL copied to clipboard');
    }
  }

  selecUpiMethod(method: string) {
    this.selectedUpiMethod = method;

    // Define the schemes for each method
    const schemes = {
      'gpay': 'tez',
      'phonepe': 'phonepe',
      'paytm': 'paytmmp',
      'default': 'upi'
    };

    // Get the scheme for the selected method
    const scheme = schemes[method] || schemes['default'];

    // Create the payment address
    const paymentAddress = this.isBankPay ?
        `${this.accountdatashow?.account?.acNo}@${this.accountdatashow?.account?.IFSC}.ifsc.npci` :this.accountdatashow.account.acNo;

    // Create the URL
    const url = `${scheme}://pay?pa=${paymentAddress}&pn=${this.accountdatashow?.account?.name}&am=${this.depositentervalue}&tn=${this.Membercode}&cu=INR`;

    // Open the URL
    location.href = url;
  }

  generateQrCode() {
    upiqr({
        payeeVPA: this.isBankPay ? this.accountdatashow?.account?.acNo + '@' +
                this.accountdatashow?.account?.IFSC + '.ifsc.npci' :
                                   this.accountdatashow.account.acNo,
      payeeName: this.accountdatashow?.account?.name,
      amount: this.depositentervalue.toString(),
      transactionNote: this.Membercode,
      currency: 'INR',
    })
      .then((upi) => {
        // console.log(upi.qr);
        this.generatedQrCode = upi.qr;
      })
      .catch((err) => { console.log(err) });
  }

  goToWithdrawPage(pageNumber: string) {
    if (pageNumber == 'first') {
      this.withdrawFirstPage = true;
      this.withdrawSecondPage = false;
      this.withdrawThirdPage = false;
    } else if (pageNumber == 'second') {
      if (this.withdrawMoney.controls['withdrawAmount'].value == null || this.withdrawMoney.controls['withdrawAmount'].value == undefined || this.withdrawMoney.controls['withdrawAmount'].value == '') {
        this.toastr.error('Please enter amount');
        return;
      }
      if (this.clearIndianCurrency(this.withdrawMoney.controls['withdrawAmount'].value) < this.getWithdrawLimit()) {
        this.toastr.error(`Minimum withdraw amount is ${this.getWithdrawLimit()}`);
        return;
      }
      if (this.clearIndianCurrency(
        this.withdrawMoney.controls['withdrawAmount'].value) >
        this.balanceWithoutBonus) {
        this.toastr.error(
          'You can not withdraw more than your main wallet balance');
        return;
      }
      this.getWithdrawBankList();
      this.withdrawFirstPage = false;
      this.withdrawSecondPage = true;
      this.withdrawThirdPage = false;
      // this.addAccountPage = true;
      this.stepperValue = 2;
    } else if (pageNumber == 'third') {
      this.withdrawFirstPage = false;
      this.withdrawSecondPage = false;
      this.withdrawThirdPage = true;
      this.stepperValue = 3;
    }
  }

  onInput(event: Event) { //restrict user to enter only numbers upto 18 digits only
    let acNo = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    if (acNo.length > 18) {
      acNo = acNo.substring(0, 18);
    }
    (event.target as HTMLInputElement).value = acNo;
  }

  onKeyDownForAcc(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Tab') {
      event.preventDefault();
    }
    if (event.target instanceof HTMLInputElement) {
      const inputLength = event.target.value.length;
      if (inputLength >= 18 && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
  }

  onTextInput(event: Event) {
    //only allow text and space remove other characters
    let acName = (event.target as HTMLInputElement).value.replace(/[^a-zA-Z ]/g, '');
    (event.target as HTMLInputElement).value = acName;
  }

  showAddBankAccount() {
    if (this.withdrawaccountlist?.length >= 5) {
      this.bankLimitPopup = true;
      return;
    }
    this.withdrawPopup = !this.withdrawPopup;
    this.enteredBankdetails = false;
  }

  goToWithdrawPopup() {
    this.withdrawCongoPopup = !this.withdrawCongoPopup;
  }

  goToRemovePopup(bankId: number) {
    this.deleteBankId = bankId;
    this.removePopup = !this.removePopup;
  }


  selectBankAccount(id: number) {
    this.selectedWithdrawBank = this.withdrawaccountlist.find((item: any) => item.accountId === id);
  }


  async showAccountDetailPage() {
    //check if user has entered bank details
    if (this.addBankAccountForm.invalid) {
      this.toastr.error('Please enter All details');
      return;
    }
    // console.log(this.addBankAccountForm.errors);
    //if user already has 5 bank accounts then show error
    if (this.withdrawaccountlist?.length >= 5) {
      this.toastr.error('You can add maximum 5 bank accounts');
      return;
    }
    //if account no and confirm account no does not match then show error
    if (this.addBankAccountForm.controls['accountNumber'].value !== this.addBankAccountForm.controls['confirmAccountNumber'].value) {
      this.toastr.error('Account number does not match');
      return;
    }
    //here i need to call add bank details api
    //and if success then need to call get bank details api
    // this.getWithdrawBankList();
    let data = {
        'ifsc': this.addBankAccountForm.controls['ifscCode'].value,
        'accountNo': this.addBankAccountForm.controls['accountNumber'].value,
        'accountName': this.addBankAccountForm.controls['accountName'].value
    }
    //withdrawaccountlist getWithdrawBankList
    if (this.withdrawaccountlist?.find((item: any) => (item?.IFSCCode === data.ifsc || item?.IFSCCode?.toUpperCase() === data.ifsc?.toUpperCase()) && item?.accountNo == data.accountNo)) {
      this.toastr.error('This account already exists');
      return;
    }

    if (this.invalidIfscErrorMessage === false && this.fifthCharacterIfsc === false) {
    this.myprofileServices.addBankAccount(data)
      .subscribe(
        (res) => {
          this.toastr.success('Account added successfully');
          // console.log(res);
          this.addBankAccountForm.reset();
          this.getWithdrawBankList();
        },
        (err) => {
              console.log(err);
          this.toastr.error(err.error.error || 'Something went wrong');
          //if error then false it
          this.enteredBankdetails = false;
        });
    }
    this.bankDetailsAvailable = true;
    this.withdrawPopup = false;
    this.addAccountPage = false;
  }

  backToWithdrawPage() {
    this.withdrawThirdPage = true;
    this.withdrawFirstPage = false;
    this.withdrawSecondPage = false;
    this.withdrawCongoPopup = false;
  }

  backToFirstPage() {
    this.withdrawThirdPage = false;
    this.withdrawFirstPage = true;
    this.withdrawSecondPage = false;
    this.addAccountPage = false;
    this.stepperValue = 1;
  }

  backToSecondPage() {
    this.withdrawThirdPage = false;
    this.withdrawFirstPage = false;
    this.withdrawSecondPage = true;
    this.addAccountPage = false;
    this.stepperValue = 2;
  }

  withdrawAmountFromBank() {

    let withdrawamount = {
        'amount': this.clearIndianCurrency(
            this.withdrawMoney.get('withdrawAmount').value),
        'acNo': this.selectedWithdrawBank?.accountNo,
        'IFSC': this.selectedWithdrawBank?.IFSCCode,
        'bankName': this.selectedWithdrawBank?.bankName,
        'branchName': this.selectedWithdrawBank?.branchName,
        'accountName': this.selectedWithdrawBank?.accountName,
    }
    //if withdraw amount is less than 300 then show error
    if (withdrawamount.amount < this.getWithdrawLimit()) {
      this.toastr.error(`Minimum withdraw amount is ${this.getWithdrawLimit()}`);
      return;
    }
    //if withdraw amount is greater than available balance then show error
    if (withdrawamount.amount > this.availableBalance) {
      this.toastr.error('Insufficient balance');
      return;
    }
    this.myprofileServices.withdrawfunds(withdrawamount).subscribe((res) => {
      if (res) {
        this.goToWithdrawPopup();
                  this.toastr.success('Withdraw request sent successfully');
        // this.home();
      }
    }, (err) => {
      if (this.selectedWithdrawBank) {
        this.toastr.error(err.error.error || 'Something went wrong');
      } else {
        // console.log(err, this.enteredBankdetails);
        this.toastr.error('Select or Add a New Account first');
      }

    })
      }

    // checkAccount() {
    //   const accNum =
    //       this.withdrawMoney.controls['newaccountaccountnumber'].value
    //   const ifscNum = this.withdrawMoney.controls['newaccountifsccode'].value
    //   return !!this.withdrawaccountlist &&
    //       !!this.withdrawaccountlist
    //             ?.filter(
    //                 (item: any) => (item?.IFSCCode === ifscNum ||
    //                                 item?.IFSCCode?.toUpperCase() ===
    //                                     ifscNum?.toUpperCase()) &&
    //                     item?.accountNo == accNum)
    //             ?.length
    // }


  /**
   * function for getting payment gateway data
   * and setting min and max values for deposit
   * and setting first payment gateway as default
   */
  paymentGatewayData() {
    this.total = 0;
    this.myprofileServices.paymentgatewaylist().subscribe((res:PaymentGateways) => {
      this.paymentgatelist = res.result;  // got payment gateway list
      if(this.paymentgatelist?.length <= 0){
        this.toastr.error('No payment gateways available');
      }
      const { min, max } = this.paymentgatelist.reduce(
        (acc: { min: number, max: number }, item: { min: string, max: string }) => {
          const valueMin = Number.parseFloat(item.min);
          const valueMax = Number.parseFloat(item.max);

          return {
            min: Math.min(acc.min, valueMin),
            max: Math.max(acc.max, valueMax)
          };
        },
        { min: Infinity, max: -Infinity }
      );

      this.min_max = { min, max };
      //seperate payment gateway list for bank and upi
      this.upiGateway = this.paymentgatelist?.filter((item: GatewayResult) => item?.isUPI === true);
      this.nonUpiGateway = this.paymentgatelist?.filter((item: GatewayResult) => item?.isUPI === false);
      this.paymentGateways=this.nonUpiGateway?.filter((item: GatewayResult) => item?.isManual === false);
      this.nonUpiGateway1 = this.nonUpiGateway?.filter((item: GatewayResult) => item?.isManual === true);
      this.accountdatashow = res.result[0]; // auto selected first payment gateway
      // this.generateQrCode();
    })
  }


  async getPromocode() {
    this.isLoader = true;
    await this.myprofileServices.getPromotion().subscribe((res:Promotion) => {
      this.isLoader = false;
      this.promocode = res.result;
      if (this.promocode?.length) {
        this.selectedPromo = this.promocode[0];
        this.promoId = this.selectedPromo.promoCode;
        this.isLoader = false;
      }
      });
    this.isLoader = false;
  }

  paymentgatewayaccount(id: any) {
    this.showSkeleton = true;
    this.total = 0;
      this.accountdatashow = [];
    this.paylist = false;
    this.hidedepositmenthod = true;
    this.paymentGateWayOpt = true;
    this.myprofileServices.paymentgatewayaccountdata(id).subscribe(
      (res) => {
        this.isLoader = false;
        this.accountdatashow = res.result;
        if (!res?.result?.isManual) {
          this.paymentGatewayApiData = res?.result;
          this.paymentGateWayOpt = true;
          this.redirectToPaymentGateway();
        } else {
          this.paymentGateWayOpt = false;
        this.showSkeleton = false;
          this.depositMinValue = Number(this.accountdatashow.min);
          this.depositMaxValue = Number(this.accountdatashow.max);
        this.generateQrCode();
        }
      },
      (err) => {
        this.depositaddmonys.reset();
        this.showSkeleton = false;
        this.toastr.error(err.error.error);
        this.isLoader = false;
      });
  }


  backacountdetaisl() {
    this.acountshow = false;
    this.accountdetailsshow = !this.accountdetailsshow;
  };

  inputvalue(val: any) {
    this.depositentervalue = val.target.value;
    this.qrcode = this.depositentervalue;
  }
  valu: any = 0;


  async depositmoney(val: any) {
    // this.amountFirstStepError = null
    if (!this.promocode) {
      await this.getPromocode();
    }
    this.total = +this.clearIndianCurrency(this.depositaddmonys.controls['depositamount'].value || 0) + val;
    this.depositaddmonys.controls['depositamount'].setValue('₹' + this.total);  // this.formatToIndianCurrency(this.total)
      this.depositentervalue = this.total;
    this.qrcode = this.total;
    this.acNoforqurcode = this.accountdatashow?.account?.acNo;
    this.gatewayIdqrcode = this.accountdatashow?.id;
    this.offerApplied = true;
    this.calculateBonus(this.selectedPromo);
  }

    // newaccountamount(val: any) {
    //   if (this.newaccountamountentervalue == undefined) {
    //     this.newaccountamountentervalue = 0;
    //   }
    //
    //   this.newaccounTtotal += Number(this.newaccountamountentervalue) + val;
    //   this.withdrawMoney.controls['newaccountamount'].setValue(
    //       this.formatToIndianCurrency(this.newaccounTtotal));
    //   this.newaccountamountentervalue = 0;
    //   if (this.newaccounTtotal > this.availableBalance) {
    //     this.lessamountmsg = true;
    //   this.minimummoney =false;
    //     this.withdrawMoney.setErrors({'invalid': true});
    //
    // } else {
    //     this.lessamountmsg = false;
    //   this.minimummoney =false;
    // }
    //  }

  async depositResquest() {
    //if utr no is blank then show error
    if (this.depositaddmonys?.controls['utrnumber']?.value == null ||
      this.depositaddmonys?.controls['utrnumber']?.value == undefined ||
      this.depositaddmonys?.controls['utrnumber']?.value == '') {
      this.toastr.error('Please enter UTR number');
      return;
    }

      if (this.depositaddmonys.status === 'INVALID') {
      return;
    }

    //if method is NEFT or RTGS then check if file is selected or not
    if (this.showReceiptArea == 'NEFT' || this.showReceiptArea == 'RTGS') {
      if (!this.selectedFile) {
        this.toastr.error('Please upload receipt');
        return;
      }
    }
    const data = {
      acNo: this.accountdatashow?.account?.acNo,
      amount: this.clearIndianCurrency(this.depositaddmonys?.controls['depositamount']?.value),
      gatewayId: this.accountdatashow?.id,
      utr: this.depositaddmonys?.controls['utrnumber']?.value,
        promoCode: this.promoId || '',
      file: this.selectedFile || null
      };
    const formData = new FormData();
    //if data object containes file then convert it to form data
    if (data.file) {
      formData.append('file', data.file);
      formData.append('acNo', data.acNo);
      formData.append('amount', data.amount);
      formData.append('gatewayId', data.gatewayId);
      formData.append('utr', data.utr);
      formData.append('promoCode', data.promoCode);
    }

    try {
      let res;
      if (formData.get('file')) {
        // console.log('formData call');
        res = await this.myprofileServices.depositmoney(formData, true).toPromise();
      } else {
        // console.log('data call');
        res = await this.myprofileServices.depositmoney(data, false).toPromise();
      }
      if (res) {
        this.showCongoPopup = true;


        this.toastr.success('Deposit request sent successfully');
        esk('track', 'Conversion');
        // this.trackingDepositPixelService();
        this.transactionreports(); //call transaction report api
        this.tabshow('transreport'); //show transaction report tab
        this.addmonys.reset(); //reset all values to default
        this.depositaddmonys.reset(); //reset all values to default
        const srtmPayload = {
            action: 'created',
          userId: localStorage.getItem('Member_code'),
          transactionId: res.result.requestId,
          amount: res.result.amount,
            currency: 'INR'
          };
        if (!this.isPrevDeposit && this.availableBalance === 0) {
          srtmPayload['label'] = 'first_deposit'
        }

        // try {
        //   this.gtm.push({event: 'Deposit'});
        //   this.gtm.push({'purchase' : 'Deposit' })
        //
        //
        //   window.fbq('init', '974838613755656');
        //   window.fbq('track', 'Purchase');
        //
        //   window.fbq1('init', '2706155189693721');
        //   window.fbq1('track', 'Purchase');
        //
        //   window.fbq2('init', '1190972091651303');
        //   window.fbq2('track', 'Purchase');
        //
        //   window.fbq3('init', '586379523499553');
        //   window.fbq3('track', 'Purchase');
        //
        //   window.fbq4('init', '832160437968868');
        //   window.fbq4('track', 'Purchase');
        //
        //   window.fbq5('init', '990285032032635');
        //   window.fbq5('track', 'Purchase');
        //
        //   this.pixel.initialize();
        //   this.pixel.trackCustom("Purchase")
        // } catch (e) {
        //   console.log("pixel error deposit", e)
        // }
        //
        //
        // window.srtmCommands.push({
        //   event: "track.betting.deposit",
        //   payload: srtmPayload
        // });
        this.showCongoPopup = true;
      } else {
        // Handle the error case if needed
      }
    } catch (err) {
      if (err.error && err.error.error) {
        this.toastr.error(err.error.error);
      } else if (err.error && err.error.result) {
        this.toastr.error(err.error.result);
      }
      this.addmonys.reset();
    }
  }


  back() {
    this.transactionreporthide = false;
    this.depositdetailsshow = true;
    this.withdrawdetailsshow = true;
      this.depositdetailsshowonpage = {};
      this.withdrawaldetailsshowonpage = {};
      this.depositdetailsshowonpagecancel = null;
    this.hideCancelButton = false;
  }


  deleteWithdrawBank() {
    this.isLoader = true;
    if (this.withdrawaccountlist?.length > 1) {
      this.authservice.deletebnkaccount(this.deleteBankId).subscribe((res) => {
        this.removePopup = false;
        this.isLoader = false;
        this.toastr.success(res.result);
        this.myprofileServices.getBankAccountList().subscribe((res) => {
          this.withdrawaccountlist = res.result;
          if (this.withdrawaccountlist?.length <= 1) {
            this.selectBankAccount(this.withdrawaccountlist[0].accountId);
          } else {
            this.selectedWithdrawBank = null;
          }
        })
      }, (err) => {
        this.isLoader = false;
                  this.toastr.error('Account not deleted')
      })
    } else {
      this.authservice.deletebnkaccount(this.deleteBankId).subscribe((res) => {
        this.isLoader = false;
        this.toastr.success(res.result);
        this.myprofileServices.getBankAccountList().subscribe((res) => {
          this.isLoader = false;
          if (res.result == null || res.result?.length == 0) {
            this.bankDetailsAvailable = false;
            this.withdrawaccountlist = [];
          }
          this.removePopup = false;
          this.withdrawaccountlist = res.result;
          this.bankDetailsAvailable = false;
          if (this.withdrawaccountlist?.length <= 1) {
            this.selectBankAccount(this.withdrawaccountlist[0].accountId);
          } else {
            this.selectedWithdrawBank = null;
          }
        })
      }, (err) => {
        this.isLoader = false;
                  this.toastr.error('Account not deleted')
      })
    }
  }

  goToHome() {
    //navitage to /
    this.router.navigate(['/']);
  }

  getMemberData() {
    return JSON.parse(localStorage.getItem('userData'))
  }

  getWithdrawLimit(): number {
    return this.getMemberData()?.minWithdraw;
  }

  // depositenewdesign
  gotopaymentmethod() {
    const errors: any = this.depositaddmonys.controls['depositamount'].errors
    const minMax: any = this.depositentervalue >= this.min_max.min && this.depositentervalue <= this.min_max.max
    if (this.depositaddmonys.controls['depositamount'].valid && this.depositentervalue != undefined && minMax) {
      this.depositeamount = true;
      this.paymentmethods = false;
      this.utrnopage = true;
      //increase
      this.showPopUpToggle();
      this.stepperValue = 2;
    }
    else {
      if ((!errors || errors.required) && !this.depositentervalue) this.amountFirstStepError = true
      if (errors?.required) this.amountFirstStepError = true
    }
  }
  async amountBlur(event: any) {
    //if !this.promocode then call api
    if (!this.promocode) {
      await this.getPromocode();
    }
    let inputValue = event.target.value;
    //if inputValue contains '₹' then remove it
    //split the inputValue with '₹' and get the second part
    const parts = inputValue.split('₹');
    // if not contains '₹' then concatinate with '₹'
    let secondPart: any;
    if (parts.length === 1) {
      inputValue = '₹' + inputValue;
      secondPart = parts[0];
    } else {
      secondPart = parts[1];
    }
    const cleanedValue = secondPart.replace(/[\D]/g, '');
    const numericValue = parseInt(cleanedValue);
    if (isNaN(numericValue)) {
        this.amountFirstStepError = null;
      this.depositaddmonys.get('depositamount')?.setValue('');
      this.depositentervalue = 0;
      this.offerApplied = false;
      return
    }
    this.depositentervalue = numericValue;
    const formattedValue = this.formatToIndianCurrency(numericValue);
      this.depositaddmonys.get('depositamount')?.setValue('₹' + formattedValue);
    this.offerApplied = true;
    this.calculateBonus(this.selectedPromo);
  }

  //withdraw amount blur
  withdrawAmountBlur(event: any) {
    let inputValue = event.target.value;
    //if inputValue contains '₹' then remove it
    //split the inputValue with '₹' and get the second part
    const parts = inputValue.split('₹');
    // if not contains '₹' then concatinate with '₹'
    let secondPart: any;
    if (parts.length === 1) {
      inputValue = '₹' + inputValue;
      secondPart = parts[0];
    } else {
      secondPart = parts[1];
    }
    const cleanedValue = secondPart.replace(/[\D]/g, '');
    const numericValue = parseInt(cleanedValue);
    if (isNaN(numericValue)) {
        this.amountFirstStepError = null;
      this.withdrawMoney.get('withdrawAmount')?.setValue('');
      // this.depositentervalue = '';
      return
    }
    // this.withdrawMoney.get('withdrawAmount').setValue(numericValue);
    this.progressBarPercentage = (numericValue / this.availableBalance) * 100;
    if (this.progressBarPercentage > 100) {
      this.progressBarPercentage = 100;
    }
    if (this.progressBarPercentage < 6) {
      this.progressBarPercentage = 6;
    }
    const formattedValue = this.formatToIndianCurrency(numericValue);
      this.withdrawMoney.get('withdrawAmount')?.setValue('₹' + formattedValue);
  }

  withdrawInputvalue(val: any) {
    this.withdrawMoney.get('withdrawAmount')?.setValue(val.target.value);
  }

  formatToIndianCurrency(value: number): string {
      return value.toLocaleString('en-IN', {style: 'currency', currency: 'INR'})
          .replace('₹', '')
          .replace('.00', '');
  }
  clearIndianCurrency(value: any): any {
    if (value) {
      const cleanedValue = value.toString().replace(/[\D]/g, '');
      const numericValue = Number.parseInt(cleanedValue, 10);
      if (Number.isNaN(numericValue)) return 0
      return numericValue;
    }
    return 0
  }

  goToBankPaymentMethod(id: string) {
    this.startTimer();
    this.paymentgatewayaccount(id);
    this.isLoader=true;
    setTimeout(() => {
      if (!this.paymentGateWayOpt) {
    this.paymentmethods = true;
    this.bankPaymentMethod = false;
    this.utrnopage = true;
    this.isBankPay = true;
    this.stepperValue = 3;
        this.isLoader=false;
  }
    }, 1000);
  }

  redirectToPaymentGateway() {
    const memberCode = localStorage.getItem('Member_code')
    const amount = this.depositaddmonys.get('depositamount').value.slice(1)
    const data = {
      "pg": this.paymentGatewayApiData?.name, // name of the gateway
      "firstname": memberCode, // membercode
      "email": memberCode + "@gmail.com", // memberCode + "@gmail.com"
      "phone": "1812121212", // fix this number
      "amount": amount, // amount
      "gatewayId": this.paymentGatewayApiData?.id ,// gateway ID found in gateway list
      "promoCode": this.promoId
    }
    this.myprofileServices?.redirectToPaymentGateway(data).subscribe(
      (res) => {
        if (res) {
          if (res.success === false) {
            this.toastr.error(res?.error || 'Something went wrong');
            this.isLoader = false;
          } else {
          const redirectUrl = res?.result?.redirectURL
            // this.trackingDepositPixelService();
          localStorage.setItem('paymentGateWayTrxnId', res?.result?.transactionId);
          if (redirectUrl) {
            window.location.replace(redirectUrl);
            this.isLoader = true;
          } else {
            this.isLoader = false;
          }
        }
        }
      },
      (err) => {
        console.error(err);
        this.toastr.error(err.error.error || 'Something went wrong');
        this.isLoader = false;
      }
    )
  }

  gotoutrnopage() {
    this.bankPaymentMethod = true;
    this.paymentmethods = true;
    // this.utrnopage = false;
    this.depositeamount = true;
    this.bankTransfer = false;
    this.showReceiptUpload('IMPS');
    this.stepperValue = 3;
    this.increaseStepper(4);
  }

  goToUpiPage(id: any) {
    //start timer of 45 minutes
    this.startTimer();
    this.paymentgatewayaccount(id);
    this.paymentmethods = true;
    this.utrnopage = false;
    // this.bankTransfer = false;
    this.bankPaymentMethod = true;
    this.depositeamount = true;
    this.isBankPay = false;
    this.showReceiptArea = '';
    this.stepperValue = 3;
    // Scroll to the top of the page
    // topOfPage.scrollIntoView({ behavior: 'smooth' });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  startTimer() {
    this.seconds = 59;
    this.minutes = 44;
    if (this.upiPaymentTimer) clearInterval(this.upiPaymentTimer);
    this.upiPaymentTimer = setInterval(() => {
      if (this.seconds > 0) {
        this.seconds--;
      } else if (this.minutes > 0) {
        this.minutes--;
        this.seconds = 59;
      } else {
        //if timer is completed then redirect to deposit page
        clearInterval(this.upiPaymentTimer);
        this.tabshow('deposit');
      }
    }, 1000);
    // console.log('timer started', this.minutes, this.seconds);
  }

  backtodepositeamout() {
    this.paymentmethods = true;
    this.depositeamount = false;
    this.utrnopage = true;
    this.stepperValue = 1;
  }


  backToBankTransfer() {
    //reset depositaddmonys.get('utrnumber') value
    this.depositaddmonys.get('utrnumber')?.setValue('');
    this.closeImage();
    this.stepperValue = 3;
    //removed touched from utrnumber
    this.depositaddmonys.get('utrnumber')?.markAsUntouched();
    //remove dirty
    this.depositaddmonys.get('utrnumber')?.markAsPristine();
    if (this.isBankPay) {
      this.bankPaymentMethod = false;
      this.bankTransfer = true;
      this.utrnopage = true;
    } else {
      this.utrnopage = false;
      this.bankPaymentMethod = true;
      this.bankTransfer = true;
    }

  }


  backToPaymentMethod() {
    this.depositeamount = true;
    this.paymentmethods = false;
    this.utrnopage = true;
    this.bankPaymentMethod = true;
    this.utrnopage = true;
    this.selectedUpiMethod = null; //reset selected upi method
    this.stepperValue = 2; //reset stepper value
    //reset timer
  }

  increaseStepper(value: number) {
    //only increase stepper if value is valid && method is imps
      //  if (this.depositaddmonys.controls['depositamount'].valid &&
      //  this.accountdatashow?.method === 'IMPS') {
    this.stepperValue = value;
  }


  goToBankTransfer() {
    this.paymentmethods = true;
    this.bankPaymentMethod = true;
    this.bankTransfer = false;
    this.utrnopage = true;
    this.depositeamount = true;
    this.selectedUpiMethod = null;
    this.increaseStepper(4);
  }


  showReceiptUpload(method: string) {
    if (method == 'NEFT') {
      this.showReceiptArea = 'NEFT';
      this.showSvg = true;
      this.imagePath = '';
      this.showImg = false;
    }
    if (method == 'RTGS') {
      this.showReceiptArea = 'RTGS';
      this.showSvg = true;
      this.imagePath = '';
      this.showImg = false;
    }
    if (method == 'IMPS') {
      this.showReceiptArea = 'IMPS';
      this.showImg = false;
    }
  }

  closeInfoPage() {
    this.showHowTo2 = false;
    this.showHowTo = false;
  }

  gotoMainPageHome() {
    this.router.navigate(['/']);
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }


  openHowToDeposit() {
    this.howDepositLangPopup = true;
    const video = document.getElementsByClassName('video') as HTMLCollectionOf<HTMLElement>;
    if (video) {
      // console.log('video', video);
    }
  }

  closeVideoPopup() {
    this.howToDeposit = false;
    this.howDepositLangPopup = false;
    this.howToDepositHindi = false;
  }

  englishVideoPopup(){
    this.howToDeposit = true;
    this.howDepositLangPopup = false;
  }

  hindiVideoPopup(){
    this.howToDepositHindi = true;
    this.howDepositLangPopup = false;
  }

  goBack() {
    //check for source in query params url
    const source = this.route.snapshot.queryParams['source'];
    if (source) {
      window.history.back();
    } else {
      this.router.navigate(['/']);
      this.myprofileServices.updateActiveMenuItem('/main');
    }
  }

  // trackingDepositPixelService() {
  //   try {
  //     const pixelIds = ["448953950813931"];
  //     for (let i = 0; i < pixelIds.length; i++) {
  //       if (i==0) {
  //         window.fbq('init', pixelIds[i]);
  //         window.fbq('track', 'Purchase');
  //       }else{
  //         window[`fbq${i}`]('init', pixelIds[i]);
  //         window[`fbq${i}`]('track', 'Purchase');
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  closeKycPopup() {
    this.authservice.versionPopUpOpen = false;
  }

  clickOpenIntercom() {
    const intercomContainer = document.getElementById("intercom-container");
    // @ts-ignore
    window.Intercom('show');
    if (intercomContainer) {
      this.renderer.setStyle(intercomContainer, 'display', 'block', RendererStyleFlags2.Important);
    }
    this.intercomBtn = true;
  }

  clickCloseIntercom() {
    const intercomContainer = document.getElementById("intercom-container");
    // @ts-ignore
    window.Intercom('hide');
    if (intercomContainer) {
      this.renderer.setStyle(intercomContainer, 'display', 'none', RendererStyleFlags2.Important);
    }
    this.intercomBtn = false;
  }

  private setupIntercomInterval() {
    const interval = setInterval(() => {
      const intercomContainer = document.getElementById("intercom-container");
      const intercomContainerClass = document.getElementsByClassName("intercom-lightweight-app")[0];
      if (window.location.href.includes('intercom')){
      // @ts-ignore

        window.Intercom('show')
      // @ts-ignore

        console.log(' window.Intercom',  window.Intercom)
        clearInterval(interval);
      } else if (intercomContainer || intercomContainerClass) {
        clearInterval(interval);
        this.intercomRender();
      }
    }, 1000);
  }
  intercomRender() {
    this.clickOpenIntercom()
    this.clickCloseIntercom()
  }

  trackInitiateCheckoutService(memberCode : string, loginName: string) {
    try {
      const pixelId = ["448953950813931","900978155047808","1026321468616181","974838613755656",
        "586379523499553","2706155189693721","1190972091651303","832160437968868","990285032032635",
        "2706155189693721","900978155047808","678060997780292","453544397402656"];
      var email = loginName.includes("@") ? loginName : '';

   var phoneNumber = loginName.replace('_','');
      for (let i = 0; i < pixelId.length; i++) {
        if (i == 0) {
          window.fbq('init', pixelId[i], {
            "ph": phoneNumber || '',
            "external_id": memberCode || '',
            'em': email || ''

          });
        } else {
          window.fbq('init', pixelId[i]);
        }
      }
      window.fbq('track', 'InitiateCheckout');
    } catch (error) {
      console.error(error);
    }
  }

  trackInitiateCheckout() {

    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const memberCode = userData?.memberCode || '';
    const loginName = userData?.loginName || '';

    this.trackInitiateCheckoutService(memberCode, loginName);
  }
}
