import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import * as Sentry from "@sentry/angular-ivy";

// Sentry.init({
//   dsn: "https://1e06b2cc3a188e1454e886212665a308@o4507077972459520.ingest.us.sentry.io/4507077978488832",
//   ignoreErrors:["Unhandled Promise rejection: OneSignal:", /OneSignal/i],
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", "11exch.com", "7exch.com/api"],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

if (environment.production) {
  enableProdMode();
}

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/assets/api.worker.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    console.debug("!!!!!!!!!!!!!!!!", err)
    console.error(err)
  }
  );
