import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';
import { SportsbookService } from 'src/app/services/sportsbook.service';
import { AuthService } from '../../services/auth.service';
import {
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { LandingService } from 'src/app/services/landing.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { EventService } from 'src/app/services/broadcast.service';
import { CasinoService } from 'src/app/services/casino.service';
import { environment } from 'src/environments/environment';
import { NumericFieldValidator } from 'src/app/authentication/validations/validations.validator';
import { Location } from '@angular/common';
// import { PixelModule, PixelService } from 'ngx-multi-pixel';
import { BonusService } from '../bonuses-cards/bonus.service';
import { GtmService } from "ngx-gtm";
import { TranslateService } from '@ngx-translate/core';
import { NotificationResponse } from 'src/app/models/notification.interface';

declare var google: any;

declare var $;

@Component({
  selector: 'app-header',
  // host: {
  //   '(document:click)': 'onClick($event)',
  //  },
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  howToDownloadAPK: boolean = false;

  none: any = 'flex';
  showBalance: any = false;
  stack1: number = 0;
  stack2: number = 0;
  stack3: number = 0;
  stack4: number = 0;
  editMode: boolean = false;

  isBonus: boolean = false;
  menushow: any = false;
  balance: any;
  siginpopup: any = true;
  frmUname: boolean = true;
  frmSpass: boolean = true;
  frmRPhone: boolean = true;
  frmRPass: boolean = true;
  frmRcPass: boolean = true;
  frmRgetOtp: boolean = true;
  frmfPhone: boolean = true;
  userId: boolean = false;
  frmfPass: boolean = true;
  frmfcPass: boolean = true;
  frmfgetOtp: boolean = true;
  recaptcha: any;
  rSiteKey: string = "";
  version: string = localStorage?.getItem("versionNow");
  versionPopUpOpen: boolean = false;
  token: any;
  interval;
  aTag;
  showsmartpanel: any = false;
  windowshowhide: any = true;
  downloadAppPopup: boolean = false;
  isAndroid: boolean = false;
  isIOS: boolean = false;
  showIosInstruction: boolean = false;
  userform: any = true;

  setting: any = true;
  public errorMsg: string;
  public ferrorMsg: string;
  public notifyMsg: string = '';
  public RerrorMsg: string;
  signinhide: any = 'block';
  public form: FormGroup;
  public form1: FormGroup;
  notificationshow: any = true;
  stars: any = true;
  betslips: any = true;

  istoken: any;
  public data3: any;
  public signinshowhide: any = true;
  registershowhide: any = true;
  showhide: any = true;
  showsign: any = true;
  bWallet: boolean = false;
  showReg: any = false;
  showfor: any = false;
  showResnd: boolean = false;
  showOtp: boolean = true;
  public interval300: any;
  livesubmenushow: any = true;
  livecasinosubmenushow: any = true;
  sportssubmenushow: any = true;
  livesubmenushowhide: any = true;
  casinosubmenushow: any = true;
  balanceamounts: any = {};
  bonusamout: any = {};
  typeWallet: any
  @ViewChild('captchaRef') captchaRef: any;
  @ViewChild('captchaRef1') captchaRef1: any;
  favCount: any;
  gamepath = environment.gamespath;
  myCardPromo: any = [];
  balanceshow: any = true;
  showLoading = true;
  favouriteimage: any = [];
  gamelist: any = [];
  isLoader = false;
  username = localStorage.getItem("User_name");
  isMobile: boolean = false;
  urlIframe: string = '';
  showSignInPopup: boolean = false;
  animateWindow: boolean = false;
  showNotifiction: boolean = false;
  formsNames = {
    phoneNumber: 'phoneNumber',
    otp: 'otp',
    password: 'password',
    confirmPassword: 'confirmPassword',
  };
  private urlIframeSubscription: Subscription;

  loggerUsername: string = '';
  depositBtn: boolean = false;

  mailLoginName: string = '';
  loginName: boolean = false;
  langPopup: boolean = true;
  activeLang: string = 'English';
  multiLang: boolean = false;
  showDownloadAppPopup: boolean = false;
  howToDownloadVideoPopup: boolean = false;
  showActiveLang: any;
  showLang: boolean = false;
  activeCheckMark: boolean = false;
  langSelectCard: boolean = false;
  showApplyButton: boolean = false;



  langs = [
    { 'name': 'English', 'value': 'en' },
    { 'name': 'हिन्दी', 'value': 'hi' },
    { 'name': 'ગુજરાતી', 'value': 'gu' },
    { 'name': 'मराठी', 'value': 'mr' },
    { 'name': 'தமிழ்', 'value': 'ta' },
    { 'name': 'తెలుగు', 'value': 'te' },
    { 'name': 'ಕನ್ನಡ', 'value': 'kn' },
    { 'name': 'മലയാളം', 'value': 'ml' },
    { 'name': 'বাংলা', 'value': 'bn' },
    { 'name': 'ਪੰਜਾਬੀ', 'value': 'pa' }
  ];

  currentUrl = "";
  menuItems = [ // [queryParams]="{ competitionName: comp?.altName }"
    // { id: 'kabaddi', name: 'Kabaddi', url: '' },
    // { id: "politics", name: 'Politics', url: '2378962' },
    { id: "aviator", name: 'Aviator', url: '/shared/blastOff' },
    { id: "teenpatti", name: 'Teenpatti', url: '/shared/predictor' },
    { id: "live-casino", name: 'Live Casino', url: '/shared/livecasino' },
    { id: "sports", name: 'Sports', url: '/shared/sport' },
    { id: "sportsBook", name: 'Sportsbook', url: '/shared/sportsbook' },
    { id: "virtual-sports", name: 'Virtual Sports', url: '/shared/virtulsports' },
    { id: "casino", name: 'Casino', url: '/shared/casino' },
    { id: "poker", name: 'Poker', url: '/shared/poker' },
    { id: "games", name: 'Games', url: '/shared/games' },
    { id: "roulette", name: 'Non-stop Roulette', url: '/shared/nonstproulette' },
    { id: "dreamWheel", name: 'Dreamwheel', url: '/shared/dreamwheel' },
    { id: "horseRacing", name: 'Horse Racing', url: '/shared/sport' },
    { id: "greyHoundRacing", name: 'Grey Hound Racing', url: '/shared/sport' },
  ]

  activeMenuItems = new Array(this.menuItems.length).fill(false);
  activeLanguage = new Array(this.langs.length).fill(false);
  transactionId: string = '';

  constructor(
    public router: Router,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private loginshowhide: SportsbookService,
    public authService: AuthService,
    private favraite: LandingService,
    private casinoServices: CasinoService,
    public myprofile: MyprofileService,
    private eventService: EventService,
    // private pixel: PixelService,
    private gtm: GtmService,
    private route: ActivatedRoute,
    public _bonusService: BonusService,
    private translater: TranslateService

  ) {
    const activeLang = localStorage.getItem('lang');
    // console.log(activeLang);
    //remove string from activeLang
    this.changeLanguage(activeLang ? activeLang : 'en');

    this.router.events.subscribe((event) => {
      this.checkDownloadPopup();
      if (event instanceof NavigationEnd) {
        this.checkUrl(event.urlAfterRedirects);
        if (event.url.includes('register')) {
          this.registershow();
        }
        if (event.url.includes('login')) {
          this.signinshow();
        }
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  checkUrl(url: string): void {
    if (url.includes('register')) {
      this.registershow();
      this.location.replaceState(url.split('#')[0]);
    }
    if (url.includes('login')) {
      this.signinshow();
      this.location.replaceState(url.split('#')[0]);
    }
  }

  getToken() {
    this.getUserName();
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  getUserName() {
    if (localStorage.getItem('ROYALTOKEN_KEY')) {
      const user = localStorage.getItem("User_name");
      const mailLoginName = localStorage.getItem('loginName');
      if (user) {
        const pattern = /^91_\d{10}$/;
        if (pattern.test(user)) {
          this.loggerUsername = this.formatPhoneNumberWithCountryCode(user);
          this.depositBtn = true;
        } else {
          this.loggerUsername = user;
          this.depositBtn = false;
        }
      } else if (mailLoginName) {
        this.loggerUsername = mailLoginName;
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (emailPattern.test(this.loggerUsername)) {
          this.depositBtn = true;
        }
      }
    }
  }

  openLang() {
    this.multiLang = !this.multiLang;
  }

  closeLangPopup() {
    this.langPopup = false;
  }

  changeLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this.translater.use(lang);
    for (let i = 0; i < this.langs.length; i++) {
      if (this.langs[i].value === lang) {
        this.activeLang = this.langs[i].name;
        this.activeLanguage[i] = true;
        for (let j = 0; j < this.langs.length; j++) {
          if (j !== i) {
            this.activeLanguage[j] = false;
          }
        }
      }
    }
    return this.activeLang;
  }

  formatPhoneNumberWithCountryCode(inputNumber: any) {
    if (inputNumber.startsWith('b06.')) {
      inputNumber = inputNumber.replace('b06.', '');
    }
    const countryCode = inputNumber.substring(0, 2);

    const phoneNumber = inputNumber.substring(2).replace(/[^0-9]/g, '');

    if (!phoneNumber.startsWith('+' + countryCode)) {
      return '+' + countryCode + phoneNumber;
    }
    return '+' + countryCode + phoneNumber;
  }

  getMember() {
    return localStorage.getItem('Member_code');
  }
  ngOnInit(): void {


    if (localStorage.getItem('paymentGateWayTrxnId')) {
      this.route.queryParams.subscribe(params => {
        this.transactionId = params['txnId'];
        localStorage.setItem('paymentGateWayTrxnId', this.transactionId);
        if (this.transactionId) {
          this.router.navigate(['depositwithdrawreport']);
        }
      });
    }
    //get element by class name "intercom-lightweight-app"
    const intercom = document.getElementsByClassName('intercom-lightweight-app')[0];
    //if element is present then remove it
    if (intercom) intercom.remove();
    this.checkIfMobile();
    // try {
    //   this.gtm.push({event: 'PageView'});
    //   this.gtm.push({'pageLoad' : 'Home_page' })

    //   this.pixel.initialize(["2706155189693721", "1190972091651303", "586379523499553", "832160437968868"]);
    //   this.pixel.trackCustom("PageView");

    //   window.fbq('init', '974838613755656');
    //   window.fbq('track', 'PageView');

    //   window.fbq1('init', '2706155189693721');
    //   window.fbq1('track', 'PageView');

    //   window.fbq2('init', '1190972091651303');
    //   window.fbq2('track', 'PageView');

    //   window.fbq3('init', '586379523499553');
    //   window.fbq3('track', 'PageView');

    //   window.fbq4('init', '832160437968868');
    //   window.fbq4('track', 'PageView');

    //   window.fbq5('init', '990285032032635');
    //   window.fbq5('track', 'PageView');

    // } catch (e) {
    //   console.log("pixel error header", e)
    // }
    // this.glogin();
    if (this.getToken() && this.getMember()) {
      this.balancamount();
      this.notificationMess();
      // this.getConfigData();
    }
    this.checkActiveMenuItem();
    // if(this.authService.activeMenuItemId=="sports"){
    //   this.scrollToElementById(this.authService.activeMenuItemId);
    //   console.log(this.authService.activeMenuItemId,"header");
    // }
    this.isIOS = navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('iPad') > -1 || navigator.userAgent.indexOf('iPod') > -1 && navigator.userAgent.indexOf('chrome') == -1;
    this.isAndroid = navigator.userAgent.indexOf('Android') > -1;

    const downloadAppPopupExpTimeStr = localStorage.getItem('installPromptExp');
    const expTime = downloadAppPopupExpTimeStr ? parseInt(downloadAppPopupExpTimeStr) : 0;
    this.downloadAppPopup = expTime < Date.now();


    // this.getConfigData();
    this.authService.googleConfigObservable$.subscribe((config: any | null) => {
      if (!config) {
        this.getConfigData();
      }
    })

    this.getPromocode();
    // this.route.queryParams.subscribe((queryParams) => {
    //   const gameId = queryParams['game_id'];
    //   console.log('Game ID from query parameter:', gameId);
    // });
    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => {
      this.urlIframe = url;
    });

    this.favraite.favouriteimage.subscribe((res) => {
      this.favouriteimage = res;
      this.favouriteimage = JSON.parse(localStorage.getItem('gamelist'));
      this.favCount = this.favouriteimage.length;
    });

    // console.log(this.favCount,this.favouriteimage.length);

    // this.favCount = ' ';
    //  this.favCount = localStorage.getItem("gamelist").length;
    //  console.log(this.favCount);

    this.createForm();

    this.loginshowhide.signinshowhide.subscribe((res) => {
      this.signinshowhide = res;
    });
    //this.balanceshow = localStorage.getItem("balanceshow") && localStorage.getItem("balanceshow") == 'true' ? true :false;
    if (localStorage.getItem('balanceshow') == 'true') {
      this.balanceshow = true;
    } else if (localStorage.getItem('balanceshow') == null) {
      this.balanceshow = true;
    } else {
      this.balanceshow = false;
    }
    setTimeout(() => this.showLoading = false, 1000)
    this.gameinfavlist();
    this.myprofile.getBalanceData().subscribe((data) => {
      this.updateBalance(data)
    })

    const timer = setTimeout(() => {
      let balanceShow = localStorage.getItem('showBalance')
      if (balanceShow === 'true') {
        this.showBalance = false;
      } else if (balanceShow === 'false') {
        this.showBalance = true;
      } else {
        this.showBalance = true;
      }
    }, 100);
  }

  checkActiveMenuItem() {
    this.authService.activeMenuItemIdObservable$.subscribe((res) => {
      // console.log("this is from checkActiveMenuItem", res);
      if (res) {
        this.scrollToElementById(res);
      }
    });
  }


  checkDownloadPopup() {
    if (this.router.url == '/' || this.router.url == '/main') {
      this.showDownloadAppPopup = true;
    } else {
      this.showDownloadAppPopup = false;
    }
  }

  // checkForActiveMenuItems() {
  //   this.menuItems.forEach((item, index) => {
  //     if (this.router.url.includes(item.url)) {
  //       this.activeMenuItems[index] = true;
  //       const container = document.getElementsByClassName('navBar-scrollable');
  //       const element = document.getElementById(item.id);
  //       if (container && element) {
  //         console.log("this is from checkForActiveMenuItems",item.id);
  //         // container[0].scrollTo(element.offsetLeft, 0);
  //       }
  //     }
  //   });
  // }

  // subscribeForUpdateMenuItems() {
  //   // console.time('activeMenuItem');
  //   this.myprofile.activeMenuItem.subscribe((res: any) => {
  //     // Common code for resetting active menu items
  //     // this.activeMenuItems = new Array(this.activeMenuItems.length).fill(false);

  //     switch (res) {
  //       case '/bethistory':
  //       case '/main':
  //         break;
  //       case '/shared/sport':
  //         // this.activeMenuItems[5] = true;
  //         this.scrollToElementById('sports');
  //         break;
  //       case '/shared/casino':
  //         // this.activeMenuItems[8] = true;
  //         // this.scrollToElementById('casino');
  //         break;
  //     }
  //   });
  //   // console.timeEnd('activeMenuItem');
  // }
  count: number = 0;
  scrollToElementById(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    } else {
    }
  }

  updateSportsID(id: any) {
    localStorage.removeItem('sportsId');
    localStorage.setItem('sportsId', id);
    this.authService.sportsId = id;
    this.authService.updateSportsId(this.authService.sportsId);
    this.router.navigate(['/shared/live/' + id]);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024; // Пороговое значение ширины для мобильных устройств
  }
  clearIframe() {

    this.casinoServices.clearCasinoURL()
    if (this.router.url.includes('blastOff') || this.router.url.includes('teenPatti')) {
      this.router.navigate(['/main']);
      this.casinoServices.clearCasinoURL();
      this.authService.updateSportsId(this.authService.sportsId);
      this.authService.updateActiveMenuItem('main');
    }

  }

  checkButtonsRender() {
    if (this.isIFrame() || this.getToken()) {
      return false
    }
    return true
  }

  ngAfterViewInit() {
    this.walogin(false);
    this.checkUrl(this.currentUrl);
    // this.checkForActiveMenuItems();
    // this.checkActiveMenuItem();
  }

  clearAutocomplete() {
    this.form1.reset();
    this.form.reset();
    const inputs = document.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].setAttribute('value', '');
    }
  }

  walogin(byClick = true) {
    const url =
      `https://betkings.authlink.me/?redirectUri=` + window.location.origin;
    if (byClick) {
      window.location.href = url;
    }
  }
  isIFrame() {
    return this.urlIframe && this.isMobile;
  }

  getPromocode() {
    this.favraite.getPromotions().subscribe((res: any) => {
      this.myCardPromo = res?.result;
    });
  }

  gameinfavlist() {
    this.gamelist = JSON.parse(localStorage.getItem('gamelist'));
    // this.casinoServices.favouritegame(this.gamelist)
  }
  notificationMess() {
    this.authService.getNotification().subscribe(
      (res: NotificationResponse) => {
        if (res?.result?.length > 0) {
          this.showNotifiction = true;
          this.notifyMsg = res?.result[0]?.text;
        }
      },
      (error) => {
        console.error(error)
      }
    );
  }


  resolved(captchaResponse: string) {
    // console.log('Inside resolved of header component');
    this.recaptcha = captchaResponse;
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    // console.log(this.recaptcha, "recaptcha");
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  async executeRecaptchaAsync() {
    this.captchaRef.execute();
    return new Promise((resolve) => {
      this.resolved = (captchaResponse: string) => {
        this.recaptcha = captchaResponse;
        resolve(captchaResponse);
      };
    });
  }


  footermenubarshow() {
    this.menushow = !this.menushow;
  }

  createForm() {
    this.form = this.formBuilder.group({
      // username: ['', [Validators.required]],
      username: [
        '',
        Validators.compose([
          Validators.required,
          NumericFieldValidator.ValidNumericField,
          Validators.pattern(
            /^(?:(?:\[1-9]{1,4}[ \-]*)|(?:\([0-9]{2,3}\)[ \-]*)|(?:[0-9]{2,4})[ \-]*)*?[0-9]{7,}[ \-]*[0-9]{3,}$/
          ),
        ]),
      ],
      // username: ['',Validators.compose([NumericFieldValidator.ValidNumericField])],
      username1: ['', Validators.pattern(/^[a-zA-Z0-9]{3,20}$/)],
      password: [
        '',
        [Validators.compose([Validators.required, Validators.minLength(6)])],
      ],
      platform: ['web'],
      //systemId: ['', [Validators.required]],
      //recaptcha: ['']
    });
    this.form1 = this.formBuilder.group({
      code: ['', [Validators.required]],
      // phoneNumber: ['', [Validators.required]],
      // phoneNumber: ['',Validators.compose([Validators.required,NumericFieldValidator.ValidNumericField, Validators.pattern(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/)] )],
      phoneNumber: [
        '',
        Validators.compose([
          Validators.required,
          NumericFieldValidator.ValidNumericField,
          Validators.pattern(
            /^(?:(?:\[1-9]{1,4}[ \-]*)|(?:\([0-9]{2,3}\)[ \-]*)|(?:[0-9]{2,4})[ \-]*)*?[0-9]{7,}[ \-]*[0-9]{3,}$/
          ),
        ]),
      ],
      pass: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/])[a-zA-Z\d!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/]+$/
          ),
        ],
      ],
      cpass: ['', [Validators.required]],
      getOTp: [
        '',
        Validators.compose([
          Validators.required,
          NumericFieldValidator.ValidNumericField,
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
      ],
      platform: ['web'],
      //systemId: ['', [Validators.required]],
      // recaptcha: ['']
    });
  }


  hide() {
    this.none = 'none';
    this.livesubmenushow = false;
    this.livecasinosubmenushow = true;
    this.sportssubmenushow = true;
    this.casinosubmenushow = true;
  }
  show() {
    this.none = 'flex';
    this.livesubmenushow = true;
    this.livecasinosubmenushow = true;
    this.sportssubmenushow = true;
    this.livesubmenushowhide = true;
    this.casinosubmenushow = true;
    this._bonusService.showAllBonuses = false;
  }

  openSign(e: any) {
    e.stopPropagation();
    //console.log('Inside openSign of header component');
    if (this.getToken()) {
      //  console.log('Inside if condition openSign of header component');
      this.router.navigate(['/balancemanagement/Deposit']);
    } else {
      document.getElementById('demo').click();
    }
  }

  signinshow() {
    // console.log('clicked sign in');
    this.myprofile.showFooter = false;
    this.showSignInPopup = true;
    // window.navigator.vibrate(50); // vibrate for 50ms
    document.body.classList.add('openpopup');
    if (document.getElementById('close_bet')) {
      document.getElementById('close_bet').click();
    }
    this.ferrorMsg = '';
    this.RerrorMsg = '';
    this.errorMsg = '';
    this.showsign = false;
    setTimeout(() => {
      this._bonusService.showAllBonuses = false;
      this.showsign = true;
    }, 100);
    this.showfor = false;
    setTimeout(() => {
      this.clearAutocomplete();
    }, 500);
    this.frmUname = true;
    this.frmSpass = true;
    this.frmRPhone = true;
    this.frmRPass = true;
    this.frmRcPass = true;
    this.frmRgetOtp = true;
    this.frmfPhone = true;
    this.frmfPass = true;
    this.frmfcPass = true;
    this.frmfgetOtp = true;
    this.signinshowhide = false;
    this.registershowhide = true;
    this.windowshowhide = false;
    this.showReg = false;
    this.pauseTimer();
    this.showResnd = false;
    this.showOtp = true;
    this.signinhide = 'block';
    setTimeout(() => {
      this.ngOnInit();
      this.captchaRef?.execute();
    }, 100);
  }


  signIn(dataProps = null) {
    let data = null;
    if (dataProps) {
      data = dataProps;
      data.recaptchaResponse = this.recaptcha;
    } else {
      data = this.getSignFormData();
    }
    if (this.checkDataToSignIn(data, dataProps)) {
      this.authService.Login(data).subscribe(
        (res: any) => {
          //debugger
          this.token = res.headers.get('authorization');
          if (res.body.status.statusCode == '0') {
            this.router.navigate(['/shared/promotions']);

            window.srtmCommands.push({
              event: "track.user.login",
              payload: {
                action: "complete",
                userId: res?.body?.result?.memberCode
              }
            });
            this.token = res.headers.get('authorization');
            this.showsign = false;
            this.errorMsg = 'Login successfully';
            document.body.classList.remove('openpopup');
            localStorage.setItem('ROYALTOKEN_KEY', JSON.stringify(this.token));
            localStorage.setItem('userData', JSON.stringify(res.body.result));
            localStorage.setItem('Member_code', res.body.result.memberCode);
            localStorage.setItem('User_name', res.body.result.loginName);
            localStorage.setItem('WaLink', res?.body?.result?.wa || 'https://walink.live/betkings');
            localStorage.setItem(
              'betstack',
              res?.body?.result?.settings.presetStakeSettings?.settings
            );
            try {
              var loginNameInter = res.body.result.loginName;
              if (loginNameInter.includes('.')) {
                loginNameInter = loginNameInter.split('.')[1];
              }
              if (loginNameInter.includes('_')) {
                loginNameInter = loginNameInter.split('_')[1];
              }
              (window as any).intercomSettings = {
                api_base: 'https://api-iam.intercom.io',
                branch_id: res?.body?.result?.branchId || 100111,
                branch_name: res?.body?.result?.branchName || "Not updated yet",
                app_id: 'n3wkvmsn',
                name: loginNameInter,
                user_id: res.body.result.memberCode,
              };
              (window as any).Intercom('update');
            } catch (e) {
              console.log(e);
            }
            this.cdr.detectChanges();
            this.router.navigate(['/main']);
          }
        },
        (error) => {
          setTimeout(() => {
            this.captchaRef?.execute();
          }, 100);
          this.errorMsg = JSON.stringify(error.error.error);
          this.authService.showError(this.errorMsg);
        }
      );
    } else {
      this.frmSpass = this.frmPassword.valid;
    }
  }

  checkDataToSignIn(data, dataProps) {
    if (data.password && data.username) {
      return true;
    }
    if (
      !dataProps &&
      this.recaptcha &&
      data &&
      data.username &&
      data.password &&
      !!(this.form.get('password').status === 'VALID')
    ) {
      return this.userform
        ? !!(this.form.get('username').status === 'VALID')
        : !!(this.form.get('username1').status === 'VALID');
    }
    return false;
  }



  pauseTimer() {
    clearInterval(this.interval);
  }


  registershow() {
    // try {
    //   this.pixel.initialize();
    //   this.pixel.trackCustom("Register")

    //   window.fbq('init', '974838613755656');
    //   window.fbq('track', 'Register');

    //   window.fbq1('init', '2706155189693721');
    //   window.fbq1('track', 'Register');

    //   window.fbq2('init', '1190972091651303');
    //   window.fbq2('track', 'Register');

    //   window.fbq3('init', '586379523499553');
    //   window.fbq3('track', 'Register');

    //   window.fbq4('init', '832160437968868');
    //   window.fbq4('track', 'Register');

    //   window.fbq5('init', '990285032032635');
    //   window.fbq5('track', 'Register');

    // } catch (e) {
    //   console.log("pixel error Register start", e)
    // }

    // const fb =  document.getElementById('fb-fire-pixel');

    // // @ts-ignore
    // fbq('track', 'Register 11')

    // // @ts-ignore
    // window.fbq('track', 'Register');


    this.generateFormNames();
    this.showSignInPopup = true;
    document.body.classList.add('openpopup');
    if (document.getElementById('close_bet')) {
      document.getElementById('close_bet').click();
    }
    this.siginpopup = false;
    this.signinshowhide = false;
    this.registershowhide = true;
    this.showReg = true;
    this.windowshowhide = false;
    this.signinhide = 'none';
    this.showResnd = false;
    this.showOtp = true;
    this.pauseTimer();
    this._bonusService.showAllBonuses = false;
    setTimeout(() => {
      this.captchaRef?.execute();
    }, 1000);
    setTimeout(() => {
      this.clearAutocomplete();
    }, 800);
  }
  hidesigninRegister() {
    this.myprofile.showFooter = true;
    this.animateWindow = true
    const timer = setTimeout(() => {
      document.body.classList.remove('openpopup');
      this.showSignInPopup = false;
      this.generateFormNames();
      this.registershowhide = true;
      this.signinshowhide = true;
      this.showReg = false;
      this.form1.reset();
      this.form.reset();
      this.frmUname = true;
      this.frmSpass = true;
      this.frmRPhone = true;
      this.frmRPass = true;
      this.frmRcPass = true;
      this.frmRgetOtp = true;
      this.frmfPhone = true;
      this.frmfPass = true;
      this.frmfcPass = true;
      this.frmfgetOtp = true;
      this.animateWindow = false;
      setTimeout(() => {
        this.clearAutocomplete();
      }, 800);
    }, 300);
    return () => clearTimeout(timer);
  }

  generateRandomString(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    let randomString = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }
  generateFormNames() {
    this.formsNames = {
      phoneNumber: this.generateRandomString(10),
      otp: this.generateRandomString(10),
      password: this.generateRandomString(10),
      confirmPassword: this.generateRandomString(10),
    };
  }


  betslip() {
    this.betslips = !this.betslips;
    this.showsmartpanel = false;
  }


  gotohome() {
    document.body.classList.remove('openpopup');

    this.stars = true;
    // this.notificationshow = true;
    this.setting = true;
    // this.notificationshow = true;
    this.myprofile.updateActiveMenuItem('/main');
    this.router.navigate(['/main']);
  }



  getSignFormData() {
    const data = this.form.value;
    if (!this.userform) {
      this.data3 = {
        username: data.username1,
        password: data.password,
        recaptchaResponse: this.recaptcha,
      };
    } else {
      this.data3 = {
        username: data.username ? '91_' + data.username : null,
        password: data.password,
        recaptchaResponse: this.recaptcha,
      };
    }

    // console.log('Inside getSignFormData of signup component value of res is:' + JSON.stringify(data1));
    return this.data3;
  }

  updateBalance(data: any) {
    this.bonusamout = data?.result?.bWallet;
    if (
      this.balanceamounts &&
      this.balanceamounts.lastActivityDate &&
      this.balanceamounts?.lastActivityDate !==
      data?.result?.lastActivityDate
    ) {
      this.eventService.emitEvent('retrivePNL');
    }

    this.balanceamounts = data?.result;
    this.balance = data?.result;
    this.isBonus = false;
    // if (data?.result?.useBWallet == true) {
    //   this.balanceamounts = data.result.bWallet;
    //   this.bWallet = true;
    //   this.typeWallet = 'B';
    //   this.isBonus = true;
    // } else if (data?.result?.useSWallet == true) {
    //   this.balanceamounts = data.result.sWallet;
    //   this.typeWallet = 'S';
    //   this.isBonus = true;
    // } else if (data?.result?.useCWallet == true) {
    //   this.balanceamounts = data.result.cWallet;
    //   this.typeWallet = 'C';
    //   this.isBonus = true;
    // } else {
    //   this.balanceamounts = data.result;
    //   this.typeWallet = 'M';
    // }
    // console.log(this.balanceamounts, "this.balanceamounts");

  }

  balancamount() {
    if (!this.getToken() || !this.getMember()) {
      return
    }

    this.myprofile.balanceamount().subscribe(
      (res) => {
        console.log()
      },
      (error) => {
        if (error.status == 403) {
          localStorage.removeItem('ROYALTOKEN_KEY');
          localStorage.removeItem('Member_code');
          localStorage.removeItem('WaLink');
          this.router.navigate(['/']);
        }
      }
    );

    this.interval300 = setTimeout(() => {
      this.balancamount();
    }, 1400);
  }

  get frmPassword() {
    return this.form.get('password');
  }


  toggleshowhidebtn() {
    // alert("hello")
    // this.showsmartpanel =  false;
  }



  closeVersionPopUp() {
    this.versionPopUpOpen = false;
  }
  getConfigData() {
    // console.log("This is getConfigdata")
    this.authService.getConfig().subscribe(
      (res) => {
        //save res.result to local storage
        localStorage.setItem("configData", JSON.stringify(res?.result));
        this.rSiteKey = res?.result?.recaptchaConfig?.recaptchaSiteKey;
        // console.log(this.rSiteKey);
        localStorage.setItem("rSiteKey", this.rSiteKey);
        this.version = res?.result?.appConfig["1"]?.model?.["betkings-version"]
        if (!localStorage.getItem("versionNow") && this.version) {
          localStorage.setItem("versionNow", this.version)
        } else if (localStorage.getItem("versionNow") != this.version) {
          this.versionPopUpOpen = true
          // localStorage.setItem("versionNow", this.version)
        }
        let interval = setTimeout(() => {
          if (this.captchaRef) {
            this.captchaRef?.execute();
            clearInterval(interval);
          }
        }, 100);
        // console.log(
        //   // 'Inside getConfigData value of this.rSiteKey:' + this.rSiteKey
        // );
      },
      (error) => {
        console.log(error)
      }
    );
  }

  formatAmountWithXs(amount: string): string {
    return amount?.replace(/\d/g, 'X')
  }

  closePopup(perement = false) {
    // after 5 days
    // const expTime = perement ? new Date().getTime() + 432000000000 : new Date().getTime() + 432000000;
    // const expTime = new Date().getTime() + 432000000;
    // after 1 day
    const expTime = new Date().getTime() + 86400000;
    // after 5 years
    // const expTime = new Date().getTime() + 2000000000000;
    localStorage.setItem('installPromptExp', expTime.toString());
  }

  removePopup() {
    this.downloadAppPopup = false;
    this.closePopup(true);
  }

  // goToAPK() {
  //   this.closePopup(true);
  //   this.downloadAppPopup = false;
  //   window.location.href = "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/11EXCH1.0.0.apk";
  // }

  gotoRelatedNavmenu(url: any, index: number, elementId: any) {
    // console.log(url, index, elementId);
    // if (index == 0) {
    //   this.router.navigate(['/shared/sportsbook']);
    //   localStorage.setItem('gameId', 'kabaddi-138');
    //   this.authService.updateSportsId(this.authService.sportsId);
    //   this.authService.updateActiveMenuItem(elementId);
    //   return;
    // }

    if (elementId === 'teenpatti') {
      if (localStorage.getItem('ROYALTOKEN_KEY') == null || !localStorage.getItem('ROYALTOKEN_KEY')) {
        this.signinshow();
        return;
      }
      this.router.navigate(['/shared/livecasino/teenPatti/98789']);
      this.authService.updateSportsId(this.authService.sportsId);
      this.authService.updateActiveMenuItem(elementId);
      return;
    }

    this.authService.updateActiveMenuItem(elementId);
    if (elementId == "horseRacing") {
      this.authService.sportsId = 7;
    } else if (elementId == "greyHoundRacing") {
      this.authService.sportsId = 4339;
    }
    this.authService.updateSportsId(this.authService.sportsId);
    this.activeMenuItems.forEach((item, i) => {
      if (i != index) {
        this.activeMenuItems[i] = false;
      } else {
        this.activeMenuItems[i] = true;
        //scroll to element to left
        const container = document.getElementsByClassName('navBar-scrollable');
        const element = document.getElementById(elementId);
        if (container && element) {
          container[0].scrollTo(element.offsetLeft, 0);
        }
      }
    });
    this.myprofile.updateMenuStatus(false);
    setTimeout(() => {
      if (url == "2378962") {
        this.updateSportsID(url);

        this.authService.sportsId = url;
      } else {
        this.router.navigate([url]);
        this.myprofile.updateActiveMenuItem(url);
      }
    }, 300);
  }

  openMenu() {
    this.myprofile.updateMenuStatus(true);
    this.myprofile.updateActiveMenuItem('/menu');
  }

  closeVideoPopup() {
    this.closePopup(true);
    this.howToDownloadVideoPopup = false;
    // window.location.href = "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/11EXCH1.0.1.apk";
  }

  goToIOS() {
    this.showIosInstruction = true;
    this.closePopup(true);
  }

  whatsappAccountRegistration(e: any) {
    const link = localStorage.getItem('WaLink') || 'https://walink.live/betkings';
    window.open(link, '_blank');
  }

  ngOnDestroy() {
  }

  changeLang(item: string) {
    console.log(item);
  }

  activeClick() {
    this.activeCheckMark = !this.activeCheckMark;
    this.langSelectCard = !this.langSelectCard;
  }
  closeNotify() {
    this.showNotifiction = false
  }

}
