<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>


    </div>
</div>
<div *ngIf="!urlIframe" class="layout-content-holder-bc">
    <app-skeleton *ngIf="!filteredArray" [className]="'skeletonStyles desctop'"></app-skeleton>

    <ngx-slick-carousel class="header-section carousel" #slickModal="slick-carousel" [config]="sliderlConfig">
        <div *ngFor="let SliderImg of filteredArray" ngxSlickItem>
            <img loading="lazy" src="{{SliderImg?.image}}" class="header-img slide" alt="11exch">
        </div>
        <app-skeleton *ngIf="!filteredArray" [className]="'skeletonStyles mobile'"></app-skeleton>
    </ngx-slick-carousel>


    <meta name="description"
        content="Betkings is India's no.1 trusted gaming platform, with  exclusive offer get instant rewards, Play Big Win Big with 500% Deposite Bonus + 5% free Money.">

    <!-- bonuses -->
    <div class="hm-row-bc max-width-550" [ngClass]="{'Modal-background':this._bonusService.showAllBonuses}"
        style="grid-template-columns: 12fr; grid-gap: 0 !important; gap : 0 !important; overflow: hidden;"
        *ngIf="this._bonusService.activeBonus>0 || this._bonusService.showAllBonuses===true">
        <div class="trending-games-title">
            <div class="trending-text">
                <ng-lottie [options]="fireLottieOptions" width="20px" height="20px"></ng-lottie>
                <span> {{'Bonuses'|translate}} </span>
            </div>
            <div (click)="showBonuses()" class="seeallbox">
                <p class="seealltxt">{{'See All'|translate}}</p> <img loading="lazy" class="seeallimg"
                    src="../../../assets/images/fi_8050813.svg">
            </div>
        </div>

        <!-- <div class="container flexbox">

            <app-claim-bonus-card class="bonus-card"></app-claim-bonus-card>
            <app-win-bonus-card class="bonus-card"></app-win-bonus-card>
            <app-no-wagering-claimed class="bonus-card"></app-no-wagering-claimed>
            <app-deposit-now-card2 class="bonus-card"></app-deposit-now-card2>
            <app-active-bonus-card class="bonus-card"></app-active-bonus-card>
            <app-no-more-bonus-card class="bonus-card"></app-no-more-bonus-card>

            <app-bonuses-cards *ngIf="this._bonusService.showAllBonuses"></app-bonuses-cards>
        </div> -->

        <div class="container max-width-550"
            [ngClass]="{'container':this._bonusService.bonuses.length !== 0 && this._bonusService.promotions.length !== 0, 'fixedHeader Modal-Background':myprofileServices.isHeaderFixed && _bonusService.showAllBonuses}"
            *ngIf="!this._bonusService.showAllBonuses">
            <!-- If there is no bonus and promotions -->
            <!-- <app-no-more-bonus-card
                *ngIf="this._bonusService.bonuses.length == 0 && this._bonusService.promotions.length == 0"
                class="bonus-card" style="width: 100%;">
            </app-no-more-bonus-card> -->

            <!-- if bonuses found and all are active -->
            <ng-container *ngIf="this._bonusService.bonuses.length > 0">
                <ng-container *ngFor="let bonus of this._bonusService.bonuses">
                    <!-- if bonus is active and claim is no available -->
                    <app-active-bonus-card *ngIf="bonus.STS_ID == 20 && !bonus.wagered?.claimAvailable && bonus.PROMO_CODE !== 'AUTO-DEPOSIT-Z9'
                        && !_bonusService.showAllBonuses" [bonus]="bonus" class="bonus-card"></app-active-bonus-card>

                    <!-- if bonus is active and claim is available -->
                    <app-claim-bonus-card *ngIf="bonus.STS_ID === 20 && bonus.wagered?.claimAvailable && bonus.PROMO_CODE !== 'AUTO-DEPOSIT-Z9'
                        &&  !_bonusService.showAllBonuses" [bonus]="bonus" class="bonus-card"></app-claim-bonus-card>

                    <!-- if bonus is active and claim is available -->
                    <!-- <app-no-wagering-claimed *ngIf="bonus.STS_ID === 20 && bonus.PROMO_CODE == 'AUTO-DEPOSIT-Z9'"
                        [bonus]="bonus" class="bonus-card"></app-no-wagering-claimed> -->
                </ng-container>

                <!-- if bonuses found and all are pending -->
                <ng-container *ngFor="let bonus of this._bonusService.bonuses">
                    <app-pending-bonus-card *ngIf="bonus.STS_ID === 10" [bonus]="bonus"
                        class="bonus-card"></app-pending-bonus-card>
                </ng-container>
            </ng-container>

            <!-- if promotions found -->
            <!-- <ng-container *ngIf="this._bonusService.promotions.length > 0">
                <ng-container *ngFor="let promotion of this._bonusService.promotions">
                    <app-deposit-now-card2 [promotion]="promotion" class="bonus-card"></app-deposit-now-card2>
                </ng-container>
            </ng-container> -->

            <!-- I phone bonus card -->
            <!-- <ng-container>
                <ng-container>
                    <app-iphone-bonus-card class="bonus-card"></app-iphone-bonus-card>
                </ng-container>
            </ng-container> -->



            <app-claim-done-popup *ngIf="this._bonusService.showClaimBonus"></app-claim-done-popup>
            <app-how-to-bonus *ngIf="this._bonusService.showHowTo"></app-how-to-bonus>
            <app-how-to-bonus2 *ngIf="this._bonusService.showHowTo2"></app-how-to-bonus2>
        </div>
        <app-bonuses-cards *ngIf="this._bonusService.showAllBonuses"></app-bonuses-cards>


    </div>

    <div class="hm-row-bc " style="grid-template-columns: 12fr; margin-top: 8px !important; overflow: hidden;">
        <div class="trending-games-title">
            <div class="trending-header">
                <div class="trending-text">
                    <ng-lottie [options]="fireLottieOptions" width="20px" height="20px"></ng-lottie>
                    <span>{{'trending now'|translate}}</span>
                </div>
                <div class="view-see-more">
                    <i (click)="scrollLeft()" class="view-arrow-more bc-i-small-arrow-left"></i>
                    <i (click)="scrollRight()" class="view-arrow-more bc-i-small-arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="hdr-navigation-scrollable-bc" data-scroll-lock-scrollable="" #scrollContainer>
            <app-skeleton *ngIf="!trendingGameData?.length"
                [styles]="{ width: '100vw', height: '72px' }"></app-skeleton>
            <nav class="hdr-navigation-scrollable-content trending-section"
                *ngFor="let games of trendingGameData; index as i" (click)="getLiveGame(games?.id || games.ID, games)">
                <img src="{{games?.image}}"
                    class="trending-img" alt="11exch">
            </nav>
            <nav class="hdr-navigation-scrollable-content trending-section"
                *ngFor="let games of trendingGameData1; index as i" (click)="getLiveGame(games?.id || games?.ID, games)">
                <img src="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1/{{games?.Image_path}}"
                    class="trending-img" alt="11exch">
            </nav>
        </div>
        <div class="trending-games-wrapper">
            <div class="trending-new-added-third-row">
                <div class="options">
                    <ng-container *ngFor="let item of allSportOptArray">
                        <div class="all-sport-opt" (click)="navigateToSportBook(item.navigate)">
                            <img style="width: 1.5rem; height: 1.5rem;" src={{item.img}}
                                *ngIf="(item.name!=='FIFA' && item.name!=='FIFA24')" alt={{item.name}}>
                            <div class="Fifa-image" *ngIf="item.name==='FIFA'">
                                <img class="footBall" src="../../../../assets/images/menu-sports-option.svg"
                                    *ngIf="item.name==='FIFA'" alt={{item.name}}>
                                <img class="virtual-img" src="../../../../assets/images/menu-virtual-games-option.svg"
                                    *ngIf="item.name==='FIFA'" alt={{item.name}}>
                            </div>
                            <div class="Fifa-image" *ngIf="item.name==='FIFA24'">
                                <img class="footBall" src="../../../../assets/images/menu-sports-option.svg"
                                    *ngIf="item.name==='FIFA24'" alt={{item.name}}>
                                <img class="virtual-img" src="../../../../assets/images/menu-virtual-games-option.svg"
                                    *ngIf="item.name==='FIFA24'" alt={{item.name}}>
                            </div>
                            <span class="title-all-sport">{{item.name|translate}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>


    <app-popUpWithApiData *ngIf="this.promoItemInfo" [popupData]="promoItemInfo?.result" [Exposure]="Exposure"
        [infoWalletType]="infoWalletType" [activeWallet]="typeWallet"
        [popuppromoData]="promoItemInfo?.result?.promoDetails?.info" (close)="closeInfo()">
    </app-popUpWithApiData>


    <div class="hm-row-bc " style="grid-template-columns: 12fr; overflow: hidden;">
        <div class="popular-matches-bc">
            <div class="page-view-more">
                <h3 class="view-title-more ellipsis">
                    <img style="padding:0" src="../../../../assets/images/live-match-dot.png" alt="">
                    {{'In Play'|translate}}
                </h3>
            </div>
            <div class="pm-body-bc">
                <div class="cricket-card" *ngIf="cricketData?.length > 0">
                    <div class="cricket-card-head sg-head-sticky-bc cricket-card-bg">
                        <div class="one one-cricket-bg"></div>
                        <div class="cricket-2 cricket-2-bg"></div>
                        <div class="card-content-cricket">
                            <img src="../../../assets/images/menu-cricket.png" class="cricket-icon" />
                            <span class="cricket-card-title ellipsis">{{'Cricket'|translate}}
                                <div class="center-badge-white">{{cricketData?.length}}</div>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div *ngFor="let Sitem of matchesWithoutBr;let i = index;trackBy: trackByFn">
                            <div *ngIf="Sitem.eventTypeId === '4'" class="c-segment-holder-bc single-g-info-bc">
                                <app-cricket-card [showOdds]="true" (openBetSlip)="openBetSlip($event,Sitem)"
                                    [data]="Sitem"></app-cricket-card>
                            </div>
                        </div>
                        <ng-container *ngFor="let Sitem of matchesWithBr;let i = index;trackBy: trackByFn">
                            <div *ngIf="Sitem.eventTypeId === '4'" class="c-segment-holder-bc single-g-info-bc">
                                <app-cricket-card [showOdds]="true" (openBetSlip)="openBetSlip($event,Sitem)"
                                    [data]="Sitem"></app-cricket-card>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="cricket-card" *ngIf="footballData?.length > 0">
                    <div class="cricket-card-head sg-head-sticky-bc football-card-bg">
                        <div class="one one-football-bg"></div>
                        <div class="cricket-2 football-2-bg"></div>
                        <div class="card-content-cricket">
                            <img src="../../../assets/images/football-logo.png" alt="football-logo"
                                class="football-logo-a">
                            <span class="cricket-card-title ellipsis">{{'FootBall'|translate}}<div
                                    class="center-badge-white">
                                    {{footballData?.length}}</div></span>
                        </div>
                    </div>
                    <div class="match-page">
                        <ng-container *ngFor="let footballMatches of footballData; let i=index;trackBy: trackByFn">
                            <div *ngIf="footballMatches.eventTypeId === '1'">
                                <app-football-card [showOdds]="true" [id]="'_'+footballMatches?.event?.id"
                                    (openBetSlip)="openBetSlip($event,footballMatches)"
                                    [data]="footballMatches"></app-football-card>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="cricket-card" *ngIf="tennisData?.length > 0">
                    <div class="cricket-card-head sg-head-sticky-bc tennis-card-bg">
                        <div class="one one-tennis-bg"></div>
                        <div class="cricket-2 tennis-2-bg"></div>
                        <div class="card-content-cricket">
                            <img style="height: 1rem;" src="../../../../assets/images/menu-tennis.svg" alt="Tennis">
                            <span class="cricket-card-title ellipsis">{{'Tennis'|translate}}<div
                                    class="center-badge-white">{{tennisData?.length}}</div></span>
                        </div>
                    </div>
                    <div class="match-page">
                        <ng-container *ngFor="let tennisMatches of tennisData; let i = index;trackBy: trackByFn">
                            <div *ngIf="tennisMatches.eventTypeId === '2'">
                                <app-tennis-card [showOdds]="true" [id]="'_'+tennisMatches?.event?.id"
                                    (openBetSlip)="openBetSlip($event,tennisMatches)"
                                    [data]="tennisMatches"></app-tennis-card>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="cricket-card" *ngIf="politicsData?.length > 0">
                    <div class="cricket-card-head sg-head-sticky-bc tennis-card-bg">
                        <div class="one one-tennis-bg"></div>
                        <div class="cricket-2 tennis-2-bg"></div>
                        <div class="card-content-cricket">
                            <img style="filter: invert(1);" src="../../../assets/images/menu-politics.svg"
                                alt="politics" style="height: 1rem;">
                            <span class="cricket-card-title ellipsis">{{'Election'|translate}}<div
                                    class="center-badge-white">{{politicsData?.length}}</div></span>
                        </div>
                    </div>
                    <div class="match-page">
                        <ng-container *ngFor="let election of politicsData; let i = index;trackBy: trackByFn">
                            <app-politics-card [showOdds]="true" [id]="'_'+election?.event?.id"
                                (openBetSlip)="openBetSlip($event,election)" [data]="election"></app-politics-card>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="hm-row-bc " style="grid-template-columns: 12fr; overflow: hidden;">
        <div class="page-view-more">
            <h3 class="view-title-more ellipsis">
                <img src="../../../../assets/images/dice.svg" alt=""> {{'Games Shows'|translate}}
            </h3>
            <a class="view-see-more" routerLink="/shared/livecasino">{{'More'|translate}}<i
                    class="view-arrow-more bc-i-small-arrow-right"></i></a>
        </div>
        <div class="games-horiz casino-game-item-amount">
            <div class="games-horiz-scroll" data-scroll-lock-scrollable="" style="grid-template-rows: auto;">
                <div class="casino-game-item-content" *ngFor="let games of teenaPattiGameData; index as i">
                    <div class="casino-game-item games-show " data-badge="">
                        <h1><img loading="lazy" [src]="games?.ImageURL" class="casino-game-item-image-bc" alt="11exch"
                                title="11exch"></h1>

                        <div class="casino-game-item-block">

                            <h3 class="casino-game-item-label-bc">{{games?.en}}</h3>
                            <div class="casino-game-buttons"><button class="btn a-color " type="button" title="Play"
                                    (click)="getGameShow(games?.ID, games)"><span>{{'Play'|translate}}</span></button><button
                                    class="btn a-outline " type="button" *ngIf="games.demo_url==!null && !getToken()"
                                    title="Demo"><span>{{'Demo'|translate}}</span></button></div>
                            <i style="top: 9px;"
                                [ngClass]="{'casino-game-item-fav-bc':true, 'bc-i-favorite':true,'active':favouriteGamelistcasino.includes('/'+games?.Image_path) ? true:null}"
                                (click)="favouriteGame(i,'/'+games?.Image_path)"></i>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>





<!--
    <div class="hm-row-bc " style="grid-template-columns: 12fr;">
        <div class="page-view-more">
            <h3 class="view-title-more ellipsis">
                <img src="../../../../assets/images/teen-patti-cards.png" alt="">
                {{'Indian Teenpatti'|translate}}
            </h3>
            <a class="view-see-more" routerLink="shared/livecasino/teenPatti/56768">{{'More'|translate}}<i
                    class="view-arrow-more bc-i-small-arrow-right"></i></a>
        </div>
        <div class="games-horiz casino-game-item-amount">
            <div class="games-horiz-scroll" data-scroll-lock-scrollable="" style="grid-template-rows: auto;">
                <div class="casino-game-item-content" *ngFor="let games of teenaPattiData; index as i">
                    <div class="casino-game-item  " data-badge="">
                        <h1><img loading="lazy"
                                src="{{games?.image}}"
                                class="casino-game-item-image-bc" alt="11exch" title="11exch"></h1>

                        <div class="casino-game-item-block">
                            <h3 class="casino-game-item-label-bc">{{games?.name}}</h3>
                            <div class="casino-game-buttons"><button class="btn a-color " type="button" title="Play"
                                    (click)="getLiveGame(games?.event?.id, games)"><span>{{'Play'|translate}}</span></button><button
                                    class="btn a-outline " type="button" *ngIf="games.demo_url==!null && !getToken()"
                                    title="Demo"><span>{{'Demo'|translate}}</span></button></div>
                            <i [ngClass]="{'casino-game-item-fav-bc':true, 'bc-i-favorite':true,'active':favouriteGamelistcasino.includes('/'+games?.Image_path) ? true:null}"
                                (click)="favouriteGame(i,'/'+games?.image)"></i>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div> -->
    <!-- <div class="hm-row-bc " style="grid-template-columns: 12fr;"> -->
        <!-- <div class="page-view-more">
            <h3 class="view-title-more ellipsis">
                <img src="../../../../assets/images/casino-chip.png" alt="">
                {{'Live Casino'|translate}}
            </h3>
            <a class="view-see-more" routerLink="shared/livecasino">{{'More'|translate}}<i
                    class="view-arrow-more bc-i-small-arrow-right"></i></a>
        </div> -->
        <!-- <div class="games-horiz casino-game-item-amount">
            <div class="games-horiz-scroll" data-scroll-lock-scrollable="" style="grid-template-rows: auto;">
                <div class="casino-game-item-content" *ngFor="let games of livecasinogamesshow; let i = index;">
                    <div class="casino-game-item  " data-badge="">
                        <h1><img loading="lazy" [src]="gamepath+games.ImageURL" class="casino-game-item-image-bc"
                                alt="11exch" title="11exch"></h1>

                        <div class="casino-game-item-block">

                            <h3 class="casino-game-item-label-bc">{{games.Trans.en}}</h3>

                            <div class="casino-game-buttons"><button class="btn a-color " type="button" title="Play"
                                    (click)="goToGame(games?.ID, games)"><span>{{'Play'|translate}}</span></button><button
                                    class="btn a-outline " type="button" *ngIf="games.demo_url==!null && !getToken()"
                                    title="Demo"><span>{{"Demo"|translate}}</span></button></div>
                            <i [ngClass]="{'casino-game-item-fav-bc':true, 'bc-i-favorite':true,'active':favouriteGamelistcasino.includes(games?.ImageURL) ? true:null}"
                                (click)="favouriteGame(i,games.ImageURL)"></i>
                        </div>

                    </div>
                </div>

            </div>
        </div> -->
    <!-- </div> -->
    <!-- <div class="hm-row-bc " style="grid-template-columns: 12fr;">
        <div class="page-view-more">
            <h3 class="view-title-more ellipsis">
                <img src="../../../../assets/images/casino-roulet.png" alt="">
                {{'Casino games'|translate}}
            </h3>
            <a class="view-see-more" routerLink="shared/casino">{{'More'|translate}}<i
                    class="view-arrow-more bc-i-small-arrow-right"></i></a>
        </div>
        <div class="games-horiz casino-game-item-amount">
            <div class="games-horiz-scroll" data-scroll-lock-scrollable="" style="grid-template-rows: auto;">
                <div class="casino-game-item-content" *ngFor="let games of gameCategory; index as i">
                    <div class="casino-game-item  " data-badge="">
                        <h1><img loading="lazy" [src]="games?.image" class="casino-game-item-image-bc"
                                alt="{{games?.name}}" title="11exch"></h1>

                        <div class="casino-game-item-block">
                            <h3 class="casino-game-item-label-bc">{{games?.name}}</h3>

                            <div class="casino-game-buttons"><button class="btn a-color " type="button" title="Play"
                                    (click)="goToGame(games)"><span>{{"Play"|translate}}</span></button><button
                                    class="btn a-outline " type="button" *ngIf="games.demo_url==!null && !getToken()"
                                    title="Demo"><span>{{'Demo'|translate}}</span></button></div>
                            <i [ngClass]="{'casino-game-item-fav-bc':true, 'bc-i-favorite':true,'active':favouriteGamelistcasino.includes(games?.ImageURL) ? true:null}"
                                (click)="favouriteGame(i,games?.ImageURL)"></i>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div> -->


<!--    <div class="promo-video">-->
<!--        <div class="celebrities-title">-->
<!--            <ng-lottie [options]="fireLottieOptions" width="20px" height="20px"></ng-lottie>-->
<!--            <span> {{'OUR AMBASSADORS'|translate}} </span>-->
<!--        </div>-->
<!--        <div id="stories-wrapper">-->
<!--            <div id="stories-container">-->
<!--                <a class="story" (click)="toggleVideo1()">-->
<!--                    <div class="profile">-->
<!--                        <img style="height: 80px" loading="lazy" src="../../../assets/images/tushar.png" alt="" />-->
<!--                    </div>-->
<!--                </a>-->
<!--                <a class="story" (click)="toggleVideo2()">-->
<!--                    <div class="profile">-->
<!--                        <img style="height: 70px" loading="lazy" src="../../../assets/images/sudesh.png" alt="" />-->
<!--                    </div>-->
<!--                </a>-->
<!--                <a class="story" (click)="toggleVideo3()">-->
<!--                    <div class="profile">-->
<!--                        <img style="height: 75px" loading="lazy" src="../../../assets/images/vikrant.png" alt="" />-->
<!--                    </div>-->
<!--                </a>-->
<!--                <a class="story" (click)="toggleVideo4()">-->
<!--                    <div class="profile">-->
<!--                        <img style="height: 70px; width: 80px" loading="lazy" src="../../../assets/images/krushna.png"-->
<!--                            alt="" />-->
<!--                    </div>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="video-player" *ngIf="showVideo1">-->
<!--            <img loading="lazy" (click)="toggleVideo1()" class="btn-close" src="../../../assets/images/close-circle.svg"-->
<!--                alt="">-->
<!--            &lt;!&ndash;            <div class="video-container">&ndash;&gt;-->
<!--            <iframe src="https://player.vimeo.com/video/870674613" frameborder="0"-->
<!--                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>-->
<!--            </iframe>-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="video-player" *ngIf="showVideo2">-->
<!--            <img loading="lazy" (click)="toggleVideo2()" class="btn-close" src="../../../assets/images/close-circle.svg"-->
<!--                alt="">-->
<!--            <iframe src="https://player.vimeo.com/video/870688861" frameborder="0"-->
<!--                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>-->
<!--            </iframe>-->
<!--            &lt;!&ndash;        <div class="video-container">&ndash;&gt;-->
<!--            &lt;!&ndash;          <iframe width="560" height="315"&ndash;&gt;-->
<!--            &lt;!&ndash;                  src="https://www.youtube.com/embed/7A-PsEq7Urc?modestbranding=1&rel=0&autohide=1&showinfo=0&autoplay=1&control=1"&ndash;&gt;-->
<!--            &lt;!&ndash;                  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"&ndash;&gt;-->
<!--            &lt;!&ndash;                  allowfullscreen>&ndash;&gt;-->
<!--            &lt;!&ndash;          </iframe>&ndash;&gt;-->
<!--            &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="video-player" *ngIf="showVideo3">-->
<!--            <img loading="lazy" (click)="toggleVideo3()" class="btn-close" src="../../../assets/images/close-circle.svg"-->
<!--                alt="">-->
<!--            <iframe src="https://player.vimeo.com/video/870693342" frameborder="0"-->
<!--                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>-->
<!--            </iframe>-->
<!--            &lt;!&ndash;        <div class="video-container">&ndash;&gt;-->
<!--            &lt;!&ndash;          <iframe width="560" height="315"&ndash;&gt;-->
<!--            &lt;!&ndash;                  src="https://www.youtube.com/embed/imzPyiN3Sn8?modestbranding=1&rel=0&autohide=1&showinfo=0&autoplay=1&control=1"&ndash;&gt;-->
<!--            &lt;!&ndash;                  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"&ndash;&gt;-->
<!--            &lt;!&ndash;                  allowfullscreen>&ndash;&gt;-->
<!--            &lt;!&ndash;          </iframe>&ndash;&gt;-->
<!--            &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="video-player" *ngIf="showVideo4">-->
<!--            <img loading="lazy" (click)="toggleVideo4()" class="btn-close" src="../../../assets/images/close-circle.svg"-->
<!--                alt="">-->
<!--            <iframe src="https://player.vimeo.com/video/870698638" frameborder="0"-->
<!--                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>-->
<!--            </iframe>-->
<!--            &lt;!&ndash;        <div class="video-container">&ndash;&gt;-->
<!--            &lt;!&ndash;          <iframe width="560" height="315"&ndash;&gt;-->
<!--            &lt;!&ndash;                  src="https://www.youtube.com/embed/MTgXLuetu3A?modestbranding=1&rel=0&autohide=1&showinfo=0&autoplay=1&control=1"&ndash;&gt;-->
<!--            &lt;!&ndash;                  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"&ndash;&gt;-->
<!--            &lt;!&ndash;                  allowfullscreen>&ndash;&gt;-->
<!--            &lt;!&ndash;          </iframe>&ndash;&gt;-->
<!--            &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        </div>-->
<!--    </div>-->


</div>

<div class="games-new-iframe"
    [ngClass]="{'userSafariPWA': isSafariPWA, 'userIphoneSafari': isIphoneSafari, 'userIphoneChrome': isIphoneChrome, 'hideIframe': !urlIframe}">
    <!-- <div *ngIf="urlIframe && !isMobile" class="iframe-title-games max-width-550">
        {{title}}
        <svg (click)="clearIframe()" width="31" height="31" viewBox="0 0 31 31" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2045 10.0005C10.9659 10.0008 10.7328 10.0722 10.5349 10.2057C10.3371 10.3391 10.1835 10.5285 10.0938 10.7496C10.0042 10.9707 9.98244 11.2136 10.0314 11.4471C10.0804 11.6806 10.1979 11.8943 10.3689 12.0607L13.7205 15.4122L10.3689 18.7638C10.2538 18.8744 10.1618 19.0068 10.0985 19.1534C10.0351 19.2999 10.0017 19.4576 10.0001 19.6173C9.99844 19.7769 10.0287 19.9353 10.089 20.0831C10.1494 20.2309 10.2386 20.3652 10.3515 20.4781C10.4644 20.591 10.5987 20.6802 10.7465 20.7406C10.8943 20.8009 11.0527 20.8312 11.2123 20.8296C11.372 20.8279 11.5297 20.7945 11.6763 20.7311C11.8228 20.6678 11.9552 20.5758 12.0658 20.4607L15.4174 17.1091L18.7689 20.4607C18.8795 20.5758 19.012 20.6678 19.1585 20.7311C19.3051 20.7945 19.4628 20.8279 19.6224 20.8296C19.7821 20.8312 19.9405 20.801 20.0883 20.7406C20.2361 20.6803 20.3704 20.591 20.4833 20.4781C20.5962 20.3652 20.6854 20.2309 20.7457 20.0831C20.8061 19.9353 20.8363 19.7769 20.8347 19.6173C20.8331 19.4577 20.7996 19.2999 20.7363 19.1534C20.6729 19.0068 20.581 18.8744 20.4658 18.7638L17.1143 15.4122L20.4658 12.0607C20.6392 11.8921 20.7577 11.6751 20.8056 11.4381C20.8536 11.2011 20.8288 10.9551 20.7345 10.7324C20.6403 10.5097 20.481 10.3206 20.2774 10.19C20.0739 10.0595 19.8357 9.99339 19.5939 10.0005C19.2822 10.0098 18.9863 10.1401 18.7689 10.3638L15.4174 13.7154L12.0658 10.3638C11.9539 10.2487 11.8199 10.1573 11.672 10.0949C11.524 10.0325 11.3651 10.0004 11.2045 10.0005V10.0005Z"
                fill="white" />
        </svg>
    </div> -->
    <div class="iframeHeight" #iframeLoad> </div>
</div>

<app-betplace *ngIf="showbet" [eventName]="eventName" [marketName]="marketName" [price]="price"> </app-betplace>
