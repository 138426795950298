import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { SportsbookService } from 'src/app/services/sportsbook.service';
import { TranslateService } from '@ngx-translate/core';

declare const BTRenderer: any;
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  sportOptiosArray = [
    { name: 'Live', img: '../../../../assets/images/menu-live-option.svg', leftStripColor: '#EF7163', navigate: '/shared/live' },
    { name: 'Sports', img: '../../../../assets/images/menu-sports-option.svg', leftStripColor: '#0090F8', navigate: '/shared/sport' },
    { name: 'Sportsbook', img: '../../../../assets/images/menu-sportsbook-option.svg', leftStripColor: '#EC2AC1', navigate: '/shared/sportsbook' },
    { name: 'Esports', img: '../../../../assets/images/menu-esports-option.png', leftStripColor: '#FBBF23', navigate: '/shared/esports' },
    { name: 'Virtual Sports', img: '../../../../assets/images/menu-virtual-games-option.svg', leftStripColor: '#15AF53', navigate: '/shared/virtulsports' },

  ]

  casinoOptiosArray = [
    { name: 'Casino', img: '../../../../assets/images/menu-casino-option.svg', leftStripColor: '#9E21B2', navigate: '/shared/casino' },
    { name: 'Live Casino', img: '../../../../assets/images/menu-live-casino-option.png', leftStripColor: '#A6F400', navigate: '/shared/livecasino' },
    { name: 'Poker', img: '../../../../assets/images/menu-poker-option.png', leftStripColor: '#B3B3B3', navigate: '/shared/poker' },
    { name: 'Game', img: '../../../../assets/images/menu-game-option.svg', leftStripColor: '#16F1FF', navigate: '/shared/games' },
    { name: 'Non-Stop Roulette', img: '../../../../assets/images/menu-roulette-option.png', leftStripColor: '#CD004A', navigate: '/shared/nonstproulette' },]

  otherOptionsArray = [
    { name: 'Aviator', img: '../../../../assets/images/menu-aviator.svg', navigate: '/shared/blastOff' },
    { name: 'Teenpatti', img: '../../../../assets/images/menu-teenPatti.png', navigate: '/shared/livecasino/teenPatti/567681' },
    { name: 'Dream Wheel', img: '../../../../assets/images/menu-dream-wheel.png', navigate: '/shared/dreamwheel' },
    { name: 'Horse Racing', img: '../../../../assets/images/menu-horse-racing.svg', navigate: '/shared/horseracing' },
    { name: 'Greyhound Racing', img: '../../../../assets/images/menu-greyhord-racing.svg', navigate: '/shared/greyhoundRacing' }
  ]

  allSportOptArray = [
    { name: 'Cricket', img: '../../../../assets/images/menu-cricket.png', navigate: 'c' },
    { name: 'Tennis', img: '../../../../assets/images/menu-tennis.svg', navigate: 't' },
    { name: 'Football', img: '../../../../assets/images/menu-sports-option.svg', navigate: 'f' },
    { name: 'Basket Ball', img: '../../../../assets/images/menu-basketBall.svg', navigate: 'basketball-2' },
    { name: 'Table Tennis', img: '../../../../assets/images/menu-tableTennis.png', navigate: 'tennis-5' },
    { name: 'Horse Racing', img: '../../../../assets/images/menu-horse-racing.svg', navigate: 'horseRacing' },
    { name: 'Greyhound Racing', img: '../../../../assets/images/menu-greyhord-racing.svg', navigate: 'greyhoundRacing' },
    { name: 'Kabaddi', img: '../../../../assets/images/menu-kabaddi.png', navigate: 'kabaddi-138' },
    { name: 'Rugby', img: '../../../../assets/images/menu-rugby.svg', navigate: 'rugby-12' },
    { name: 'Boxing', img: '../../../../assets/images/menu-boxing.svg', navigate: 'boxing-10' },
    { name: 'Formula1', img: '../../../../assets/images/menu-formula1.svg', navigate: 'formula-1-40' },
    // { name: 'Badminton', img: '../../../../assets/images/menu-badminton.svg', navigate: '' },
    { name: 'Golf', img: '../../../../assets/images/menu-golf.svg', navigate: 'golf-9' },
    // { name: 'Counter Strike', img: '../../../../assets/images/menu-counterStrike.svg', navigate: '' },
    { name: 'Baseball', img: '../../../../assets/images/menu-baseBall.png', navigate: 'baseball-3' },
    { name: 'FIFA', img: '../../../../assets/images/menu-sports-option.svg', navigate: 'fifa-300' },
    { name: 'Volleyball', img: '../../../../assets/images/menu-vollyBall.svg', navigate: 'volleyball-23' },
    { name: 'FIFA24', img: '../../../../assets/images/menu-sports-option.svg', navigate: 'penalty-shootout-307' },
    // { name: 'Dota 2', img: '../../../../assets/images/menu-dota2.svg', navigate: '' },
    { name: 'E-Cricket', img: '../../../../assets/images/menu-eCricket.svg', navigate: 'ecricket-305' },
    { name: 'Ice Hockey', img: '../../../../assets/images/menu-iceHockey.png', navigate: 'ice-hockey-4' },
    { name: 'Handball', img: '../../../../assets/images/menu-HandBall.svg', navigate: 'handball-6' },
    { name: 'Cycling', img: '../../../../assets/images/menu-cycling.png', navigate: 'cycling-17' },
    { name: 'Darts', img: '../../../../assets/images/menu-darts.svg', navigate: 'darts-22' },
    // { name: 'E-Fighting', img: '../../../../assets/images/e-fighting.svg', navigate: '' },
    { name: 'Snooker', img: '../../../../assets/images/menu-snooker.png', navigate: 'snooker-19' },
    { name: 'American Football', img: '../../../../assets/images/menu-americanFootball.svg', navigate: 'american-football-16' },
    { name: 'NBA2K', img: '../../../../assets/images/menu-nba2k19.svg', navigate: 'nba-2k-302' },
    { name: 'Waterpolo', img: '../../../../assets/images/menu-Waterpolo.png', navigate: 'waterpolo-26' },
    // { name: 'Bike Racing', img: '../../../../assets/images/menu-bikeRacing.svg', navigate: '' },
  ];

  deposit: boolean = false;
  loginUserorMobile: boolean = false;
  BTrendererVar: any;
  btRendererSuccess: boolean = false;
  istoken: boolean = false;
  closeMenuPage: boolean = false;
  user: boolean = false;
  userName: any;

  // langs = [
  //   { 'name': 'English', 'value': 'en' },
  //   { 'name': 'हिन्दी', 'value': 'hi' },
  //   { 'name': 'मराठी', 'value': 'mr' },
  // ];

  constructor(public router: Router, private authService: AuthService, private loginshow: SportsbookService, private translater: TranslateService, private myprofile: MyprofileService) {
    if (this.authService.currentUserValue == null) {
      this.istoken = true;
    } else {
      this.istoken = false;
    }
  }


  ngOnInit(): void {
    this.closeClick;
    const royal_token_key = localStorage.getItem('ROYALTOKEN_KEY');
    if (royal_token_key) { this.deposit = true; }
    this.getUserName();
  }

  getUserName() {
    if (localStorage.getItem('ROYALTOKEN_KEY')) {
      const user = localStorage.getItem("User_name");
      const mailLoginName = localStorage.getItem('loginName');
      if (user) {
        const pattern = /^91_\d{10}$/;
        if(pattern.test(user)) {
          this.userName = this.formatPhoneNumberWithCountryCode(user);
          this.user = true;
          this.deposit = true;
        }else{
          this.userName = user;
          this.user = true;
          this.deposit = false;
        }
      } else if (mailLoginName) {
        this.userName = mailLoginName;
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (emailPattern.test(this.userName)) {
           this.deposit = true;
        }
        this.user = true;
      }
    }
  }

  formatPhoneNumberWithCountryCode(inputNumber:any) {
    if(inputNumber.startsWith('b06.')) {
      inputNumber = inputNumber.replace('b06.', '');
    }
    const countryCode = inputNumber.substring(0, 2);

    const phoneNumber = inputNumber.substring(2).replace(/[^0-9]/g, '');

    if (!phoneNumber.startsWith('+' + countryCode)) {
      return '+' + countryCode + phoneNumber;
    }
    return '+' + countryCode + phoneNumber;
  }


  getToken() {
    let currentUser = '';
    if (localStorage.getItem('ROYALTOKEN_KEY')) {
      currentUser = JSON.parse(localStorage.getItem('ROYALTOKEN_KEY'));
    }
    return currentUser;
  }

  navigateToregister() {
    this.closeClick.emit(true);
    this.router.navigate(['/register'])
  }

  closeClicked() {
    this.closeMenuPage = true;
    setTimeout(() => {
      this.closeClick.emit(true);
      this.myprofile.updateMenuStatus(false);
      if (this.router.url === '/main' || this.router.url === '/') {
        this.myprofile.updateActiveMenuItem('/main');
      }
      this.closeMenuPage = false;
    }, 400);
  }

  naavigateToRelatedPage(item: String) {
    // console.log(typeof (item));
    if (item == '/shared/sport') {
      this.router.navigate([item]);
      this.authService.sportsId = 4;
    } else {
      this.router.navigate([item]);
    }
    this.closeClick.emit(true);
  }

  navigateToDeposit() {
    this.router.navigate(['/balancemanagement/Deposit']);
    this.closeClick.emit(true);
  }

  // goToAPK() {
  //   const link = document.createElement('a');
  //   link.href = 'https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/11EXCH1.0.0.apk';
  //   link.target = '_self';
  //   link.download = '11EXCH1.0.0.apk';
  //   link.click();
  // }

  navigateToSportBook(item: string) {
    const containsNumber = /\d/.test(item);
    if (item == 'f') {
      localStorage.removeItem('sportsId');
      this.authService.sportsId = '1';
      this.authService.updateSportsId(this.authService.sportsId);
      localStorage.setItem('sportsId', this.authService.sportsId);
      this.router.navigate(['/shared/sport/']);
      this.closeClick.emit(true);
    } else if (item === 't') {
      localStorage.removeItem('sportsId');
      this.authService.sportsId = '2';
      this.authService.updateSportsId(this.authService.sportsId);
      localStorage.setItem('sportsId', this.authService.sportsId);
      this.router.navigate(['/shared/sport/']);
      this.closeClick.emit(true);
    } else if (item === 'c') {
      localStorage.removeItem('sportsId');
      this.authService.sportsId = '4';
      localStorage.setItem('sportsId', this.authService.sportsId);
      this.authService.updateSportsId(this.authService.sportsId);
      this.router.navigate(['/shared/sport/']);
      this.closeClick.emit(true);
    } else if (item === 'horseRacing') {
      this.authService.sportsId = '7';
      this.authService.updateSportsId(this.authService.sportsId);
      this.router.navigate(['/shared/sport']);
      this.closeClick.emit(true);
    } else if (item === 'greyhoundRacing') {
      this.authService.sportsId = '4339';
      this.authService.updateSportsId(this.authService.sportsId);
      this.router.navigate(['/shared/sport']);
      this.closeClick.emit(true);
    } else if (!containsNumber) {
      this.router.navigate([item]);
      localStorage.setItem('gameId', item);
      this.closeClick.emit(true);
    } else {
      this.router.navigate(['/shared/sportsbook']);
      localStorage.setItem('gameId', item);
      this.closeClick.emit(true);
    }
  }

  logOut() {
    localStorage.removeItem('ROYALTOKEN_KEY');
    localStorage.removeItem('Member_code');
    localStorage.removeItem('token');
    localStorage.removeItem('loginName');
    localStorage.removeItem('User_name');
    localStorage.removeItem('betstack');
    localStorage.removeItem('showBalance');
    localStorage.removeItem('WaLink');
  }
}
